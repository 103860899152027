import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import Error from "./views/pages/Error";
import withRoot from "./modules/withRoot";
import app from "./firebase/base";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

//Admin
import Navigation from "./views/navigation/Navigation";
//import Dashboard from "./views/pages/Dashboard";
import Media from "./views/pages/Media";
import Products from "./views/pages/Products";
import Package from "./views/pages/Package";
import Categories from "./views/pages/Categories";
import Orders from "./views/pages/Orders";
import Invoices from "./views/pages/Invoices";
import Receipts from "./views/pages/Receipts";
import DeliveryOrder from "./views/pages/DeliveryOrder";
import OrdersStatus from "./views/pages/Orders_status";
import GiftRedeem from "./views/pages/GiftRedeem";
import UserLevel from "./views/pages/User_level";
import Users from "./views/pages/Users";
import ConceptStore from "./views/pages/ConceptStore";
import Agent from "./views/pages/Agent";
import Customer from "./views/pages/Customer";
import Salesman from "./views/pages/Salesman";
import KOL from "./views/pages/KOL";
import Coupons from "./views/pages/Coupons";
import Shipping from "./views/pages/Shipping";
import Request from "./views/pages/Request";
import Banner from "./views/pages/Banner";
import Warranty from "./views/pages/Warranty";


import Login from "./views/authentication/Login";

import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectCurrentUser } from './redux/user/user.selector'
import {
  setCurrentUser
} from './redux/user/user.actions';
import {
  setItem
} from './redux/cart/cart.actions';

function App(props) {

  const [authenticated, setAuthenticated] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {

    app.auth().onAuthStateChanged(user => {
      if (user) {
        setAuthenticated(true)
        setLoading(false)
       
      } else {
        setAuthenticated(false)
        setLoading(false)
      }
    });

    
  }, []);

  const renderAdminRoute = () => {
    return(
      <Switch>
        <Route exact path="/" component={Media} />
        <Route path="/warranty" component={Warranty} />
        <Route path="/media" component={Media} />
        <Route path="/products" component={Products} />
        <Route path="/package" component={Package} />
        <Route path="/categories" component={Categories} />
        <Route path="/orders" component={Orders} />
        <Route path="/invoices" component={Invoices} />
        <Route path="/receipts" component={Receipts} />
        <Route path="/delivery_order" component={DeliveryOrder} />
        <Route path="/orders_status" component={OrdersStatus} />
        <Route path="/gift_redeem" component={GiftRedeem} />
        <Route path="/users" component={Users} />
        <Route path="/concept_store" component={ConceptStore} />
        <Route path="/agent" component={Agent} />
        <Route path="/customer" component={Customer} />
        <Route path="/salesman" component={Salesman} />
        <Route path="/kol" component={KOL} />
        <Route path="/user_level" component={UserLevel} />
        <Route path="/coupons" component={Coupons} />
        <Route path="/banner" component={Banner} />
        <Route path="/shipping" component={Shipping} />
        <Route path="/request" component={Request} />
        <Route path="*" component={Error} />
      </Switch>
    )
  }

  if(loading){
    return (
    <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center', marginTop: 70  }}>
      <CircularProgress 
        disableShrink
        color="primary" />
    </div>);
  }

  if(authenticated){
    return (
      <BrowserRouter>
          <Navigation component={renderAdminRoute()} />
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route path="*" component={Error} />
        </Switch>
    </BrowserRouter>
  );
}

const mapDispatchToProps = dispatch => ({
  setCurrentUser: item => dispatch(setCurrentUser(item)),
  setItem: item => dispatch(setItem(item))
});

const mapStatetoProps = createStructuredSelector({
  currentUser: selectCurrentUser,
})

export default withRoot(connect(mapStatetoProps, mapDispatchToProps)(App)
);
