import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Dialog from '../../modules/components/Dialog';
import Media from './Media';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Typography } from '@material-ui/core';
import ReactPlayer from 'react-player'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import ImageIcon from '@material-ui/icons/Image';
import Avatar from '@material-ui/core/Avatar';
import Checkbox from '@material-ui/core/Checkbox';
import TableHead from '@material-ui/core/TableHead';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import CheckIcon from '@material-ui/icons/Check';
import { makeStyles } from '@material-ui/core/styles';
import _uniqueId from 'lodash/uniqueId';
import DeleteIcon from '@material-ui/icons/Delete';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PublishIcon from '@material-ui/icons/Publish';
import ContentPreview from './Banner/ContentPreview'
import firebase from "firebase";

//table head
function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount, headCells, disableCheckBox } = props;

  return (
      <TableHead >
          <TableRow>
              {disableCheckBox ? null : <TableCell padding="checkbox">
                  <Checkbox
                      indeterminate={numSelected > 0 && numSelected < rowCount}
                      checked={rowCount > 0 && numSelected === rowCount}
                      onChange={onSelectAllClick}
                      inputProps={{ 'aria-label': 'select all' }}
                  />
              </TableCell>}
              {headCells ? headCells.map((headCell) => (
                  <TableCell
                      style={{ padding: 3 }}
                      key={headCell.id}
                      align={'left'}
                      sortDirection={false}
                  >
                      {headCell.label}
                  </TableCell>
              )) : null}

          </TableRow>
      </TableHead>
  );
}

const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  ...(isDragging && {
      background: "rgb(57,57,57)"
  })
})

const DraggableComponent = (id, index) => (props) => {
  return (
      <Draggable draggableId={id} index={index}>
          {(provided, snapshot) => (
              <TableRow
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                  {...props}
              >
                  {props.children}
              </TableRow>
          )}
      </Draggable>
  )
}

const DroppableComponent = (onDragEnd) => (props) => {
  return (
      <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={'1'} direction="vertical">
              {(provided) => {
                  return (
                      <TableBody ref={provided.innerRef} {...provided.droppableProps} {...props}>
                          {props.children}
                          {provided.placeholder}
                      </TableBody>
                  )
              }}
          </Droppable>
      </DragDropContext>
  )
}


const useStyles = makeStyles((theme) => ({
  root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
  },
  duration: {
      "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
          display: "none"
      }
  }, table: {
      width: "100%",
  },

}));

export default function Index(props) {

    //Dialog
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogLoading, setDialogLoading] = React.useState(false);
    const [fullScreenDialog, setFullScreenDialog] = React.useState(false);
    const [dialogTitle, setDialogTitle] = React.useState("");
    const [dialogDes, setDialogDes] = React.useState("");
    const [agreeButton, setAgreeButton] = React.useState(false);
    const [option, setOptions] = React.useState("");

    const [selected, setSelected] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const [selecteditem, setSelecteditem] = React.useState(null);
    const [mediaOption, setMediaOption] = React.useState("");
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [duration, setDuration] = React.useState(15)
    const classes = useStyles();

    useEffect(() => {

      let bannerRef = firebase.database().ref("banner");

      bannerRef.once('value', snapshot => {
            if (snapshot.exists()) {
                var allbanner = []
                
                snapshot.forEach(child => {

                    var itemsVal = child.val();
                    allbanner.push(itemsVal);

                })
                
                setSelecteditem(allbanner[0])
                setRows(allbanner)
            }
        })

    }, []);

    const addContent = () => {
      setMediaOption("content")
      setDialogLoading(false)
      setAgreeButton(false)
      setDialogTitle("Add Image")
      setOptions("addItem")
      setDialogDes("")
      setFullScreenDialog(true)
      setOpenDialog(true)
    }

    const handleSetRows = (row) => {
      if(mediaOption === "content")
      {
          var newRows = [];
          row.forEach((child, index) => {
              var newChild = child;
              newChild.uid = child.id + (rows.length + _uniqueId('prefix-'));
              if (child.type === "image/jpeg") {
                  newChild.duration = 15
              }

              newRows.push(newChild)
          })

          if (!selecteditem) {

              setSelecteditem(newRows[0])
              setDuration(newRows[0].type === "image/jpeg" ? 15 : 0)
          }
          setRows(prev => [...prev, ...newRows])

          if(!selecteditem){
            setSelecteditem(row[0])
          }
      }
      
      setOpenDialog(false)
    }
    
    const onChangeduration = (e) => {
      const newValue = Math.min(Math.max(e.target.value, 1), 100)
      setRowsDuration(newValue)
      setDuration(previousValue => newValue)
    }
    
    const addDuration = () => {
      setDuration(prev => {
    
          var newPrev = 0;
    
          if (prev < 100) {
              newPrev = prev + 1
          }
          else {
              newPrev = prev
          }
    
          setRowsDuration(newPrev)
    
          return newPrev
      })
    }
    
    const minusDuration = () => {
      setDuration(prev => {
          var newPrev = 0;
    
          if (prev > 1) {
              newPrev = prev - 1
          }
          else {
              newPrev = prev
          }
    
          setRowsDuration(newPrev)
    
          return newPrev
      })
    }
    
    const setRowsDuration = (value) => {
      rows[rows.map(val => val).indexOf(selecteditem)].duration = value;
    }
    

    const handleClose = () => {
      setOpenDialog(false)
    };

    const renderComponent = () => {

      if(mediaOption === "playPreview")
      {
        return (
          <ContentPreview withinDateTime={withinDateTime} rows={rows} />
        )
      }
      else if(mediaOption === "content")
      {
        return(<Media mediaOption={mediaOption} handleSetRows={(rows) => handleSetRows(rows)}/>)
      }
      
    }

    const withinDateTime = (startDate, endDate, startTime, endTime) => {


      if (startDate && endDate && startTime && endTime) {

          var start_date = new Date(new Date(startDate).getFullYear(), new Date(startDate).getMonth(), new Date(startDate).getDate());
          var end_date = new Date(new Date(endDate).getFullYear(), new Date(endDate).getMonth(), new Date(endDate).getDate());
          var current_date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
          var current_time = new Date();

          var start_time = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), new Date(startTime).getHours(), new Date(startTime).getMinutes());
          var end_time = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), new Date(endTime).getHours(), new Date(endTime).getMinutes());

          if (start_date <= current_date && end_date >= current_date && new Date(start_time).getHours() === new Date(end_time).getHours() && new Date(startTime).getMinutes() === new Date(endTime).getMinutes()) {
              return true
          }

          if (start_date <= current_date && end_date >= current_date && current_time >= start_time && current_time <= end_time) {
              return true
          }

          return false
      }
      else {
          return true
      }
    }

    const onClickDelete = () => {
      var newRows = rows.filter(item => !selected.includes(item))

      if (newRows.length === 0) {
          setSelecteditem(null)
          setDuration(0)
      }
      else if (newRows.map(val => val).indexOf(selecteditem) === -1) {
          setSelecteditem(newRows[0])
          setDuration(newRows[0].duration)
      }

      setRows(newRows)
      setSelected([])
    }

    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
          const newSelecteds = rows.map((n) => n);
          setSelected(newSelecteds);
          return;
      }
      setSelected([]);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const handleCheckBoxClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const onDragEnd = (result) => {

        if (!result.destination) {
            return
        }

        const items = reorder(
            rows,
            result.source.index,
            result.destination.index
        )


        setRows(items);
    }

    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list)
      const [removed] = result.splice(startIndex, 1)
      result.splice(endIndex, 0, removed)

      return result
    }

    const onClickRow = (row) => {
      setSelecteditem(row)
      setDuration(rows[rows.map(val => val).indexOf(row)].duration)
    }

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onClickPreview = () => {
      setDialogLoading(false)
      setAgreeButton(false)
      setDialogTitle("Preview")
      setOptions("playPreview")
      setMediaOption("playPreview")
      setDialogDes("")
      setFullScreenDialog(false)
      setOpenDialog(true)
    }

    const publishCampaign = () => {
      setDialogLoading(false)
      setAgreeButton(true)
      setDialogTitle("Publish")
      setOptions("publishBanner")
      setMediaOption("publishBanner")
      setDialogDes("Are you sure want to publish?")

      setFullScreenDialog(false)
      setOpenDialog(true)
    }

    const handleAgree = () => {

      
      if (option === "publishBanner") {
        firebase.database().ref("banner").set(rows)
      }

      setOpenDialog(false)
    }

    return (
      <Grid>
        <Dialog 
                agreeTxt={"Yes"}
                disagreeTxt={"No"}
                description={dialogDes}
                title={dialogTitle}
                open={openDialog} 
                agreeButton={agreeButton}
                fullScreenDialog={fullScreenDialog}
                option={option}
                dialogLoading={dialogLoading}
                handleAgree={handleAgree}
                component={renderComponent()}
                handleClose={handleClose}/>
                 <Grid container>
                  <Grid item lg={4} xs={12} >
                        <Grid style={{ display: "flex", width: "100%", padding: 20 }}  >
                          <Button onClick={addContent} color="primary" style={{ width: "100%" , height: "100%"}} 
                          startIcon={<AddCircleIcon/>} 
                          disableElevation  variant="contained">{"Add Content"}</Button>
                        </Grid>
                        <Grid style={{ display: "flex", width: "100%", paddingLeft: 20, paddingRight: 20, paddingBottom: 20 }}  >
                            <Button disabled={rows.length > 0 ? false : true} color="primary" style={{ width: "100%", height: "100%" }}
                                startIcon={<DeleteIcon />}
                                onClick={onClickDelete}
                                disableElevation variant="outlined">Delete</Button>
                        </Grid>

                        <Grid style={{ display: "flex", width: "100%", paddingLeft: 20, paddingRight: 20, paddingBottom: 20 }}  >
                          <Grid style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <TextField
                                  disabled={rows.length > 0 && selecteditem ? rows.map(val => val).indexOf(selecteditem) !== -1 && selecteditem.type === "image/jpeg" ? false : true : true}
                                  className={classes.duration}
                                  label="Duration"
                                  type="number"
                                  onChange={onChangeduration}
                                  value={rows.length > 0 && selecteditem ? !rows.map(val => val).indexOf(selecteditem) !== -1 && selecteditem.type === "image/jpeg" ? duration : 0 : 0}
                                  variant="outlined"
                              />
                          </Grid>
                          <Grid style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                              <IconButton style={{ marginBottom: -5 }}
                                  disabled={rows.length > 0 && selecteditem ? rows.map(val => val).indexOf(selecteditem) !== -1 && selecteditem.type === "image/jpeg" ? false : true : true}
                                  onClick={addDuration}>
                                  <AddCircleIcon style={{ color: rows.length > 0 && selecteditem ? rows.map(val => val).indexOf(selecteditem) !== -1 && selecteditem.type === "image/jpeg" ? "#556ee6" : "#9f9f9f" : "#9f9f9f" }} />
                              </IconButton>
                              <IconButton

                                  disabled={rows.length > 0 && selecteditem ? rows.map(val => val).indexOf(selecteditem) !== -1 && selecteditem.type === "image/jpeg" ? false : true : true}
                                  onClick={minusDuration}>
                                  <RemoveCircleIcon style={{ color: rows.length > 0 && selecteditem ? rows.map(val => val).indexOf(selecteditem) !== -1 && selecteditem.type === "image/jpeg" ? "#556ee6" : "#9f9f9f" : "#9f9f9f" }} />
                              </IconButton>
                          </Grid>
                        </Grid>

                        <Grid style={{ display: "flex", flexDirection: "column", paddingLeft: 20, paddingRight: 20, paddingBottom: 10 }}>
                          <Typography variant="caption">Drag the contents to change sequence</Typography>
                        </Grid>

                      <Grid>
                        <TableContainer>
                            <Table className={classes.table} aria-label="custom pagination table">
                                <EnhancedTableHead

                                    classes={classes}
                                    numSelected={selected.length}
                                    onSelectAllClick={handleSelectAllClick}
                                    rowCount={selected.length}
                                    headCells={[
                                        { id: 'no', numeric: false, disablePadding: false, label: 'No.' },
                                        { id: 'thumbnail', numeric: false, disablePadding: false, label: '' },
                                        { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
                                        { id: 'selected', numeric: true, disablePadding: false, label: '' }]}
                                />

                                <TableBody component={DroppableComponent(onDragEnd)}>
                                    {(rowsPerPage > 0
                                        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : rows
                                    ).map((row, index) => {
                                        const isItemSelected = isSelected(row);
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow hover
                                                component={DraggableComponent(row.uid, index)}
                                                key={labelId}
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                onClick={() => onClickRow(row)}

                                                selected={isItemSelected} >
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        onClick={(event) => handleCheckBoxClick(event, row)}
                                                        checked={isItemSelected}
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </TableCell>

                                                <TableCell style={{ padding: 3, width: 10, overflow: "hidden", textOverflow: "ellipsis" }} align="left">
                                                    <Typography noWrap variant="body2">{(index + 1) + (page * 10) + "."}</Typography>
                                                </TableCell>
                                                <TableCell style={{ padding: 3, width: 50 }} align="left">
                                                    {row.type === "image/jpeg" ?
                                                        <Avatar variant="rounded" alt={row.file_name} src={row.url}>
                                                        </Avatar> :
                                                        <Avatar variant="rounded">
                                                            <ImageIcon />
                                                        </Avatar>}
                                                </TableCell>
                                                <TableCell style={{ padding: 3, overflow: "hidden", textOverflow: "ellipsis", maxWidth: 150 }} align="left">
                                                    <Typography noWrap variant="body2">{row.file_name}</Typography>
                                                </TableCell>
                                                <TableCell style={{ padding: 3, paddingRight: 10, width: 40 }} align="right">
                                                    {selecteditem && row.uid === selecteditem.uid ? <CheckIcon color="primary" /> : ""}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}

                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            labelRowsPerPage={"Pages"}
                                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                            colSpan={6}
                                            count={rows.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{
                                                inputProps: { 'aria-label': 'rows per page' },
                                                native: true,
                                            }}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            //ActionsComponent={TablePaginationActions}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                      </Grid>
                  </Grid>
                  <Grid item lg={4} xs={12} style={{ padding: 20 }}>
                    {selecteditem ?
                        <Grid>
                            {selecteditem.type === "image/jpeg" ?
                                <img style={{ borderRadius: 5 }} width={"100%"} alt={selecteditem.file_name} src={selecteditem.url} />
                                : <ReactPlayer controls style={{ borderRadius: 5 }} width={"100%"} alt={selecteditem.file_name} url={selecteditem.url} />}
                        </Grid> :
                        <Grid style={{ backgroundColor: '#4a4a4a', minHeight: 200, borderRadius: 5 }}></Grid>}
                  </Grid>
                  <Grid item lg={4} xs={12} >
                    <Grid style={{ padding: 20 }}>
                        <Grid container spacing={2}>
                            <Grid item md={6} xs={4}>
                                <Button onClick={onClickPreview} color="primary" style={{ width: "100%", }}
                                    startIcon={<VisibilityIcon />}
                                    disabled={rows.length > 0 ? false : true}
                                    disableElevation variant="outlined" >
                                    Preview
                                </Button>
                            </Grid>
                            <Grid item md={6} xs={4}>
                                <Button onClick={() => publishCampaign()} color="primary" style={{ width: "100%", }}
                                    startIcon={<PublishIcon />}
                                    disabled={rows.length > 0 ? false : true}
                                    disableElevation variant="contained" >
                                    Publish
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                  </Grid>
              </Grid>
      </Grid>
       
    )

}
  