import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import FolderIcon from '@material-ui/icons/Folder';
import InputIcon from '@material-ui/icons/Input';
import DeleteIcon from '@material-ui/icons/Delete';
import PreviewIcon from '@material-ui/icons/Visibility';
import Receipt from '@material-ui/icons/Receipt';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ReceiptIcon from '@material-ui/icons/Receipt';
import GetAppIcon from '@material-ui/icons/GetApp';

export default function ControlButton(props){

    return(
      <Grid>
        {props.data === "concept_store" ? <Tooltip title="Download Sales Report">
          <IconButton component="div" style={{ opacity: .5 }} color="inherit" onClick={() => props.onClickDownloadSaleReport(props.row)}>
              <GetAppIcon fontSize="small" />
          </IconButton>
        </Tooltip> : null }
         {props.data === "concept_store" ? <Tooltip title="Sales Report">
          <IconButton component="div" style={{ opacity: .5 }} color="inherit" onClick={() => props.onClickSaleReport(props.row)}>
              <AssessmentIcon fontSize="small" />
          </IconButton>
        </Tooltip> : null }
        {props.data === "concept_store" ? <Tooltip title="Orders">
          <IconButton component="div" style={{ opacity: .5 }} color="inherit" onClick={() => props.onClickOrders(props.row)}>
              <AssignmentTurnedInIcon fontSize="small" />
          </IconButton>
        </Tooltip> : null }
        {props.data === "concept_store" ? <Tooltip title="Stock">
          <IconButton component="div" style={{ opacity: .5 }} color="inherit" onClick={() => props.onClickProducts(props.row)}>
              <FormatListNumberedIcon fontSize="small" />
          </IconButton>
        </Tooltip> : null }
        {props.previewButton ? <Tooltip title="Preview">
          <IconButton component="div" color="primary" onClick={() => props.onClickPreview(props.row)}>
              <PreviewIcon fontSize="small" />
          </IconButton>
        </Tooltip> : null }
        {props.type === "folder" ? <Tooltip title="Enter Folder">
          <IconButton component="div" color="primary" onClick={() => props.onClickOpenFolder(props.row)}>
              <FolderIcon fontSize="small" />
          </IconButton>
        </Tooltip> : null }
        {props.data === "orders" ? <Tooltip title="Proof of Payment">
          <IconButton disabled={!props.row.proof_of_payment} component="div" style={{ opacity: .5 }} color="inherit" onClick={() => props.onClickProofOfPayment(props.row)}>
              <Receipt fontSize="small" />
          </IconButton>
        </Tooltip> : null }
        <Tooltip title="Info">
          <IconButton component="div" style={{ opacity: .5 }} color="inherit" onClick={() => props.onClickInfo(props.row)}>
              <InfoIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        {props.type === "folder" || props.hideMove ? null : <Tooltip title="Move">
          <IconButton component="div" style={{ display: props.disabledMove? "none": "normal", opacity: .5 }} color="inherit" onClick={() => props.onClickMove(props.row)}>
              <InputIcon fontSize="small" />
          </IconButton>
        </Tooltip>}

        {props.orderReceiptButton ? <Tooltip title="Receipt">
          <IconButton component="div" style={{ opacity: .5 }} color="inherit" onClick={() => props.onClickReceipt(props.row)}>
              <ReceiptIcon fontSize="small" />
          </IconButton>
        </Tooltip>: null}
        {props.hideEdit ? null : <Tooltip title="Edit">
          <IconButton disabled={props.disabledEdit} component="div" style={{ opacity: .5 }} color="inherit" onClick={() => props.onClickEdit(props.row)}>
              <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>}
        {props.hideRemove ? null : <Tooltip title="Delete">
            <IconButton disabled={props.disabledDelete} component="div" style={{ opacity: .5 }} color="inherit" onClick={() => props.onClickDelete(props.row)}>
                <DeleteIcon fontSize="small" />
            </IconButton>
        </Tooltip>}
      </Grid>
    )
}