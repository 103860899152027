import React, { useEffect } from 'react';
import Table from '../../../modules/components/Table'
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AddEditRequest from './AddEditRequest';
import firebase from "firebase";

export default function Index(props) {

    const [headCells, setHeadCells] = React.useState([]);
    const [headMobileCells, setHeadMobileCells] = React.useState([]);
    const [searchValue, setSearchValue] = React.useState("");
    const [searchCell, setSearchCell] = React.useState([]);
    const [displayInfo, setDisplayInfo] = React.useState([]);
    const [editCells, setEditCells] = React.useState([]);
    const [addCells, setAddCells] = React.useState([]);
    const [addCellsName, setAddCellsName] = React.useState([]);
    const matches = useMediaQuery(theme => theme.breakpoints.up('sm'));

    useEffect(() => {

        setHeadCells([
            { id: 'user_uid', label: 'User' },
            { id: 'created', label: 'Created' },
            { id: 'subject', label: 'Subject' },
            { id: 'description', label: 'Description' }
        ])

        setHeadMobileCells([
            { id: 'subject', label: 'Subject' },
        ])
    
        setSearchCell([
            { id: 'user_uid', label: 'User' },
            { id: 'subject', label: 'Subject' },
            { id: 'description', label: 'Description' }
        ])

        setDisplayInfo([
            { id: 'user_uid', label: 'User' },
            { id: 'created', label: 'Created' },
            { id: 'subject', label: 'Subject' },
            { id: 'description', label: 'Description' }
        ])

        setEditCells([
            { id: 'subject', label: 'Subject' },
            { id: 'description', label: 'Description' }
        ])

        setAddCells([
            { id: 'subject', label: 'Subject' },
            { id: 'description', label: 'Description' }
        ])

        setAddCellsName({ 
            user_uid: '', 
            created: firebase.database.ServerValue.TIMESTAMP,
            updated: firebase.database.ServerValue.TIMESTAMP,
            subject: '',
            description: ''
         })
    
        setSearchValue("subject")
        
    }, []);

    return (
    <Grid>
        <Table 
             {...props}
            control={true}
            disabledMove={true}
            customizeDialog={AddEditRequest}
            orderBy="created" 
            data="request" 
            addCells={addCells}
            addCellsName={addCellsName}
            folder_data="request_zone"
            displayInfo={displayInfo}
            editCells={editCells}
            matches={matches}
            headCells={matches ? headCells : headMobileCells} 
            searchValue={searchValue} 
            searchCell={searchCell}/>
    </Grid>
    )

}
  