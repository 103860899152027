import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Dialog from '../../../modules/components/Dialog';
import Media from '../Media';
import Categories from '../Categories';
import Shipping from '../Shipping';
import Typography from '@material-ui/core/Typography';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import firebase from "firebase";

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #6e6e6e',
    width: 70,
    height: 70,
    padding: 4,
    boxSizing: 'border-box',
    marginLeft: 8,
    marginRight: 8,
    marginBottom: 8 
};

 
const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};

export default function Index(props) {

    //Dialog
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogLoading, setDialogLoading] = React.useState(false);
    const [fullScreenDialog, setFullScreenDialog] = React.useState(false);
    const [dialogTitle, setDialogTitle] = React.useState("");
    const [dialogDes, setDialogDes] = React.useState("");
    const [agreeButton, setAgreeButton] = React.useState(false);
    const [option, setOptions] = React.useState("");

    const [selected, setSelected] = React.useState([]);
    const [selectedGallery, setSelectedGallery] = React.useState([]);
    const [selectedCategories, setSelectedCategories] = React.useState([]);
    const [selectedShipping, setSelectedShipping] = React.useState([]);
    const [status, setStatus] = React.useState("published");
    const [mediaOption, setMediaOption] = React.useState("");
    const [values, setValues] = React.useState({});

    useEffect(() => {
        setValues(props.addCellsName ? props.addCellsName : {})
        
        if(props.option === "edit"){
            setValues(props.selectedItem ? props.selectedItem : {})
            setStatus(props.selectedItem.status ? props.selectedItem.status  : {})
            setSelected(props.selectedItem.image ? props.selectedItem.image : [])
            setSelectedGallery(props.selectedItem.gallery ? props.selectedItem.gallery : [])
            setSelectedCategories(props.selectedItem.categories ? props.selectedItem.categories : [])
        }

    }, [props]);

    const handleClose = () => {
        setOpenDialog(false)
    };

    const handleAgree = () => {

        var updates = {};
        if(props.option === "edit"){
            values.id = props.selectedItem.id;
            values.image = selected;
            values.gallery = selectedGallery;
            values.categories = selectedCategories;
            values.shipping = selectedShipping;
            values.status = status;

            updates[props.data + '/' + props.selectedItem.id] = values;
    
            firebase.database().ref().update(updates);
        }
        else{
            var newPostKey = firebase.database().ref().child(props.data).push().key;

            values.id = newPostKey;
            values.user = firebase.auth().currentUser.email;
            values.image = selected;
            values.gallery = selectedGallery;
            values.categories = selectedCategories;
            values.shipping = selectedShipping;
            values.status = status;
    
            updates[props.data + '/' + newPostKey] = values;
    
            firebase.database().ref().update(updates);
        }
        

        props.handleClose()
    }

    const handleSetRows = (row) => {
        if(mediaOption === "gallery"){
            setSelectedGallery(prev => [...prev,...row])
        }
        else if(mediaOption === "image")
        {
            setSelected(row)
        }
        else if(mediaOption === "categories")
        {
            setSelectedCategories(row)
        }
        else if(mediaOption === "shipping")
        {
            setSelectedShipping(row)
        }
       
        setOpenDialog(false)
    }

    const renderComponent = () => {
        if(mediaOption === "categories"){
            return(<Categories mediaOption={mediaOption} handleSetRows={(rows) => handleSetRows(rows)}/>)
        }else if(mediaOption === "shipping"){
            return(<Shipping mediaOption={mediaOption} handleSetRows={(rows) => handleSetRows(rows)}/>)
        }else{
            return(<Media mediaOption={mediaOption} handleSetRows={(rows) => handleSetRows(rows)}/>)
        }
        
    }

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleCheckChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.checked });
    };

    const handleChangeStatus = (event) => {
        
        setStatus(event.target.value)
    }

    const addContent = () => {
        setMediaOption("image")
        setDialogLoading(false)
        setAgreeButton(false)
        setDialogTitle("Add Featured Image")
        setOptions("addItem")
        setDialogDes("")
        setFullScreenDialog(true)
        setOpenDialog(true)
    }

    const addGallery = () => {
        setMediaOption("gallery")
        setDialogLoading(false)
        setAgreeButton(false)
        setDialogTitle("Add Gallery")
        setOptions("addItem")
        setDialogDes("")
        setFullScreenDialog(true)
        setOpenDialog(true)
    }

    const addCategories = () => {
        setMediaOption("categories")
        setDialogLoading(false)
        setAgreeButton(false)
        setDialogTitle("Add Categories")
        setOptions("addItem")
        setDialogDes("")
        setFullScreenDialog(true)
        setOpenDialog(true)
    }

    const addShipping = () => {
        setMediaOption("shipping")
        setDialogLoading(false)
        setAgreeButton(false)
        setDialogTitle("Add Shipping")
        setOptions("addItem")
        setDialogDes("")
        setFullScreenDialog(true)
        setOpenDialog(true)
    }

    const onClickClear = () => {
        setSelected([])
    }

    const onClickClearGallery = () => {
        setSelectedGallery([])
    }

    const onClickClearCatgories = (event) => {
        setSelectedCategories([])
    }

    const onClickClearShipping = (event) => {
        setSelectedShipping([])
    }

    const thumbs = selected.map((file, index) => {

        if(file.type === "video/mp4"){
            
            return(
                <Grid key={file.name+index} style={{ display:"flex", flexDirection: "column"}}>
                    <Grid style={{overflow: "hidden", textOverflow: "ellipsis", width: 70, padding: 8}}>
                        <Typography style={{ color : "#6e6e6e"}} variant="caption" noWrap={true}>{file.name}</Typography>
                    </Grid>
                    
                    <Grid style={thumb} key={file.name}>
                        <Grid style={{ display: "flex", justifyContent:'center', alignItems: 'center', width: "100%"}}>
                            <VideoLibraryIcon color="primary" style={{ fontSize: 36}}/>
                        </Grid>
                    </Grid>
                </Grid>
               
            )
        }
        else{
            return(
                <Grid key={file.name+index} style={{ display:"flex", flexDirection: "column"}}>
                    <Grid style={{overflow: "hidden", textOverflow: "ellipsis", width: 70, padding: 8}}>
                        <Typography style={{ color : "#6e6e6e"}} variant="caption" noWrap={true} rows={2} >{file.name}</Typography>
                    </Grid>
                    
                    <Grid style={thumb} key={file.name}>
                        <Grid style={{ display: "flex", justifyContent:'center', alignItems: 'center', width: "100%", overflow: 'hidden'}}>
                            <img
                            src={file.url}
                            style={img}
                            alt={file.name}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )
        }
    });

    const thumbsGallery = selectedGallery.map((file, index) => {

        if(file.type === "video/mp4"){
            
            return(
                <Grid key={file.name+index} style={{ display:"flex", flexDirection: "column"}}>
                    <Grid style={{overflow: "hidden", textOverflow: "ellipsis", width: 70, padding: 8}}>
                        <Typography style={{ color : "#6e6e6e"}} variant="caption" noWrap={true}>{file.name}</Typography>
                    </Grid>
                    
                    <Grid style={thumb} key={file.name}>
                        <Grid style={{ display: "flex", justifyContent:'center', alignItems: 'center', width: "100%"}}>
                            <VideoLibraryIcon color="primary" style={{ fontSize: 36}}/>
                        </Grid>
                    </Grid>
                </Grid>
               
            )
        }
        else{
            return(
                <Grid key={file.name+index} style={{ display:"flex", flexDirection: "column"}}>
                    <Grid style={{overflow: "hidden", textOverflow: "ellipsis", width: 70,  padding: 8}}>
                        <Typography style={{ color : "#6e6e6e"}} variant="caption" noWrap={true}>{file.name}</Typography>
                    </Grid>
                    
                    <Grid style={thumb} key={file.name}>
                        <Grid style={{ display: "flex", justifyContent:'center', alignItems: 'center', width: "100%", overflow: 'hidden'}}>
                            <img
                            src={file.url}
                            style={img}
                            alt={file.name}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )
        }
    });

    const thumbsCategories = selectedCategories.map((file, index) => {

        return(
            <Grid key={file.name+index} style={{ display:"flex", flexDirection: "column"}}>
                <Grid style={{overflow: "hidden", padding: 8}}>
                    <Typography multiline
                                rows={2} style={{ color : "white"}} variant="caption" noWrap={true}>{file.name}</Typography>
                </Grid>
            </Grid>
           
        )
    });

    const thumbsShipping = selectedShipping.map((file, index) => {

        return(
            <Grid key={file.name+index} style={{ display:"flex", flexDirection: "column"}}>
                <Grid style={{overflow: "hidden", padding: 8}}>
                    <Typography multiline
                                rows={2} style={{ color : "white"}} variant="caption" noWrap={true}>{file.name}</Typography>
                </Grid>
            </Grid>
           
        )
    });


    return (
        <Grid style={{ maxWidth: 625  }}>
            <Dialog 
                agreeTxt={"Yes"}
                disagreeTxt={"No"}
                description={dialogDes}
                title={dialogTitle}
                open={openDialog} 
                agreeButton={agreeButton}
                fullScreenDialog={fullScreenDialog}
                option={option}
                dialogLoading={dialogLoading}
                component={renderComponent()}
                handleClose={handleClose}/>

            <Grid container style={{ paddingBottom: 10, display: "flex", flexDirection: "row" }}>
                {props[props.option === "edit" ? "editCells" : "addCells"] ? props[props.option === "edit" ? "editCells" : "addCells"].map((cell, index)=>{ 
                const labelCellId = `add-cell-${index}`;
                var sizeMd = 6;

                if(index === props.addCells.length - 1){
                    if((index+1) % 2 !== 0){
                    sizeMd = 12;
                    } 
                }

                if(cell.id === "image"){
                    return(<Grid key={labelCellId} xs={12} md={12} style={{flexDirection: "column", padding: 10, display: "flex", justifyContent: "flex-start", alignItems: "center"}} item >
                    <Grid container style={{ width: "100%" , height: "100%"}}>
                        
                        <Grid item md={9}>
                        <Button onClick={addContent} color="primary" style={{ width: "100%" , height: "100%"}} 
                        startIcon={<AddCircleIcon/>} 
                        disableElevation  variant="contained">{"Featured Image"}</Button>
                        </Grid>

                        <Grid item md={3} style={{ paddingLeft: 10 }} >
                        <Button style={{ width: "100%" , height: "100%" }} disabled={selected.length > 0 ? false : true } onClick={onClickClear} variant="outlined" color="primary">
                            Clear
                        </Button>
                        </Grid>
                    
                    </Grid>

                        <Grid>
                        <aside style={thumbsContainer}>
                            {thumbs}
                        </aside>
                        </Grid>
                        
                    </Grid>)
                }

                if(cell.id === "gallery"){
                    return(<Grid key={labelCellId} xs={12} md={12} style={{flexDirection: "column", padding: 10, display: "flex", justifyContent: "flex-start", alignItems: "center"}} item >
                    <Grid container style={{ width: "100%" , height: "100%"}}>
                        
                        <Grid item md={9}>
                        <Button onClick={addGallery} color="primary" style={{ width: "100%" , height: "100%"}} 
                        startIcon={<AddCircleIcon/>} 
                        disableElevation  variant="contained">{"Gallery"}</Button>
                        </Grid>

                        <Grid item md={3} style={{ paddingLeft: 10 }} >
                        <Button style={{ width: "100%" , height: "100%" }} disabled={selectedGallery.length > 0 ? false : true } 
                        onClick={onClickClearGallery} variant="outlined" color="primary">
                            Clear
                        </Button>
                        </Grid>
                    
                    </Grid>

                        <Grid>
                        <aside style={thumbsContainer}>
                            {thumbsGallery}
                        </aside>
                        </Grid>
                        
                    </Grid>)
                }

                if(cell.id === "description"){
                    return (<Grid key={labelCellId} xs={12} md={12} style={{  padding: 10, display: "flex", justifyContent: "center", alignItems: "center"}} item >
                             <TextField
                                key={"textfield-input-"+index}
                                label={cell.label}
                                style={{ width: "100%"}}
                                multiline
                                value={values[cell.id] || ''}
                                onChange={handleChange(cell.id)}
                                variant="outlined"/></Grid>
                        )
                }

                if(cell.id === "redeem"){
                    return (<Grid key={labelCellId} xs={12} md={sizeMd} style={{  padding: 10, display: "flex", justifyContent: "center", alignItems: "center"}} item >
                             <FormControlLabel
                                control={
                                <Checkbox
                                    checked={values[cell.id] ? true : false }
                                    onChange={handleCheckChange(cell.id)}
                                    color="primary"
                                />
                                }
                                label="Enable Redemption"
                            /></Grid>
                        )
                }

                if(cell.id === "price" || cell.id === "sale_price" || cell.id === "sale_price_2" || cell.id === "retail_price" || cell.id === "discount_price"){
                    return (<Grid key={labelCellId} xs={12} md={sizeMd} style={{  padding: 10, display: "flex", justifyContent: "center", alignItems: "center"}} item >
                            <TextField
                                key={"textfield-input-"+index}
                                label={cell.label}
                                style={{ width: "100%"}}
                                type="number"
                                InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        RM
                                      </InputAdornment>
                                    ),
                                }}
                                value={values[cell.id] || ''}
                                onChange={handleChange(cell.id)}
                                variant="outlined"/>
                            </Grid>)
                }

                if(cell.id === "quantity" || cell.id === "in_stock" || cell.id === "point" || cell.id === "product_point"){
                    return (<Grid key={labelCellId} xs={12} md={sizeMd} style={{  padding: 10, display: "flex", justifyContent: "center", alignItems: "center"}} item >
                            <TextField
                                key={"textfield-input-"+index}
                                label={cell.label}
                                style={{ width: "100%"}}
                                type="number"
                                value={values[cell.id] || ''}
                                onChange={handleChange(cell.id)}
                                variant="outlined"/>
                            </Grid>)
                }

                if(cell.id === "status"){
                    return (<Grid key={labelCellId} xs={12} md={sizeMd} style={{  padding: 10, display: "flex", justifyContent: "center", alignItems: "center"}} item >
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Status</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={status}
                            label="Status"
                            onChange={handleChangeStatus}
                            >
                            <MenuItem value={"published"}>Published</MenuItem>
                            <MenuItem value={"draft"}>Draft</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>)
                }

                if(cell.id === "categories"){
                    return(<Grid key={labelCellId} xs={12} md={12} style={{flexDirection: "column", padding: 10, display: "flex", justifyContent: "flex-start", alignItems: "center"}} item >
                    <Grid container style={{ width: "100%" , height: "100%"}}>
                        
                        <Grid item md={9}>
                        <Button onClick={addCategories} color="primary" style={{ width: "100%" , height: "100%"}} 
                        startIcon={<AddCircleIcon/>} 
                        disableElevation  variant="contained">{"Categories"}</Button>
                        </Grid>

                        <Grid item md={3} style={{ paddingLeft: 10 }} >
                        <Button style={{ width: "100%" , height: "100%" }} disabled={selectedCategories.length > 0 ? false : true } 
                        onClick={onClickClearCatgories} variant="outlined" color="primary">
                            Clear
                        </Button>
                        </Grid>
                    
                    </Grid>

                        <Grid>
                        <aside style={thumbsContainer}>
                            {thumbsCategories}
                        </aside>
                        </Grid>
                        
                    </Grid>)
                }

                if(cell.id === "user"){
                    return(<Grid key={labelCellId} xs={12} md={12} style={{flexDirection: "column", padding: 10, display: "flex", justifyContent: "flex-start", alignItems: "center"}} item >
                    <Grid container style={{ width: "100%" , height: "100%"}}>
                        
                        <Grid item md={9}>
                        <Button onClick={addShipping} color="primary" style={{ width: "100%" , height: "100%"}} 
                        startIcon={<AddCircleIcon/>} 
                        disableElevation  variant="contained">{"Shipping"}</Button>
                        </Grid>

                        <Grid item md={3} style={{ paddingLeft: 10 }} >
                        <Button style={{ width: "100%" , height: "100%" }} disabled={selectedShipping.length > 0 ? false : true } 
                        onClick={onClickClearShipping} variant="outlined" color="primary">
                            Clear
                        </Button>
                        </Grid>
                    
                    </Grid>

                        <Grid>
                        <aside style={thumbsContainer}>
                            {thumbsShipping}
                        </aside>
                        </Grid>
                        
                    </Grid>)
                }
                
                return(   
                    <Grid key={labelCellId} xs={12} md={sizeMd} style={{  padding: 10, display: "flex", justifyContent: "center", alignItems: "center"}} item >
                    <TextField
                        key={"textfield-input-"+index}
                        label={cell.label}
                        style={{ width: "100%"}}
                        value={values[cell.id] || ''}
                        onChange={handleChange(cell.id)}
                        variant="outlined"/></Grid>
                    )}):null }
            </Grid>
            
            <Grid style={{ padding: 10, marginBottom: 30, display: 'flex', justifyContent: "flex-end" }}>
                <Button variant="outlined" style={{ fontSize: 14, fontWeight: 'bold'}} onClick={props.handleClose} color="primary">
                {"No"}
                </Button>
                <Button disableElevation variant="contained" style={{ marginLeft: 10, fontSize: 14, fontWeight: 'bold'}} onClick={handleAgree} color="primary" autoFocus>
                {"Yes"}
                </Button>
            </Grid>
        </Grid>
    )

}
  