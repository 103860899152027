import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';
import app from "../../firebase/base";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import PersonIcon from '@material-ui/icons/Person';
//import DashboardIcon from '@material-ui/icons/Dashboard';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';
import Description from '@material-ui/icons/Description';
import RedeemIcon from '@material-ui/icons/Redeem';
import Assignment from '@material-ui/icons/Assignment';
import Receipt from '@material-ui/icons/Receipt';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import MessageIcon from '@material-ui/icons/Message';
import Image from '@material-ui/icons/Image';
import PanoramaIcon from '@material-ui/icons/Panorama';
import firebase from "firebase";
import StoreMallDirectoryIcon from '@material-ui/icons/StoreMallDirectory';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import ContactsIcon from '@material-ui/icons/Contacts';
import StorefrontIcon from '@material-ui/icons/Storefront';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import AllInboxIcon from '@material-ui/icons/AllInbox';

const styles = (theme) => ({
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  item: {
    paddingTop: 1,
    paddingBottom: 1,
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover,&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  itemCategory: {
    boxShadow: '0 -1px 0 #404854 inset',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  firebase: {
    fontSize: 24,
    color: theme.palette.common.white,
  },
  itemActiveItem: {
    color: theme.palette.primary.main,
  },
  itemPrimary: {
    fontSize: 'inherit',
  },
  itemIcon: {
    minWidth: 'auto',
    marginRight: theme.spacing(2),
  },
  notificationIcon: {
    minWidth: 'auto',
    marginLeft: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});

function Navigator(props) {
  const { classes, staticContext,  ...other } = props;
  const path = props.location.pathname.slice(1);
  const [open, setOpen] = React.useState(false);
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [categories, setCategories] = React.useState([
    {
      id: 'Manage',
      children: [
        //{ id: 'Dashboard', icon: <DashboardIcon />, active: true, url: '' },
        { id: 'Media', icon: <Image />, url: 'media', notification: 0 },
        { id: 'Products', icon: <OfflineBoltIcon />, url: 'products', notification: 0 },
        { id: 'Package', icon: <AllInboxIcon />, url: 'package', notification: 0 },
        { id: 'Categories', icon: <DynamicFeedIcon />, url: 'categories', notification: 0 },
        { id: 'Orders', icon: <AssignmentTurnedInIcon />, url: 'orders', notification: 0 },
        { id: 'Order Status', icon: <Assignment />, url: 'orders_status', notification: 0 },
        { id: 'Invoices', icon: <Description />, url: 'invoices', notification: 0 },
        { id: 'Receipts', icon: <Receipt />, url: 'receipts', notification: 0 },
        { id: 'Delivery Order', icon: <LocalShippingIcon />, url: 'delivery_order', notification: 0 },
        { id: 'User Level', icon: <RecentActorsIcon />, url: 'user_level', notification: 0 },
        { id: 'Store', icon: <StoreMallDirectoryIcon />, url: 'users', notification: 0 },
        { id: 'Concept Store', icon: <StorefrontIcon />, url: 'concept_store', notification: 0 },
        { id: 'Agent', icon: <PersonIcon />, url: 'agent', notification: 0 },
        { id: 'Salesman', icon: <PermContactCalendarIcon />, url: 'salesman', notification: 0 },
        { id: 'KOL', icon: <ContactsIcon />, url: 'kol', notification: 0 },
        { id: 'Customer', icon: <SupervisorAccountIcon />, url: 'customer', notification: 0 },
        { id: 'Warranty', icon: <VerifiedUserIcon />, url: 'warranty', notification: 0 },
        { id: 'Gift Redeem', icon: <RedeemIcon />, url: 'gift_redeem', notification: 0 },
        //{ id: 'Coupons', icon: <ConfirmationNumberIcon />, url: 'coupons', notification: 0 },
        { id: 'Shipping', icon: <LocalShippingIcon />, url: 'shipping', notification: 0 },
        //{ id: 'Request', icon: <MessageIcon />, url: 'request', notification: 0 },
        { id: 'Banner', icon: <PanoramaIcon />, url: 'banner', notification: 0 },
        
      ],
    }
  ]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSignout = () => {

    setBackdropOpen(true)

    app.auth().signOut().then(() => {
      setBackdropOpen(false)
    }).catch((error) => {
      setBackdropOpen(false)
    });

    setOpen(false);
  };
  
  return (
    <div>
    <Backdrop className={classes.backdrop} open={backdropOpen}>
      <CircularProgress 
        disableShrink
        color="inherit" />
    </Backdrop>
    <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: '#212121',
            boxShadow: 'none',
          },
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography style={{ fontSize: 24, fontWeight: 'bold'}}>Logout</Typography>
        </DialogTitle>
        <DialogContent >
          <DialogContentText id="alert-dialog-description">
            You will be returned to the login screen
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" style={{ fontSize: 14, fontWeight: 'bold'}} onClick={handleClose} color="primary">
            No 
          </Button>
          <Button disableElevation variant="contained" style={{ fontSize: 14, fontWeight: 'bold'}} onClick={handleSignout} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
    </Dialog>
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem style={{ display:'flex', justifyContent: 'center'}}  className={clsx(classes.firebase, classes.itemCategory)}>
          <img height={80} src="/assets/logo/logo-white.png" alt="Sp2s Logo"/>
        </ListItem>
        
        {categories.map(({ id, children }) => (
          <React.Fragment key={id}>
            <ListItem className={classes.categoryHeader}>
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary,
                }}
              >
                {id}
              </ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, notification, url }) => (
              <ListItem
                key={childId}
                button
                className={path === url ? clsx(classes.item, true && classes.itemActiveItem) : clsx(classes.item, false && classes.itemActiveItem)}
                component={Link} to={'/' + url.toLowerCase()} 
                onClick={props.onClose}
              >
                
                <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                <ListItemText
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                >
                  {childId}
                </ListItemText>

                <ListItemIcon className={classes.notificationIcon}>{notification > 0 ? notification : ''}</ListItemIcon>
              </ListItem>
            ))}

            <Divider className={classes.divider} />
          </React.Fragment>
        ))}

        <ListItem
          button
          style={{ marginTop: 10 }}
          className={clsx(classes.item, false && classes.itemActiveItem)}
          onClick={handleClickOpen}
        >
          <ListItemIcon className={classes.itemIcon}><ExitToAppIcon /></ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.itemPrimary,
            }}
          >
            {'Logout'}
          </ListItemText>
        </ListItem>
          </List>
    </Drawer>
    </div>
  );
}

Navigator.propTypes = {
  classes: PropTypes.object.isRequired,
};

const routeNavigator = withRouter(Navigator);

export default withStyles(styles)(routeNavigator);