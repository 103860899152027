import React, { useEffect } from 'react';
import Table from '../../../modules/components/Table'
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddEditPackage from './AddEditPackage';
import firebase from "firebase";

export default function Index(props) {

    const [headCells, setHeadCells] = React.useState([]);
    const [headMobileCells, setHeadMobileCells] = React.useState([]);
    const [searchValue, setSearchValue] = React.useState("");
    const [searchCell, setSearchCell] = React.useState([]);
    const [displayInfo, setDisplayInfo] = React.useState([]);
    const [editCells, setEditCells] = React.useState([]);
    const [addCells, setAddCells] = React.useState([]);
    const [addCellsName, setAddCellsName] = React.useState([]);
    const matches = useMediaQuery(theme => theme.breakpoints.up('sm'));

    useEffect(() => {

        setHeadCells([
            { id: 'name', label: 'Name' },
            { id: 'package_categories', label: 'Package Catogories' },
            { id: 'package_quantity', label: 'Package quantity' },
            { id: 'foc_categories', label: 'FOC' },
            { id: 'foc_quantity', label: 'FOC quantity' },
            { id: 'status', label: 'Status' },
        ])

        setHeadMobileCells([
            { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
        ])
    
        setSearchCell([
            { id: 'id', label: 'ID' },
            { id: 'name', label: 'Name' },
        ])

        setDisplayInfo([
            { id: 'id', label: 'ID' },
            { id: 'package_categories', label: 'Package Catogories' },
            { id: 'package_quantity', label: 'Package quantity' },
            { id: 'foc_categories', label: 'FOC' },
            { id: 'foc_quantity', label: 'FOC quantity' },
            { id: 'status', label: 'Status' },
        ])

        setEditCells([
            { id: 'name', label: 'Name' },
            { id: 'package_categories', label: 'Package Catogories' },
            { id: 'package_quantity', label: 'Package quantity' },
            { id: 'foc_categories', label: 'FOC' },
            { id: 'foc_quantity', label: 'FOC quantity' },
            { id: 'status', label: 'Status' },
        ])

        setAddCells([
            { id: 'name', label: 'Name' },
            { id: 'package_categories', label: 'Package Catogories' },
            { id: 'package_quantity', label: 'Package quantity' },
            { id: 'foc_categories', label: 'FOC' },
            { id: 'foc_quantity', label: 'FOC quantity' },
            { id: 'status', label: 'Status' },
        ])

        setAddCellsName({ 
            name: '', 
            created: firebase.database.ServerValue.TIMESTAMP,
            updated: firebase.database.ServerValue.TIMESTAMP,
            status: 'published', 
            package_categories: [],
            package_quantity: "1",
            foc_categories: [],
            foc_quantity: "1",
         })
    
        setSearchValue("name")

       
        
    }, []);

    return (
    <Grid>
        <Table 
            {...props}
            customizeDialog={AddEditPackage}
            control={true}
            orderBy="created" 
            data="package" 
            addItemButton={true}
            addItemText="Add package"
            addItemIcon={<AddCircleIcon/>}
            addCells={addCells}
            addCellsName={addCellsName}
            folder_data="package_zone"
            displayInfo={displayInfo}
            editCells={editCells}
            matches={matches}
            headCells={matches ? headCells : headMobileCells} 
            searchValue={searchValue} 
            searchCell={searchCell}/>
    </Grid>
    )

}
  