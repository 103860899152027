import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Dialog from '../../../modules/components/Dialog';
import Media from '../Media';
import Categories from '../Categories';
import Products from '../Products';
import Typography from '@material-ui/core/Typography';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import firebase from "firebase";

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
  } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";


const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #6e6e6e',
    width: 70,
    height: 70,
    padding: 4,
    boxSizing: 'border-box',
    marginLeft: 8,
    marginRight: 8,
    marginBottom: 8 
};

 
const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};

export default function Index(props) {

    //Dialog
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogLoading, setDialogLoading] = React.useState(false);
    const [fullScreenDialog, setFullScreenDialog] = React.useState(false);
    const [dialogTitle, setDialogTitle] = React.useState("");
    const [dialogDes, setDialogDes] = React.useState("");
    const [agreeButton, setAgreeButton] = React.useState(false);
    const [option, setOptions] = React.useState("");

    const [selected, setSelected] = React.useState([]);
    const [selectedGallery, setSelectedGallery] = React.useState([]);
    const [selectedCategories, setSelectedCategories] = React.useState([]);
    const [selectedProduct, setSelectedProduct] = React.useState([]);
    const [selectedFreeProduct, setSelectedFreeProduct] = React.useState([]);
    const [selectedFreeCategory, setSelectedFreeCategory] = React.useState([]);
    const [couponType, setCouponType] = React.useState("free_item_by_product");
    const [discountPrice, setDiscountPrice] = React.useState("");
    const [status, setStatus] = React.useState("published");
    const [mediaOption, setMediaOption] = React.useState("");
    const [values, setValues] = React.useState({});

    const [selectedDate, setDate] = React.useState(moment());

    useEffect(() => {
        setValues(props.addCellsName ? props.addCellsName : {})
        
        if(props.option === "edit"){
            console.log()
            setValues(props.selectedItem ? props.selectedItem : {})
            setSelected(props.selectedItem.image ? props.selectedItem.image : [])
            setCouponType(props.selectedItem.coupon_type ? props.selectedItem.coupon_type : "free_item_by_product")
            setSelectedProduct(props.selectedItem.products ? props.selectedItem.products : [])
            setSelectedFreeProduct(props.selectedItem.free_products ? props.selectedItem.free_products : [])
            setSelectedFreeCategory(props.selectedItem.free_products_by_category ? props.selectedItem.free_products_by_category : [])
            setSelectedGallery(props.selectedItem.gallery ? props.selectedItem.gallery : [])
            setSelectedCategories(props.selectedItem.categories ? props.selectedItem.categories : []);
            setDate(props.selectedItem.expired ? moment.unix(props.selectedItem.expired) : moment());
        }


    }, [props]);

    const handleClose = () => {
        setOpenDialog(false)
    };

    const handleAgree = () => {

        var updates = {};

        if(props.option === "edit"){
            values.id = props.selectedItem.id;
            values.image = selected;
            values.products = selectedProduct;
            values.free_products = selectedFreeProduct;
            values.free_products_by_category = selectedFreeCategory;
            values.expired = selectedDate.unix();
            values.coupon_type= couponType;

            updates[props.data + '/' + props.selectedItem.id] = values;
    
            firebase.database().ref().update(updates);
        }
        else{
            var newPostKey = firebase.database().ref().child(props.data).push().key;

            values.id = newPostKey;
            values.user = firebase.auth().currentUser.email;
            values.image = selected;
            values.products = selectedProduct;
            values.free_products = selectedFreeProduct;
            values.free_products_by_category = selectedFreeCategory;
            values.expired = selectedDate.unix();
            values.coupon_type= couponType;
    
            updates[props.data + '/' + newPostKey] = values;
    
            firebase.database().ref().update(updates);
        }
        

        props.handleClose()
    }

    const handleSetRows = (row) => {
        if(mediaOption === "gallery"){
            setSelectedGallery(prev => [...prev,...row])
        }
        else if(mediaOption === "image")
        {
            setSelected(row)
        }
        else if(mediaOption === "categories")
        {
            setSelectedCategories(row)
        }
        else if(mediaOption === "product"){
            setSelectedProduct(prev => [...prev,...row])
        }
        else if(mediaOption === "free_product_by_product"){
            setSelectedFreeProduct(prev => [...prev,...row])
        }
        else if(mediaOption === "free_product_by_category"){
            setSelectedFreeCategory(prev => [...prev,...row])
        }
       
        setOpenDialog(false)
    }

    const renderComponent = () => {
        if(mediaOption === "categories" || mediaOption === "free_product_by_category"){
            return(<Categories mediaOption={mediaOption} handleSetRows={(rows) => handleSetRows(rows)}/>)
        }
        else if(mediaOption === "product" || mediaOption === "free_product_by_product"){
            return(<Products mediaOption={mediaOption} handleSetRows={(rows) => handleSetRows(rows)}/>)
        }else{
            return(<Media mediaOption={mediaOption} handleSetRows={(rows) => handleSetRows(rows)}/>)
        }
        
    }

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleChangeStatus = (event) => {
        values.status = event.target.value
        setStatus(event.target.value)
    }

    

    const addContent = () => {
        setMediaOption("image")
        setDialogLoading(false)
        setAgreeButton(false)
        setDialogTitle("Add Featured Image")
        setOptions("addItem")
        setDialogDes("")
        setFullScreenDialog(true)
        setOpenDialog(true)
    }

    const addGallery = () => {
        setMediaOption("gallery")
        setDialogLoading(false)
        setAgreeButton(false)
        setDialogTitle("Add Gallery")
        setOptions("addItem")
        setDialogDes("")
        setFullScreenDialog(true)
        setOpenDialog(true)
    }

    const addCategories = () => {
        setMediaOption("categories")
        setDialogLoading(false)
        setAgreeButton(false)
        setDialogTitle("Add Categories")
        setOptions("addItem")
        setDialogDes("")
        setFullScreenDialog(true)
        setOpenDialog(true)
    }

    const addProduct = () => {
        setMediaOption("product")
        setDialogLoading(false)
        setAgreeButton(false)
        setDialogTitle("Add Product")
        setOptions("addItem")
        setDialogDes("")
        setFullScreenDialog(true)
        setOpenDialog(true)
    }

    const addFreeProduct = () => {
        setMediaOption("free_product_by_product")
        setDialogLoading(false)
        setAgreeButton(false)
        setDialogTitle("Add Free Product")
        setOptions("addItem")
        setDialogDes("")
        setFullScreenDialog(true)
        setOpenDialog(true)
    }

    const addFreeCategory = () => {
        setMediaOption("free_product_by_category")
        setDialogLoading(false)
        setAgreeButton(false)
        setDialogTitle("Add Category")
        setOptions("addItem")
        setDialogDes("")
        setFullScreenDialog(true)
        setOpenDialog(true)
    }

    const onClickClear = () => {
        setSelected([])
    }

    const onClickClearGallery = () => {
        setSelectedGallery([])
    }

    const onClickClearCatgories = (event) => {
        setSelectedCategories([])
    }

    const onClickClearProduct = (event) => {
        setSelectedProduct([])
    }

    const onClickClearFreeProduct = (event) => {
        setSelectedFreeProduct([])
    }

    const onClickClearFreeProductByCategory = (event) => {
        setSelectedFreeCategory([])
    }

    const onDateChange = (date, value) => {
        setDate(date);
    };

    const handleChangeType = (e) => {
        setCouponType(e.target.value)
    }

    const handleDiscountChange = (e) => {
        setDiscountPrice(e.target.value)
    }
    
    const dateFormatter = str => {
        return str;
    };

    const thumbs = selected.map((file, index) => {

        if(file.type === "video/mp4"){
            
            return(
                <Grid key={file.name+index} style={{ display:"flex", flexDirection: "column"}}>
                    <Grid style={{overflow: "hidden", textOverflow: "ellipsis", width: 70, padding: 8}}>
                        <Typography style={{ color : "#6e6e6e"}} variant="caption" noWrap={true}>{file.name}</Typography>
                    </Grid>
                    
                    <Grid style={thumb} key={file.name}>
                        <Grid style={{ display: "flex", justifyContent:'center', alignItems: 'center', width: "100%"}}>
                            <VideoLibraryIcon color="primary" style={{ fontSize: 36}}/>
                        </Grid>
                    </Grid>
                </Grid>
               
            )
        }
        else{
            return(
                <Grid key={file.name+index} style={{ display:"flex", flexDirection: "column"}}>
                    <Grid style={{overflow: "hidden", textOverflow: "ellipsis", width: 70, padding: 8}}>
                        <Typography style={{ color : "#6e6e6e"}} variant="caption" noWrap={true}>{file.name}</Typography>
                    </Grid>
                    
                    <Grid style={thumb} key={file.name}>
                        <Grid style={{ display: "flex", justifyContent:'center', alignItems: 'center', width: "100%", overflow: 'hidden'}}>
                            <img
                            src={file.url}
                            style={img}
                            alt={file.name}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )
        }
    });

    const thumbsGallery = selectedGallery.map((file, index) => {

        if(file.type === "video/mp4"){
            
            return(
                <Grid key={file.name+index} style={{ display:"flex", flexDirection: "column"}}>
                    <Grid style={{overflow: "hidden", textOverflow: "ellipsis", width: 70, padding: 8}}>
                        <Typography style={{ color : "#6e6e6e"}} variant="caption" noWrap={true}>{file.name}</Typography>
                    </Grid>
                    
                    <Grid style={thumb} key={file.name}>
                        <Grid style={{ display: "flex", justifyContent:'center', alignItems: 'center', width: "100%"}}>
                            <VideoLibraryIcon color="primary" style={{ fontSize: 36}}/>
                        </Grid>
                    </Grid>
                </Grid>
               
            )
        }
        else{
            return(
                <Grid key={file.name+index} style={{ display:"flex", flexDirection: "column"}}>
                    <Grid style={{overflow: "hidden", textOverflow: "ellipsis", width: 70,  padding: 8}}>
                        <Typography style={{ color : "#6e6e6e"}} variant="caption" noWrap={true}>{file.name}</Typography>
                    </Grid>
                    
                    <Grid style={thumb} key={file.name}>
                        <Grid style={{ display: "flex", justifyContent:'center', alignItems: 'center', width: "100%", overflow: 'hidden'}}>
                            <img
                            src={file.url}
                            style={img}
                            alt={file.name}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )
        }
    });

    const thumbsCategories = selectedCategories.map((file, index) => {

        return(
            <Grid key={file.name+index} style={{ display:"flex", flexDirection: "column"}}>
                <Grid style={{overflow: "hidden", padding: 8}}>
                    <Typography multiline
                                rows={2} style={{ color : "white"}} variant="caption" noWrap={true}>{file.name}</Typography>
                </Grid>
            </Grid>
           
        )
    });

    const thumbsProduct = selectedProduct.map((file, index) => {

        return(
            <Grid key={file.name+index} style={{ display:"flex", flexDirection: "column"}}>
                <Grid style={{overflow: "hidden", textOverflow: "ellipsis", width: 70, height: 60, padding: 8}}>
                    <Typography style={{ color : "#6e6e6e",
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2}} variant="caption" noWrap={false}>{file.name}</Typography>
                </Grid>
                
                <Grid style={thumb} key={file.name}>
                    <Grid style={{ display: "flex", justifyContent:'center', alignItems: 'center', width: "100%", overflow: 'hidden'}}>
                        <img
                        src={file.image ? file.image[0].url : ''}
                        style={img}
                        alt={file.name}
                        />
                    </Grid>
                </Grid>
                <Grid style={{overflow: "hidden", textOverflow: "ellipsis", width: 90,  padding: 8}}>
                    <TextField
                        label={"Min"}
                        style={{ width: "100%"}}
                        type="number"
                        value={file.coupon_min}
                        onChange={(e)=> onChangeProductQuantity(e, index)}
                        variant="outlined"/>
                </Grid>
            </Grid>
        )
    });

    const onChangeProductQuantity = (e, index) => {
        selectedProduct[index].coupon_min = Number(e.target.value);

        var _selectedProduct = [...selectedProduct]
        setSelectedProduct(_selectedProduct)
    }

    const thumbsFreeProduct = selectedFreeProduct.map((file, index) => {

        return(
            <Grid key={file.name+index} style={{ display:"flex", flexDirection: "column"}}>
                <Grid style={{overflow: "hidden", textOverflow: "ellipsis", width: 70, height: 60,  padding: 8}}>
                    <Typography style={{ color : "#6e6e6e", 
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2 }} variant="caption" noWrap={false}>{file.name}</Typography>
                </Grid>
                
                <Grid style={thumb} key={file.name}>
                    <Grid style={{ display: "flex", justifyContent:'center', alignItems: 'center', width: "100%", overflow: 'hidden'}}>
                        <img
                        src={file.image ? file.image[0].url : ''}
                        style={img}
                        alt={file.name}
                        />
                    </Grid>
                </Grid>
                <Grid style={{overflow: "hidden", textOverflow: "ellipsis", width: 90,  padding: 8}}>
                    <TextField
                        label={"Qty"}
                        style={{ width: "100%"}}
                        type="number"
                        value={file.coupon_quantity}
                        onChange={(e)=> onChangeFreeProductQuantity(e, index)}
                        variant="outlined"/>
                </Grid>
            </Grid>
        )
    });

    const onChangeFreeProductQuantity = (e, index) => {
        selectedFreeProduct[index].coupon_quantity = Number(e.target.value);

        var _selectedFreeProduct = [...selectedFreeProduct]
        setSelectedFreeProduct(_selectedFreeProduct)
    }

    const thumbsFreeCategory = selectedFreeCategory.map((file, index) => {

        return(
            <Grid key={file.name+index} style={{ display:"flex", flexDirection: "column"}}>
                <Grid style={{overflow: "hidden", textOverflow: "ellipsis", width: 70, height: 60, padding: 8}}>
                    <Typography style={{ color : "#6e6e6e",
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2 }} variant="caption" noWrap={false}>{file.name}</Typography>
                </Grid>
                
                <Grid style={thumb} key={file.name}>
                    <Grid style={{ display: "flex", justifyContent:'center', alignItems: 'center', width: "100%", overflow: 'hidden'}}>
                        <img
                        src={file.image ? file.image[0].url : ''}
                        style={img}
                        alt={file.name}
                        />
                    </Grid>
                </Grid>
                <Grid style={{overflow: "hidden", textOverflow: "ellipsis", width: 90, padding: 8}}>
                    <TextField
                        label={"Qty"}
                        style={{ width: "100%"}}
                        type="number"
                        value={file.coupon_quantity}
                        onChange={(e)=> onChangeFreeProductByCategoryQuantity(e, index)}
                        variant="outlined"/>
                </Grid>
            </Grid>
        )
    });

    const onChangeFreeProductByCategoryQuantity = (e, index) => {
        selectedFreeCategory[index].coupon_quantity = Number(e.target.value);

        var _selectedFreeCategory = [...selectedFreeCategory]
        setSelectedFreeCategory(_selectedFreeCategory)
    }


    return (
        <Grid style={{ maxWidth: 625  }}>
            <Dialog 
                agreeTxt={"Yes"}
                disagreeTxt={"No"}
                description={dialogDes}
                title={dialogTitle}
                open={openDialog} 
                agreeButton={agreeButton}
                fullScreenDialog={fullScreenDialog}
                option={option}
                dialogLoading={dialogLoading}
                component={renderComponent()}
                handleClose={handleClose}/>

            <Grid container style={{ paddingBottom: 10, display: "flex", flexDirection: "row" }}>
                {props[props.option === "edit" ? "editCells" : "addCells"] ? props[props.option === "edit" ? "editCells" : "addCells"].map((cell, index)=>{ 
                const labelCellId = `add-cell-${index}`;
                var sizeMd = 6;

                if(index === props.addCells.length - 1){
                    if((index+1) % 2 !== 0){
                    sizeMd = 12;
                    } 
                }

                if(cell.id === "type"){
                    return (<Grid key={labelCellId} xs={12} md={12} style={{  padding: 10, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}} item >
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Type</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={couponType}
                            label="Status"
                            onChange={handleChangeType}
                            >
                            <MenuItem value={"free_item_by_product"}>Free Item by product</MenuItem>
                            <MenuItem value={"free_item_by_category"}>Free Item by category</MenuItem>
                            </Select>
                        </FormControl>
                        {couponType === "free_item_by_product" ?
                        <Grid style={{ width: "100%", marginTop: 20  }}>
                            <Grid container >
                                <Grid item md={9}>
                                <Button onClick={addFreeProduct} color="primary" style={{ width: "100%" , height: "100%"}} 
                                startIcon={<AddCircleIcon/>} 
                                disableElevation  variant="contained">{"Free Product"}</Button>
                                </Grid>

                                <Grid item md={3} style={{ paddingLeft: 10 }} >
                                <Button style={{ width: "100%" , height: "100%" }} disabled={selectedFreeProduct.length > 0 ? false : true } 
                                onClick={onClickClearFreeProduct} variant="outlined" color="primary">
                                    Clear
                                </Button>
                                </Grid>
                            </Grid>
                            <Grid style={{ width: "100%", display: "flex", justifyContent: "center"}}>
                            <aside style={thumbsContainer}>
                                {thumbsFreeProduct}
                            </aside>
                            </Grid>
                        </Grid>
                        : null}

                        {couponType === "free_item_by_category" ?
                        <Grid style={{ width: "100%", marginTop: 20  }}>
                            <Grid container >
                                <Grid item md={9}>
                                <Button onClick={addFreeCategory} color="primary" style={{ width: "100%" , height: "100%"}} 
                                startIcon={<AddCircleIcon/>} 
                                disableElevation  variant="contained">{"Category"}</Button>
                                </Grid>

                                <Grid item md={3} style={{ paddingLeft: 10 }} >
                                <Button style={{ width: "100%" , height: "100%" }} disabled={selectedFreeCategory.length > 0 ? false : true } 
                                onClick={onClickClearFreeProductByCategory} variant="outlined" color="primary">
                                    Clear
                                </Button>
                                </Grid>
                            </Grid>
                            <Grid style={{ width: "100%", display: "flex", justifyContent: "center"}}>
                            <aside style={thumbsContainer}>
                                {thumbsFreeCategory}
                            </aside>
                            </Grid>
                        </Grid>
                        : null}

                        {couponType === "discount" ?
                        <Grid style={{ width: "100%", marginTop: 20  }}>
                            <TextField
                                label={"Discount"}
                                style={{ width: "100%"}}
                                type="number"
                                InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        RM
                                      </InputAdornment>
                                    ),
                                }}
                                value={discountPrice}
                                onChange={handleDiscountChange}
                                variant="outlined"/>
                        </Grid>
                        : null}
                    </Grid>)
                }


                if(cell.id === "expired"){
                    return(<Grid key={labelCellId} xs={12} md={12} style={{flexDirection: "column", padding: 10, display: "flex", justifyContent: "flex-start", alignItems: "center"}} item >
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <KeyboardDatePicker
                          autoOk={true}
                          showTodayButton={true}
                          label="Expired Date"
                          value={selectedDate}
                          format="DD-MM-YYYY"
                          onChange={onDateChange}
                          rifmFormatter={dateFormatter}
                        />
                      </MuiPickersUtilsProvider>
                      </Grid>)
                }

                if(cell.id === "products"){
                    return(<Grid key={labelCellId} xs={12} md={12} style={{flexDirection: "column", padding: 10, display: "flex", justifyContent: "flex-start", alignItems: "center"}} item >
                    <Grid container style={{ width: "100%" , height: "100%"}}>
                        
                        <Grid item md={9}>
                        <Button onClick={addProduct} color="primary" style={{ width: "100%" , height: "100%"}} 
                        startIcon={<AddCircleIcon/>} 
                        disableElevation  variant="contained">{"Product"}</Button>
                        </Grid>

                        <Grid item md={3} style={{ paddingLeft: 10 }} >
                        <Button style={{ width: "100%" , height: "100%" }} disabled={selectedProduct.length > 0 ? false : true } 
                        onClick={onClickClearProduct} variant="outlined" color="primary">
                            Clear
                        </Button>
                        </Grid>
                    
                    </Grid>

                        <Grid>
                        <aside style={thumbsContainer}>
                            {thumbsProduct}
                        </aside>
                        </Grid>
                        
                    </Grid>)
                }

                if(cell.id === "image"){
                    return(<Grid key={labelCellId} xs={12} md={12} style={{flexDirection: "column", padding: 10, display: "flex", justifyContent: "flex-start", alignItems: "center"}} item >
                    <Grid container style={{ width: "100%" , height: "100%"}}>
                        
                        <Grid item md={9}>
                        <Button onClick={addContent} color="primary" style={{ width: "100%" , height: "100%"}} 
                        startIcon={<AddCircleIcon/>} 
                        disableElevation  variant="contained">{"Featured Image"}</Button>
                        </Grid>

                        <Grid item md={3} style={{ paddingLeft: 10 }} >
                        <Button style={{ width: "100%" , height: "100%" }} disabled={selected.length > 0 ? false : true } onClick={onClickClear} variant="outlined" color="primary">
                            Clear
                        </Button>
                        </Grid>
                    
                    </Grid>

                        <Grid>
                        <aside style={thumbsContainer}>
                            {thumbs}
                        </aside>
                        </Grid>
                        
                    </Grid>)
                }

                if(cell.id === "gallery"){
                    return(<Grid key={labelCellId} xs={12} md={12} style={{flexDirection: "column", padding: 10, display: "flex", justifyContent: "flex-start", alignItems: "center"}} item >
                    <Grid container style={{ width: "100%" , height: "100%"}}>
                        
                        <Grid item md={9}>
                        <Button onClick={addGallery} color="primary" style={{ width: "100%" , height: "100%"}} 
                        startIcon={<AddCircleIcon/>} 
                        disableElevation  variant="contained">{"Gallery"}</Button>
                        </Grid>

                        <Grid item md={3} style={{ paddingLeft: 10 }} >
                        <Button style={{ width: "100%" , height: "100%" }} disabled={selectedGallery.length > 0 ? false : true } 
                        onClick={onClickClearGallery} variant="outlined" color="primary">
                            Clear
                        </Button>
                        </Grid>
                    
                    </Grid>

                        <Grid>
                        <aside style={thumbsContainer}>
                            {thumbsGallery}
                        </aside>
                        </Grid>
                        
                    </Grid>)
                }

                if(cell.id === "description"){
                    return (<Grid key={labelCellId} xs={12} md={12} style={{  padding: 10, display: "flex", justifyContent: "center", alignItems: "center"}} item >
                             <TextField
                                key={"textfield-input-"+index}
                                label={cell.label}
                                style={{ width: "100%"}}
                                multiline
                                rows={4}
                                value={values[cell.id] || ''}
                                onChange={handleChange(cell.id)}
                                variant="outlined"/></Grid>
                        )
                }

                if(cell.id === "price" || cell.id === "sale_price"){
                    return (<Grid key={labelCellId} xs={12} md={sizeMd} style={{  padding: 10, display: "flex", justifyContent: "center", alignItems: "center"}} item >
                            <TextField
                                key={"textfield-input-"+index}
                                label={cell.label}
                                style={{ width: "100%"}}
                                type="number"
                                InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        RM
                                      </InputAdornment>
                                    ),
                                }}
                                value={values[cell.id] || ''}
                                onChange={handleChange(cell.id)}
                                variant="outlined"/>
                            </Grid>)
                }

                if(cell.id === "min_point" || cell.id === "min_orders"){
                    return (<Grid key={labelCellId} xs={12} md={sizeMd} style={{  padding: 10, display: "flex", justifyContent: "center", alignItems: "center"}} item >
                            <TextField
                                key={"textfield-input-"+index}
                                label={cell.label}
                                style={{ width: "100%"}}
                                type="number"
                                value={values[cell.id] || ''}
                                onChange={handleChange(cell.id)}
                                variant="outlined"/>
                            </Grid>)
                }

                if(cell.id === "status"){
                    return (<Grid key={labelCellId} xs={12} md={sizeMd} style={{  padding: 10, display: "flex", justifyContent: "center", alignItems: "center"}} item >
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Status</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={status}
                            label="Status"
                            onChange={handleChangeStatus}
                            >
                            <MenuItem value={"published"}>Published</MenuItem>
                            <MenuItem value={"draft"}>Draft</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>)
                }

                if(cell.id === "categories"){
                    return(<Grid key={labelCellId} xs={12} md={12} style={{flexDirection: "column", padding: 10, display: "flex", justifyContent: "flex-start", alignItems: "center"}} item >
                    <Grid container style={{ width: "100%" , height: "100%"}}>
                        
                        <Grid item md={9}>
                        <Button onClick={addCategories} color="primary" style={{ width: "100%" , height: "100%"}} 
                        startIcon={<AddCircleIcon/>} 
                        disableElevation  variant="contained">{"Categories"}</Button>
                        </Grid>

                        <Grid item md={3} style={{ paddingLeft: 10 }} >
                        <Button style={{ width: "100%" , height: "100%" }} disabled={selectedCategories.length > 0 ? false : true } 
                        onClick={onClickClearCatgories} variant="outlined" color="primary">
                            Clear
                        </Button>
                        </Grid>
                    
                    </Grid>

                        <Grid>
                        <aside style={thumbsContainer}>
                            {thumbsCategories}
                        </aside>
                        </Grid>
                        
                    </Grid>)
                }
                
                return(   
                    <Grid key={labelCellId} xs={12} md={sizeMd} style={{  padding: 10, display: "flex", justifyContent: "center", alignItems: "center"}} item >
                    <TextField
                        key={"textfield-input-"+index}
                        label={cell.label}
                        style={{ width: "100%"}}
                        value={values[cell.id] || ''}
                        onChange={handleChange(cell.id)}
                        variant="outlined"/></Grid>
                    )}):null }
            </Grid>
            
            <Grid style={{ padding: 10, marginBottom: 30, display: 'flex', justifyContent: "flex-end" }}>
                <Button variant="outlined" style={{ fontSize: 14, fontWeight: 'bold'}} onClick={props.handleClose} color="primary">
                {"No"}
                </Button>
                <Button disableElevation variant="contained" style={{ marginLeft: 10, fontSize: 14, fontWeight: 'bold'}} onClick={handleAgree} color="primary" autoFocus>
                {"Yes"}
                </Button>
            </Grid>
        </Grid>
    )

}
  