import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Dialog from '../../../modules/components/Dialog';
import Users from '../Users';
import Products from '../Products';
import Invoices from '../Invoices';
import Receipts from '../Receipts';
import DeliveryOrder from '../DeliveryOrder';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import OrdersDetails from './OrdersDetails';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import firebase from "firebase";



const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

export default function Index(props) {

    //Dialog
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogLoading, setDialogLoading] = React.useState(false);
    const [fullScreenDialog, setFullScreenDialog] = React.useState(false);
    const [dialogTitle, setDialogTitle] = React.useState("");
    const [dialogDes, setDialogDes] = React.useState("");
    const [agreeButton, setAgreeButton] = React.useState(false);
    const [option, setOptions] = React.useState("");

    const [selectedCustomer, setSelectedCustomer] = React.useState([]);
    const [selectedInvoice, setSelectedInvoice] = React.useState([]);
    const [selectedReceipt, setSelectedReceipt] = React.useState([]);
    const [selectedDeliveryOrder, setSelectedDeliveryOrder] = React.useState([]);
    const [selectedProduct, setSelectedProduct] = React.useState([]);
    const [mediaOption, setMediaOption] = React.useState("customer");
    const [values, setValues] = React.useState({});

    const [orderSerial, setOrderSerial] = React.useState(1);
    const [orderStatus, setOrderStatus] = React.useState([]);
    const [orderHandleBy, setOrderHandleBy] = React.useState("");
    const [selectedShipping, setSelectedShipping] = React.useState("");
    const [allShipping, setAllShipping] = React.useState([]);
    const [orderCourier, setOrderCourier] = React.useState("");
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        setValues(props.addCellsName ? props.addCellsName : {})
        
        if(props.option === "edit"){
            setValues(props.selectedItem ? props.selectedItem : {})
            setSelectedCustomer(props.selectedItem.customer ? props.selectedItem.customer : [])
            setSelectedProduct(props.selectedItem.products ? props.selectedItem.products : [])
            setOrderSerial(props.selectedItem.status_orders_serial ? props.selectedItem.status_orders_serial : 1)
            setOrderHandleBy(props.selectedItem.handle_by_orders ? props.selectedItem.handle_by_orders : "main_office");
            setOrderCourier(props.selectedItem.courier ? props.selectedItem.courier : "jnt");
            setSelectedInvoice(props.selectedItem.invoices ? props.selectedItem.invoices : [])
            setSelectedReceipt(props.selectedItem.receipts ? props.selectedItem.receipts : [])
            setSelectedDeliveryOrder(props.selectedItem.delivery_orders ? props.selectedItem.delivery_orders : [])
            setSelectedShipping(props.selectedItem.shipping ? props.selectedItem.shipping : "")
        }


        let itemRef = firebase.database().ref(props.data + "_status");

        itemRef.on('value', snapshot => {
            if(snapshot.exists()){

                let items = [];
                snapshot.forEach(child => {
                    var itemsVal = child.val();
                    items.push(itemsVal);
                })

                
                setOrderStatus(items)
            }

            let shippingRef = firebase.database().ref("shipping");

            shippingRef.on('value', snapshot_shipping => {
                if(snapshot.exists()){

                    let items_shipping = [];
                    snapshot_shipping.forEach(child => {
                        var itemsVal = child.val();
                        items_shipping.push(itemsVal);
                    })

                    setAllShipping(items_shipping)

                    setLoading(false)
                }
                else{
                    setLoading(false)
                }
            })
        })


    }, [props]);

    const handleClose = () => {
        setOpenDialog(false)
    };

    const handleAgree = () => {


        var updates = {};

        if(props.option === "edit"){
            values.id = props.selectedItem.id;
            values.customer = selectedCustomer;
            values.products = selectedProduct;
            values.status_orders_serial = orderSerial;
            values.handle_by_orders = orderHandleBy;
            values.courier = orderCourier;
            values.invoices = selectedInvoice;
            values.receipts = selectedReceipt;
            values.delivery_orders = selectedDeliveryOrder;
            values.read = true;
            
            

            let currentIndexShipping = allShipping.map(val => val.id).indexOf(selectedShipping)
            values.shipping_fee = currentIndexShipping !== -1 ? allShipping[currentIndexShipping].amount : null;
            values.shipping = selectedShipping;

            let currentIndexOrderStatus = orderStatus.map(val => val.serial).indexOf(orderSerial.toString())
            values.orders = currentIndexOrderStatus !== -1 ? orderStatus[currentIndexOrderStatus] : null;
            values.status_orders = orderStatus[currentIndexOrderStatus].name

            updates[props.data + '/' + props.selectedItem.id] = values;
    
            firebase.database().ref().update(updates).then(()=>{ 
                if(values.notification_id){
                    firebase.database().ref("notification/" + values.notification_id).update({ read: true });
                }
                
            });
        }
        else{
            var newPostKey = firebase.database().ref().child(props.data).push().key;

            values.id = newPostKey;
            values.user = firebase.auth().currentUser.email;
            values.products = selectedProduct;
            values.status_orders_serial = orderSerial;
            values.status_orders = orderStatus[orderSerial].name;
            values.handle_by_orders = orderHandleBy;
            values.courier = orderCourier;
            values.receipts = selectedReceipt;
            values.delivery_orders = selectedDeliveryOrder;
            values.read = false;

            let currentIndexOrderStatus = orderStatus.map(val => val.serial).indexOf(orderSerial.toString())
            values.orders = orderStatus[currentIndexOrderStatus];
            values.status_orders = orderStatus[currentIndexOrderStatus].name
    
            updates[props.data + '/' + newPostKey] = values;
    
            firebase.database().ref().update(updates).then(()=>{ 
                if(values.notification_id){
                    firebase.database().ref("notification/" + values.notification_id).update({ read: true });
                }
                
            });
        }
        
        
        

        props.handleClose()
    }

    const handleSetRows = (row) => {
        if(mediaOption === "customer"){
            setSelectedCustomer(row)
        }
        else if(mediaOption === "product"){
            setSelectedProduct(prev => [...prev,...row])
        }
        else if(mediaOption === "invoice"){
            setSelectedInvoice(row)
        }
        else if(mediaOption === "receipt"){
            setSelectedReceipt(row)
        }
        else if(mediaOption === "delivery_order"){
            setSelectedDeliveryOrder(row)
        }

        setOpenDialog(false)
    }

    const renderComponent = () => {
        if(mediaOption === "customer"){
            return(<Users mediaOption={mediaOption} handleSetRows={(rows) => handleSetRows(rows)}/>)
        }
        else if(mediaOption === "product"){
            return(<Products mediaOption={mediaOption} handleSetRows={(rows) => handleSetRows(rows)}/>)
        }
        else if(mediaOption === "invoice"){
            return(<Invoices mediaOption={mediaOption} handleSetRows={(rows) => handleSetRows(rows)}/>)
        }
        else if(mediaOption === "receipt"){
            return(<Receipts mediaOption={mediaOption} handleSetRows={(rows) => handleSetRows(rows)}/>)
        }
        else if(mediaOption === "delivery_order"){
            return(<DeliveryOrder mediaOption={mediaOption} handleSetRows={(rows) => handleSetRows(rows)}/>)
        }
    }

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleChangeStatusOrder = (event) => {
        setOrderSerial(event.target.value)
    }
    

    const handleChangeOrderhandleBy = (event) => {
        setOrderHandleBy(event.target.value)
    }

    const handleChangeShipping = (event) => {
        setSelectedShipping(event.target.value)
    }

    const handleChangeOrderCourier = (event) => {
        setOrderCourier(event.target.value)
    }

    const addCustomer = () => {
        setMediaOption("customer")
        setDialogLoading(false)
        setAgreeButton(false)
        setDialogTitle("Add Customer")
        setOptions("addItem")
        setDialogDes("")
        setFullScreenDialog(true)
        setOpenDialog(true)
    }

    const addInvoice = () => {
        setMediaOption("invoice")
        setDialogLoading(false)
        setAgreeButton(false)
        setDialogTitle("Add Invoice")
        setOptions("addItem")
        setDialogDes("")
        setFullScreenDialog(true)
        setOpenDialog(true)
    }

    const addReceipt = () => {
        setMediaOption("receipt")
        setDialogLoading(false)
        setAgreeButton(false)
        setDialogTitle("Add Receipt")
        setOptions("addItem")
        setDialogDes("")
        setFullScreenDialog(true)
        setOpenDialog(true)
    }

    const addDeliveryOrder = () => {
        setMediaOption("delivery_order")
        setDialogLoading(false)
        setAgreeButton(false)
        setDialogTitle("Add Delivery Order")
        setOptions("addItem")
        setDialogDes("")
        setFullScreenDialog(true)
        setOpenDialog(true)
    }

    const onClickClearCustomer = (event) => {
        setSelectedCustomer([])
    }

    const onClickClearInvoice = (event) => {
        setSelectedInvoice([])
    }

    const onClickClearReceipt = (event) => {
        setSelectedReceipt([])
    }

    const onClickClearDeliveryOrder = (event) => {
        setSelectedDeliveryOrder([])
    }

    const thumbsCustomer = selectedCustomer.map((file, index) => {

        return(
            <Grid key={file.name+index} style={{ display:"flex", flexDirection: "column"}}>
                <Grid style={{overflow: "hidden", padding: 8}}>
                    <Typography rows={2} style={{ color : "white"}} variant="caption" noWrap={true}>{file.last_name + " " + file.first_name}</Typography>
                </Grid>
            </Grid>
           
        )
    });

    const thumbsInvoice = selectedInvoice.map((file, index) => {

        return(
            <Grid key={file.name+index} style={{ display:"flex", flexDirection: "column"}}>
                <Grid style={{overflow: "hidden", padding: 8}}>
                    <Typography rows={2} style={{ color : "white"}} variant="caption" noWrap={true}>{file.name}</Typography>
                </Grid>
            </Grid>
           
        )
    });

    const thumbsReceipt = selectedReceipt.map((file, index) => {

        return(
            <Grid key={file.name+index} style={{ display:"flex", flexDirection: "column"}}>
                <Grid style={{overflow: "hidden", padding: 8}}>
                    <Typography rows={2} style={{ color : "white"}} variant="caption" noWrap={true}>{file.name}</Typography>
                </Grid>
            </Grid>
           
        )
    });

    const thumbsDeliveryOrder = selectedDeliveryOrder.map((file, index) => {

        return(
            <Grid key={file.name+index} style={{ display:"flex", flexDirection: "column"}}>
                <Grid style={{overflow: "hidden", padding: 8}}>
                    <Typography rows={2} style={{ color : "white"}} variant="caption" noWrap={true}>{file.name}</Typography>
                </Grid>
            </Grid>
           
        )
    });

    if(loading){
        return(
            <Grid style={{ padding: 40, display: "flex", justifyContent: "center", alignItems: "center" }}>
                <CircularProgress 
                disableShrink
                color="primary" />
            </Grid>
        )
    }

    return (
        <Grid style={{ padding: 30 , maxWidth: 1000 }}>
            <Dialog 
                agreeTxt={"Yes"}
                disagreeTxt={"No"}
                description={dialogDes}
                title={dialogTitle}
                open={openDialog} 
                agreeButton={agreeButton}
                fullScreenDialog={fullScreenDialog}
                option={option}
                dialogLoading={dialogLoading}
                component={renderComponent()}
                handleClose={handleClose}/>

            <Grid container >
                
                <Grid item md={12} xs={12}>
                    <Grid container style={{ minWidth: 500, paddingBottom: 10, display: "flex", flexDirection: "row" }}>
                        {props[props.option === "edit" ? "editCells" : "addCells"] ? props[props.option === "edit" ? "editCells" : "addCells"].map((cell, index)=>{ 
                        const labelCellId = `add-cell-${index}`;
                        var sizeMd = 6;

                        if(index === props.addCells.length - 1){
                            if((index+1) % 2 !== 0){
                            sizeMd = 12;
                            } 
                        }

                        if(cell.id === "customer"){
                            return(<Grid key={labelCellId} xs={12} md={12} style={{flexDirection: "column", padding: 10, display: "flex", justifyContent: "flex-start", alignItems: "center"}} item >
                            <Grid container style={{ width: "100%" , height: "100%"}}>
                                
                                <Grid item md={9}>
                                <Button onClick={addCustomer} color="primary" style={{ width: "100%" , height: "100%"}} 
                                startIcon={<AddCircleIcon/>} 
                                disableElevation  variant="contained">{"Customer"}</Button>
                                </Grid>

                                <Grid item md={3} style={{ paddingLeft: 10 }} >
                                <Button style={{ width: "100%" , height: "100%" }} disabled={selectedCustomer.length > 0 ? false : true } onClick={onClickClearCustomer} variant="outlined" color="primary">
                                    Clear
                                </Button>
                                </Grid>
                            
                            </Grid>

                                <Grid>
                                <aside style={thumbsContainer}>
                                    {thumbsCustomer}
                                </aside>
                                </Grid>
                                
                            </Grid>)
                        }

                        if(cell.id === "status_orders"){
                            return (<Grid key={labelCellId} xs={12} md={12} style={{  padding: 10, flexDirection: "column", display: "flex", justifyContent: "center", alignItems: "center"}} item >
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                    <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={orderStatus.length > 0 ? orderSerial : ""}
                                    label="Status"
                                    onChange={handleChangeStatusOrder}
                                    >
                                    {orderStatus.map((val, indexOrder)=>{
                                        return(
                                            <MenuItem key={'order_status_menu_item_' + indexOrder} value={parseInt(val.serial)}>{val.name}</MenuItem>
                                        )
                                    })}
                                    </Select>
                                </FormControl>
                                {orderSerial === 4 ? <FormControl style={{ marginTop : 10 }} fullWidth>
                                    <InputLabel id="demo-simple-select-label">Handle by</InputLabel>
                                    <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={orderHandleBy}
                                    label="Handle by"
                                    onChange={handleChangeOrderhandleBy}
                                    >
                                        <MenuItem value={"main_office"}>{"Main Office"}</MenuItem>
                                        <MenuItem value={"seri_petalling"}>{"Sri Petaling"}</MenuItem>
                                    </Select>
                                </FormControl> : null}

                                {orderSerial === 6 ? <Grid style={{ width: "100%" , flexDirection: "column", marginTop: 10, display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                                    <Grid container style={{ width: "100%" , height: "100%"}}>
                                    
                                        <Grid item md={9}>
                                        <Button onClick={addInvoice} color="primary" style={{ width: "100%" , height: "100%"}} 
                                        startIcon={<AddCircleIcon/>} 
                                        disableElevation  variant="contained">{"Invoice"}</Button>
                                        </Grid>

                                        <Grid item md={3} style={{ paddingLeft: 10 }} >
                                        <Button style={{ width: "100%" , height: "100%" }} disabled={selectedInvoice.length > 0 ? false : true } onClick={onClickClearInvoice} variant="outlined" color="primary">
                                            Clear
                                        </Button>
                                        </Grid>
                                    
                                        <FormControl style={{ marginTop : 10 }} fullWidth>
                                            <InputLabel id="demo-simple-select-label">Shipping</InputLabel>
                                            <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedShipping}
                                            label="Handle by"
                                            onChange={handleChangeShipping}
                                            >
                                                <MenuItem value={""}>{"None"}</MenuItem>
                                                {allShipping.map(val => {
                                                    return <MenuItem value={val.id}>{val.name + " (RM " + val.amount + ")"}</MenuItem>
                                                })}
    
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid>
                                    <aside style={thumbsContainer}>
                                        {thumbsInvoice}
                                    </aside>
                                    </Grid>
                                </Grid> : null}

                                {orderSerial === 7 ? <Grid style={{ width: "100%" , flexDirection: "column", marginTop: 10, display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                                    <Grid container style={{ width: "100%" , height: "100%"}}>
                                    
                                        <Grid item md={9}>
                                        <Button onClick={addReceipt} color="primary" style={{ width: "100%" , height: "100%"}} 
                                        startIcon={<AddCircleIcon/>} 
                                        disableElevation  variant="contained">{"Receipt"}</Button>
                                        </Grid>

                                        <Grid item md={3} style={{ paddingLeft: 10 }} >
                                        <Button style={{ width: "100%" , height: "100%" }} disabled={selectedReceipt.length > 0 ? false : true } onClick={onClickClearReceipt} variant="outlined" color="primary">
                                            Clear
                                        </Button>
                                        </Grid>
                                    
                                    </Grid>

                                    <Grid>
                                    <aside style={thumbsContainer}>
                                        {thumbsReceipt}
                                    </aside>
                                    </Grid>
                                </Grid> : null}

                                {orderSerial === 5 ? <Grid style={{ width: "100%" , flexDirection: "column", marginTop: 10, display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                                    <Grid container style={{ width: "100%" , height: "100%"}}>
                                    
                                        <Grid item md={9}>
                                        <Button onClick={addDeliveryOrder} color="primary" style={{ width: "100%" , height: "100%"}} 
                                        startIcon={<AddCircleIcon/>} 
                                        disableElevation  variant="contained">{"Delivery Order"}</Button>
                                        </Grid>

                                        <Grid item md={3} style={{ paddingLeft: 10 }} >
                                        <Button style={{ width: "100%" , height: "100%" }} disabled={selectedDeliveryOrder.length > 0 ? false : true } onClick={onClickClearDeliveryOrder} variant="outlined" color="primary">
                                            Clear
                                        </Button>
                                        </Grid>
                                    
                                    </Grid>

                                    <Grid>
                                    <aside style={thumbsContainer}>
                                        {thumbsDeliveryOrder}
                                    </aside>
                                    </Grid>
                                </Grid> : null}

                                {orderSerial === 9 ? <FormControl style={{ marginTop : 10 }} fullWidth>
                                    <InputLabel id="demo-simple-select-label">Courier</InputLabel>
                                    <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={orderCourier}
                                    label="Courier"
                                    onChange={handleChangeOrderCourier}
                                    >
                                        <MenuItem value={"jnt"}>{"JNT"}</MenuItem>
                                        <MenuItem value={"lalamove"}>{"Lalamove"}</MenuItem>
                                    </Select>
                                </FormControl> : null}
                            </Grid>)
                        }

                        if(cell.id === "paid_total"){
                            return(
                                <Grid key={labelCellId} xs={12} md={sizeMd} style={{  padding: 10, display: "flex", justifyContent: "center", alignItems: "center"}} item >
                                <TextField
                                key={"textfield-input-"+index}
                                label={cell.label}
                                style={{ width: "100%"}}
                                type="number"
                                InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        RM
                                      </InputAdornment>
                                    ),
                                }}
                                value={values[cell.id] || ''}
                                onChange={handleChange(cell.id)}
                                variant="outlined"/></Grid>
                            )
                        }
                        
                        return(   
                            <Grid key={labelCellId} xs={12} md={sizeMd} style={{  padding: 10, display: "flex", justifyContent: "center", alignItems: "center"}} item >
                                <TextField
                                    key={"textfield-input-"+index}
                                    label={cell.label}
                                    style={{ width: "100%"}}
                                    value={values[cell.id] || ''}
                                    onChange={handleChange(cell.id)}
                                    variant="outlined"/></Grid>
                                )}):null }
                                
                        </Grid>
                    <Grid style={{ padding: 10, marginBottom: 30, display: 'flex', justifyContent: "flex-end" }}>
                        <Button variant="outlined" style={{ fontSize: 14, fontWeight: 'bold'}} onClick={props.handleClose} color="primary">
                        {"No"}
                        </Button>
                        <Button disableElevation variant="contained" style={{ marginLeft: 10, fontSize: 14, fontWeight: 'bold'}} onClick={handleAgree} color="primary" autoFocus>
                        {"Yes"}
                        </Button>
                    </Grid>
                </Grid>

                <Grid item md={12} xs={12}>
                    <OrdersDetails data={props.selectedItem} />
                </Grid>
            </Grid>
            
            
        </Grid>
    )

}
  