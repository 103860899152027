import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Dialog from '../../../modules/components/Dialog';
import Media from '../Media';
import Categories from '../Categories';
import Typography from '@material-ui/core/Typography';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import PhoneIcon from '@material-ui/icons/Phone';
import firebase from "firebase";

import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';


const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #6e6e6e',
    width: 70,
    height: 70,
    padding: 4,
    boxSizing: 'border-box',
    marginLeft: 8,
    marginRight: 8,
    marginBottom: 8 
};

 
const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};

export default function Index(props) {

    //Dialog
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogLoading, setDialogLoading] = React.useState(false);
    const [fullScreenDialog, setFullScreenDialog] = React.useState(false);
    const [dialogTitle, setDialogTitle] = React.useState("");
    const [dialogDes, setDialogDes] = React.useState("");
    const [agreeButton, setAgreeButton] = React.useState(false);
    const [option, setOptions] = React.useState("");

    const [selectedGallery, setSelectedGallery] = React.useState([]);
    const [selectedCategories, setSelectedCategories] = React.useState([]);
    const [status, setStatus] = React.useState("active");
    const [role, setRole] = React.useState("cashier");
    const [mediaOption, setMediaOption] = React.useState("image");
    const [values, setValues] = React.useState({});
    
    const [isCustomer, setIsCustomer] = React.useState(false);
    const [isWarranty, setIsWarranty] = React.useState(false);

    const [selectedClient, setSelectedClient] = React.useState(null);

    const [selectedCamera, setSelectedCamera] = React.useState("environment");


    useEffect(() => {
        setValues(props.addCellsName ? props.addCellsName : {})
        
        if(props.option === "edit"){
            setValues(props.selectedItem ? props.selectedItem : {})
        }


    }, [props]);

    const handleClose = () => {
        setOpenDialog(false)
    };

    const validateEmail = (email) => {
        const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regexp.test(email);
    }

    const handleAgree = () => {

        if(props.option === "edit"){
            var updates = {};
            values.id = props.selectedItem.id;
            
            if(values.claimed){
                values.claimed = null;
                values.claimed_date = null;
            }
            else
            {
                values.claimed = true;
                values.claimed_date =  firebase.database.ServerValue.TIMESTAMP;
            }

            updates[props.data + '/' + props.selectedItem.id] = values;
    
            firebase.database().ref().update(updates);

            props.handleClose()
        }
        else
        {
            var updates = {};
            var warrantyActive = false;
            var customerActive = false;
    
            if(selectedClient){
                customerActive = true;
                setIsCustomer(false)
            }
            else
            {
                customerActive = false;
                setIsCustomer(true)
            }
    
            if(values.warranty_id && values.warranty_id.length > 8){
                warrantyActive = true;
                firebase.database().ref("warranty").once('value', snapshot => {
                    if(snapshot.exists()){
        
                        var warrantyRegistered = false;
    
                        snapshot.forEach(child => {
                            var itemsVal = child.val();
                
                            if(itemsVal.id === values.warranty_id)
                            {
                                warrantyRegistered =true;
                            }
                
                        })
        
                        if(!warrantyRegistered){
                            warrantyActive = true;
                            setIsWarranty(false)
                        }
                        else
                        {
                            warrantyActive = false;
                            setIsWarranty(true)
                        }
        
                        if(warrantyActive && customerActive){
                            if(props.option === "edit"){
                                values.id = props.selectedItem.id;
                                
                                if(values.claimed){
                                    values.claimed = null;
                                    values.claimed_date = null;
                                }
                                else
                                {
                                    values.claimed = true;
                                    values.claimed_date =  firebase.database.ServerValue.TIMESTAMP;
                                }
                    
                                updates[props.data + '/' + props.selectedItem.id] = values;
                        
                                firebase.database().ref().update(updates);
                            }
                            else{
                
                                values.id = values.warranty_id;
                                values.customer_id = selectedClient[0].customer_id;
                                values.phone = selectedClient[0].phone;
                                values.store_id = firebase.auth().currentUser.displayName;
                        
                                updates[props.data + '/' + values.warranty_id] = values;
                        
                                firebase.database().ref().update(updates);
                            }
                
                            props.handleClose()
                        }
                    }
                    else
                    {
    
                        var warrantyRegistered = false;
        
                        if(!warrantyRegistered){
                            warrantyActive = true;
                            setIsWarranty(false)
                        }
                        else
                        {
                            warrantyActive = false;
                            setIsWarranty(true)
                        }
                        
                        if(warrantyActive && customerActive){
                            if(props.option === "edit"){
                                values.id = props.selectedItem.id;
                                
                                if(values.claimed){
                                    values.claimed = null;
                                    values.claimed_date = null;
                                }
                                else
                                {
                                    values.claimed = true;
                                    values.claimed_date =  firebase.database.ServerValue.TIMESTAMP;
                                }
                    
                                updates[props.data + '/' + props.selectedItem.id] = values;
                        
                                firebase.database().ref().update(updates);
                            }
                            else{
                
                                values.id = values.warranty_id;
                                values.customer_id = selectedClient[0].customer_id;
                                values.phone = selectedClient[0].phone;
                                values.store_id = firebase.auth().currentUser.displayName;
                        
                                updates[props.data + '/' + values.warranty_id] = values;
                        
                                firebase.database().ref().update(updates);
                            }
                
                            props.handleClose()
                        }
                    }
                })
            }
            else
            {
                warrantyActive = false;
                setIsWarranty(true)
            }
        }

        
        
    }

    const handleSetRows = (row) => {
        if(mediaOption === "gallery"){
            setSelectedGallery(prev => [...prev,...row])
        }
        else if(mediaOption === "categories")
        {
            setSelectedCategories(row)
        }
        else if(mediaOption === "customer"){
            setSelectedClient(row)
        }
       
        setOpenDialog(false)
    }

    const handleScan = (data) => {
        if(data){
            if(data.startsWith("http://sbr.fangcuanhuoxitong.com/Home/Index/"))
            {
                const resultArray = data.split("/");
                setValues({ ...values, ["warranty_id"]: resultArray[5] });
                setOpenDialog(false)
            }
        }
    }

    const handleError = (err) =>{
        console.error(err)
    }

    const renderComponent = () => {
        if(mediaOption === "categories"){
            return(<Categories mediaOption={mediaOption} handleSetRows={(rows) => handleSetRows(rows)}/>)
        }
        
        else{
            return(<Media mediaOption={mediaOption} handleSetRows={(rows) => handleSetRows(rows)}/>)
        }
        
    }

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleChangeStatus = (event) => {
        setStatus(event.target.value)
    }

    const addGallery = () => {
        setMediaOption("gallery")
        setDialogLoading(false)
        setAgreeButton(false)
        setDialogTitle("Add Gallery")
        setOptions("addItem")
        setDialogDes("")
        setFullScreenDialog(true)
        setOpenDialog(true)
    }

    const addCategories = () => {
        setMediaOption("categories")
        setDialogLoading(false)
        setAgreeButton(false)
        setDialogTitle("Add Categories")
        setOptions("addItem")
        setDialogDes("")
        setFullScreenDialog(true)
        setOpenDialog(true)
    }

    const addCustomers = () => {
        setMediaOption("customer")
        setDialogLoading(false)
        setAgreeButton(false)
        setDialogTitle("Customer")
        setOptions("addItem")
        setDialogDes("")
        setFullScreenDialog(false)
        setOpenDialog(true)
    }

    const scanQR = () => {
        setMediaOption("scanqr")
        setDialogLoading(false)
        setAgreeButton(false)
        setDialogTitle("Scan QR (Warranty)")
        setOptions("addItem")
        setDialogDes("")
        setFullScreenDialog(false)
        setOpenDialog(true)
    }

    const onClickClearCustomers = (event) => {
        setSelectedClient(null)
    }

    const onClickClearGallery = () => {
        setSelectedGallery([])
    }

    const onClickClearCatgories = (event) => {
        setSelectedCategories([])
    }

    const handleChangeRole = (event) => {
        setRole(event.target.value)
    }

    const handleChangeCamera = (e) => {
        setSelectedCamera(e.target.value)
    }

    const thumbsGallery = selectedGallery.map((file, index) => {

        if(file.type === "video/mp4"){
            
            return(
                <Grid key={file.name+index} style={{ display:"flex", flexDirection: "column"}}>
                    <Grid style={{overflow: "hidden", textOverflow: "ellipsis", width: 70, padding: 8}}>
                        <Typography style={{ color : "#6e6e6e"}} variant="caption" noWrap={true}>{file.name}</Typography>
                    </Grid>
                    
                    <Grid style={thumb} key={file.name}>
                        <Grid style={{ display: "flex", justifyContent:'center', alignItems: 'center', width: "100%"}}>
                            <VideoLibraryIcon color="primary" style={{ fontSize: 36}}/>
                        </Grid>
                    </Grid>
                </Grid>
               
            )
        }
        else{
            return(
                <Grid key={file.name+index} style={{ display:"flex", flexDirection: "column"}}>
                    <Grid style={{overflow: "hidden", textOverflow: "ellipsis", width: 70,  padding: 8}}>
                        <Typography style={{ color : "#6e6e6e"}} variant="caption" noWrap={true}>{file.name}</Typography>
                    </Grid>
                    
                    <Grid style={thumb} key={file.name}>
                        <Grid style={{ display: "flex", justifyContent:'center', alignItems: 'center', width: "100%", overflow: 'hidden'}}>
                            <img
                            src={file.url}
                            style={img}
                            alt={file.name}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )
        }
    });

    const thumbsCategories = selectedCategories.map((file, index) => {

        return(
            <Grid key={file.name+index} style={{ display:"flex", flexDirection: "column"}}>
                <Grid style={{overflow: "hidden", padding: 8}}>
                    <Typography multiline
                                rows={2} style={{ color : "white"}} variant="caption" noWrap={true}>{file.name}</Typography>
                </Grid>
            </Grid>
           
        )
    });


    return (
        <Grid style={{ maxWidth: 625  }}>
            <Dialog 
                agreeTxt={"Yes"}
                disagreeTxt={"No"}
                description={dialogDes}
                title={dialogTitle}
                open={openDialog} 
                agreeButton={agreeButton}
                fullScreenDialog={fullScreenDialog}
                option={option}
                dialogLoading={dialogLoading}
                component={renderComponent()}
                handleClose={handleClose}/>

            <Grid container style={{ paddingBottom: 10, display: "flex", flexDirection: "row" }}>
                {props[props.option === "edit" ? "editCells" : "addCells"] ? props[props.option === "edit" ? "editCells" : "addCells"].map((cell, index)=>{ 
                const labelCellId = `add-cell-${index}`;
                var sizeMd = 6;

                if(index === props.addCells.length - 1){
                    if((index+1) % 2 !== 0){
                    sizeMd = 12;
                    } 
                }

                if(cell.id === "address"){
                    return(<Grid key={labelCellId} xs={12} md={12} style={{flexDirection: "column", padding: 10, display: "flex", justifyContent: "flex-start", alignItems: "center"}} item >
                    
                    </Grid>)
                }


                


                if(cell.id === "gallery"){
                    return(<Grid key={labelCellId} xs={12} md={12} style={{flexDirection: "column", padding: 10, display: "flex", justifyContent: "flex-start", alignItems: "center"}} item >
                    <Grid container style={{ width: "100%" , height: "100%"}}>
                        
                        <Grid item md={9}>
                        <Button onClick={addGallery} color="primary" style={{ width: "100%" , height: "100%"}} 
                        startIcon={<AddCircleIcon/>} 
                        disableElevation  variant="contained">{"Gallery"}</Button>
                        </Grid>

                        <Grid item md={3} style={{ paddingLeft: 10 }} >
                        <Button style={{ width: "100%" , height: "100%" }} disabled={selectedGallery.length > 0 ? false : true } 
                        onClick={onClickClearGallery} variant="outlined" color="primary">
                            Clear
                        </Button>
                        </Grid>
                    
                    </Grid>

                        <Grid>
                        <aside style={thumbsContainer}>
                            {thumbsGallery}
                        </aside>
                        </Grid>
                        
                    </Grid>)
                }

                if(cell.id === "price" || cell.id === "sale_price"){
                    return (<Grid key={labelCellId} xs={12} md={sizeMd} style={{  padding: 10, display: "flex", justifyContent: "center", alignItems: "center"}} item >
                            <TextField
                                key={"textfield-input-"+index}
                                label={cell.label}
                                style={{ width: "100%"}}
                                type="number"
                                InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        RM
                                      </InputAdornment>
                                    ),
                                }}
                                value={values[cell.id] || ''}
                                onChange={handleChange(cell.id)}
                                variant="outlined"/>
                            </Grid>)
                }

                if(cell.id === "point_users" || cell.id === "user_redeem_time"){
                    return (<Grid key={labelCellId} xs={12} md={sizeMd} style={{  padding: 10, display: "flex", justifyContent: "center", alignItems: "center"}} item >
                            <TextField
                                key={"textfield-input-"+index}
                                label={cell.label}
                                style={{ width: "100%"}}
                                type="number"
                                value={values[cell.id] || 0}
                                onChange={handleChange(cell.id)}
                                variant="outlined"/>
                            </Grid>)
                }

                if(cell.id === "status_users"){
                    return (values["role"] !== "admin" ? <Grid key={labelCellId} xs={12} md={sizeMd} style={{  padding: 10, display: "flex", justifyContent: "center", alignItems: "center"}} item >
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Status</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={status}
                            label="Status"
                            onChange={handleChangeStatus}
                            >
                            <MenuItem value={"active"}>Active</MenuItem>
                            <MenuItem value={"inactive"}>Inactive</MenuItem>
                            <MenuItem value={"new_user"}>New User</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>: null)
                }

                if(cell.id === "role"){
                    return (values[cell.id] !== "admin" ? <Grid key={labelCellId} xs={12} md={sizeMd} style={{  padding: 10, display: "flex", justifyContent: "center", alignItems: "center"}} item >
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Role</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={role}
                            required
                            label="Status"
                            onChange={handleChangeRole}
                            >
                            <MenuItem value={"manager"}>Manager</MenuItem>
                            <MenuItem value={"cashier"}>Cashier</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid> :null)
                }

                if(cell.id === "phone"){
                    return(<Grid key={labelCellId} xs={12} md={sizeMd} style={{flexDirection: "column", padding: 10, display: "flex", justifyContent: "flex-start", alignItems: "center"}} item >
                        <TextField
                                key={"textfield-input-"+index}
                                label={cell.label}
                                style={{ width: "100%"}}
                                type="number"
                                InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <PhoneIcon/>
                                      </InputAdornment>
                                    ),
                                }}
                                value={values[cell.id] || ''}
                                onChange={handleChange(cell.id)}
                                variant="outlined"/>
                        
                    </Grid>)
                }


                if(cell.id === "customer"){
                    return(<Grid key={labelCellId} xs={12} md={12} style={{  padding: 10, display: "flex", justifyContent: "center", alignItems: "center"}} item >
                    <Grid container style={{ width: "100%" , height: "100%" }}>
                          
                          <Grid item md={9}>
                          <Button onClick={addCustomers} color="primary" style={{ width: "100%" , height: "100%", fontSize: 18 }} 
                          disableElevation variant="contained">{"Customer"}</Button>
                          </Grid>
          
                          <Grid item md={3} style={{ paddingLeft: 10 }} >
                          <Button style={{ width: "100%" , height: "100%", fontSize: 18 }} disabled={selectedClient ? false : true } 
                          onClick={onClickClearCustomers} variant="outlined" color="primary">
                              Clear
                          </Button>
                          </Grid>

                          <Grid item md={12} style={{ marginTop: 20 }}>
                            {selectedClient ? <Grid item xs={12} style={{ marginBottom: 10, border: '1px solid #505050', borderRadius: 5, padding: 10  }}> 
                            <Typography variant="body2" color="textSecondary" component="p" align="left" style={{ fontSize: 18 }}>
                                {selectedClient[0].first_name + " " + selectedClient[0].last_name}
                            </Typography> 
                            </Grid>: null}

                            {isCustomer ? <Grid item xs={12} > 
                            <Typography variant="body2" color="secondary" component="p" align="left" style={{ fontSize: 12 }}>
                                {"Please select at least a customer"}
                            </Typography> 
                            </Grid>: null}
                          </Grid>
                          
                        </Grid>

                       
                        
                        </Grid>)
                }
                if(cell.id === "warranty_id"){
                    return(<Grid key={labelCellId} xs={12} md={12} style={{ flexDirection: "column", padding: 10, display: "flex", justifyContent: "center", alignItems: "center"}} item >
                    <Grid style={{ display: "flex", flexDirection: "row", width: "100%" }} >
                        <Grid  style={{ padding: 10 }}>
                            

                            <IconButton onClick={scanQR} color="primary" aria-label="scan qr" component="span">
                                <PhotoCamera />
                            </IconButton>
                        </Grid>
                        
                            <TextField
                            error={isWarranty}
                                key={"textfield-input-"+index}
                                label={cell.label}
                                type="number"
                                style={{ width: "100%"}}
                                value={values[cell.id] || ''}
                                onChange={handleChange(cell.id)}
                                variant="outlined"/>
                        </Grid>
                        {isWarranty ? <Grid item xs={12} style={{ marginTop: 10}} > 
                            <Typography variant="body2" color="secondary" component="p" align="left" style={{ fontSize: 12 }}>
                                {"Warranty ID Invalid or Registered"}
                            </Typography> 
                            </Grid>: null}
                        </Grid>)
                }

                if(cell.id === "claimed"){
                    return(   
                        <Grid key={labelCellId} xs={12} md={12} style={{ marginTop: 20, padding: 10, display: "flex", justifyContent: "center", alignItems: "center"}} item >
                        <Typography variant="body2" component="p" align="left" style={{ fontSize: 18 }}>
                                {values[cell.id] ? "Are you sure want to revoke claim now?": "Are you sure want to Claim now?"}
                            </Typography> 
                        </Grid>
                        )
                }

                if(cell.id === "claimed_date"){
                    return(   
                        <Grid key={labelCellId} xs={12} md={12} style={{  padding: 10, display: "flex", justifyContent: "center", alignItems: "center"}} item >
 
                        </Grid>
                        )
                }
                
                return(   
                    <Grid key={labelCellId} xs={12} md={sizeMd} style={{  padding: 10, display: "flex", justifyContent: "center", alignItems: "center"}} item >
                    <TextField
                        key={"textfield-input-"+index}
                        label={cell.label}
                        style={{ width: "100%"}}
                        value={values[cell.id] || ''}
                        onChange={handleChange(cell.id)}
                        variant="outlined"/></Grid>
                    )}):null }
            </Grid>
            
            <Grid style={{ padding: 10, marginBottom: 30, display: 'flex', justifyContent: "flex-end" }}>
                <Button variant="outlined" style={{ fontSize: 14, fontWeight: 'bold'}} onClick={props.handleClose} color="primary">
                {"No"}
                </Button>
                <Button disableElevation variant="contained" style={{ marginLeft: 10, fontSize: 14, fontWeight: 'bold'}} onClick={handleAgree} color="primary" autoFocus>
                {props.option === "edit" ? "Yes" : "Register"}
                </Button>
            </Grid>
        </Grid>
    )

}
  