import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ReactPlayer from 'react-player'
import Carousel from 'react-material-ui-carousel'
import Link from '@material-ui/core/Link';

import Contents from '../../views/pages/Content/Contents'
import Products from '../../views/pages/Products/Products';
import Orders from '../../views/pages/Orders/Orders';
import Users from '../../views/pages/Users/Users';
import Salesman from '../../views/pages/Salesman/Salesman';
import Invoices from '../../views/pages/Invoices/Invoices';

import Orders_store from './Orders'
import Products_store from './Products'
import DailyCheckOut from './DailyCheckOut'
import Receipt from './Receipt'
import moment from "moment";

const bytesToSize = (bytes) => {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
};

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    display:"flex",
    width:"100%",
    justifyContent: "space-between",
  },
  closeButton: {
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, data, ...other } = props;
  return (
    <React.Fragment>
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography style={{ color: "black", textAlign: "center", marginTop: 5}} variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon  style={{ color: "black  "}}/>
          </IconButton>
        ) : null}
      </MuiDialogTitle>
      <Divider style={{ opacity: .2 }}/>
    </React.Fragment>
  );
});

export default function AlertDialog(props) {

  const renderPage = () => {
    if(props.data === "content_zone"){
      return <Grid><Contents mediaOption={props.mediaOption} handleSetRows={props.handleSetRows} folder={props.selectedItem ? props.selectedItem.id : null} /></Grid>
    }
    else if(props.data === "products_zone"){
      return <Grid><Products mediaOption={props.mediaOption} handleSetRows={props.handleSetRows} folder={props.selectedItem ? props.selectedItem.id : null} /></Grid>
    }
    else if(props.data === "orders_zone"){
      return <Grid><Orders mediaOption={props.mediaOption} handleSetRows={props.handleSetRows} folder={props.selectedItem ? props.selectedItem.id : null} /></Grid>
    }
    else if(props.data === "users_zone"){
      return <Grid><Users mediaOption={props.mediaOption} handleSetRows={props.handleSetRows} folder={props.selectedItem ? props.selectedItem.id : null} /></Grid>
    }
    else if(props.data === "salesman_zone"){
      return <Grid><Salesman mediaOption={props.mediaOption} handleSetRows={props.handleSetRows} folder={props.selectedItem ? props.selectedItem.id : null} /></Grid>
    }
    else if(props.data === "invoices_zone"){
      return <Grid><Invoices mediaOption={props.mediaOption} handleSetRows={props.handleSetRows} folder={props.selectedItem ? props.selectedItem.id : null} /></Grid>
    }
  }
  
  return (
    <div >
      <Dialog
        fullScreen={props.fullScreenDialog}
        open={props.open}
        fullWidth={true}
        maxWidth={props.maxWidth ? props.maxWidth : "xs"}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            backgroundColor: '#242424',
            boxShadow: 'none',
          },
        }}
      >

        <DialogTitle style={{ minWidth: 200 }} id="customized-dialog-title" onClose={props.handleClose} data={props.data}>
          {props.title}
        </DialogTitle>

        {props.description !== "" ?
          <Typography style={{ padding: 18 }} id="alert-dialog-description">
            {props.description}
          </Typography>
        :null}
        {props.option=== "info" ? 
        <Table>
            <TableBody>
              {props.displayInfo.map((cell, index)=>{
                const labelCellId = `enhanced-table-cell-${index}`;
                
                if(cell.id === "created" || cell.id === "updated" || cell.id === "claimed_date"){
                  return(
                    <TableRow key={labelCellId} >
                      <TableCell component="th" scope="row">
                        {cell.label}
                      </TableCell>
                      {props.selectedItem[cell.id] ? <TableCell component="th" scope="row">
                      {new Intl.DateTimeFormat('en-GB', {year: 'numeric',day: '2-digit',  month: 'long' ,hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true }).format(props.selectedItem[cell.id])}
                      </TableCell> : <TableCell component="th" scope="row"></TableCell>}
                    </TableRow>)
                }
                else if(cell.id === "claimed"){
                  return(
                    <TableRow key={labelCellId} >
                      <TableCell component="th" scope="row">
                        {cell.label}
                      </TableCell>
                      <TableCell key={labelCellId}  component="th" scope="row">
                        <Typography style={{ wordWrap: "break-word" }} variant="body2">{props.selectedItem[cell.id] ? "Yes": "No"}</Typography>
                      </TableCell>
                    </TableRow>
                    )
                }
                else if(cell.id === "expired_date"){
                  return(
                  <TableRow key={labelCellId} >
                    <TableCell component="th" scope="row">
                      {cell.label}
                    </TableCell>
                    {props.selectedItem["created"] ? <TableCell component="th" scope="row">
                    {moment(props.selectedItem["created"]).add(365, "days").format("DD-MM-YYYY")}
                    </TableCell> : <TableCell component="th" scope="row"></TableCell>}
                  </TableRow>)
              }
                else if(cell.id === "size"){
                  return(
                    <TableRow key={labelCellId} >
                      <TableCell component="th" scope="row">
                        {cell.label}
                      </TableCell>
                      <TableCell key={labelCellId}  component="th" scope="row">
                      {bytesToSize(props.selectedItem[cell.id])}
                      </TableCell>
                    </TableRow>
                    )
                }

                else if(cell.id === "shipping_address"){
                  return(
                    <TableRow key={labelCellId} >
                      <TableCell component="th" scope="row">
                        {cell.label}
                      </TableCell>
                      <TableCell key={labelCellId}  component="th" scope="row">
                        <Typography style={{ wordWrap: "break-word" }} variant="body2">
                          {props.selectedItem[cell.id] ? props.selectedItem[cell.id].street_address + ", " + 
                  props.selectedItem[cell.id].city + ", " + 
                  props.selectedItem[cell.id].zip + " " + 
                  props.selectedItem[cell.id].state + ", " + 
                  props.selectedItem[cell.id].country : ""}</Typography>
                      </TableCell>
                    </TableRow>
                    )
                }

                else if(cell.id === "billing_address"){
                  return(
                    <TableRow key={labelCellId} >
                      <TableCell component="th" scope="row">
                        {cell.label}
                      </TableCell>
                      <TableCell key={labelCellId}  component="th" scope="row">
                        <Typography style={{ wordWrap: "break-word" }} variant="body2">
                          {props.selectedItem[cell.id] ? props.selectedItem[cell.id].street_address + ", " + 
                  props.selectedItem[cell.id].city + ", " + 
                  props.selectedItem[cell.id].zip + " " + 
                  props.selectedItem[cell.id].state + ", " + 
                  props.selectedItem[cell.id].country : ""}</Typography>
                      </TableCell>
                    </TableRow>
                    )
                }

                else if(cell.id === "amount"){
                  return(
                    <TableRow key={labelCellId} >
                      <TableCell component="th" scope="row">
                        {cell.label}
                      </TableCell>
                      <TableCell key={labelCellId}  component="th" scope="row">
                        <Typography style={{ wordWrap: "break-word" }} variant="body2">{"RM " + props.selectedItem[cell.id]}</Typography>
                      </TableCell>
                    </TableRow>
                    )
                }

                else if(cell.id === "user_id"){
                  return(
                    <TableRow key={labelCellId} >
                      <TableCell component="th" scope="row">
                        {cell.label}
                      </TableCell>
                      <TableCell key={labelCellId}  component="th" scope="row">
                        <Typography style={{ wordWrap: "break-word" }} variant="body2">{props.selectedItem["user"].id}</Typography>
                      </TableCell>
                    </TableRow>
                    )
                }

                else if(cell.id === "user_name"){
                  return(
                    <TableRow key={labelCellId} >
                      <TableCell component="th" scope="row">
                        {cell.label}
                      </TableCell>
                      <TableCell key={labelCellId}  component="th" scope="row">
                        <Typography style={{ wordWrap: "break-word" }} variant="body2">{props.selectedItem["user"].last_name + " " + props.selectedItem["user"].first_name}</Typography>
                      </TableCell>
                    </TableRow>
                    )
                }

                else if(cell.id === "user_phone"){
                  return(
                    <TableRow key={labelCellId} >
                      <TableCell component="th" scope="row">
                        {cell.label}
                      </TableCell>
                      <TableCell key={labelCellId}  component="th" scope="row">
                        <Typography style={{ wordWrap: "break-word" }} variant="body2">{props.selectedItem["user"].phone}</Typography>
                      </TableCell>
                    </TableRow>
                    )
                }
                else if(cell.id === "redeem"){
                  return(
                    <TableRow key={labelCellId} >
                      <TableCell component="th" scope="row">
                        {cell.label}
                      </TableCell>
                      <TableCell key={labelCellId}  component="th" scope="row">
                        <Typography style={{ wordWrap: "break-word" }} variant="body2">{props.selectedItem[cell.id] ? "Enabled": "Disabled"}</Typography>
                      </TableCell>
                    </TableRow>
                    )
                }
                else if(cell.id === "document"){

                  let document = [];

                  if(props.selectedItem[cell.id]){
                    props.selectedItem[cell.id].forEach(val =>{
                      document.push(<Grid><Link target="_blank" href={val.url} >
                      {val.name}
                    </Link></Grid>)
                    })
                  }
                  
                  
                  return(
                    <TableRow key={labelCellId} >
                      <TableCell component="th" scope="row">
                        {cell.label}
                      </TableCell>
                      <TableCell key={labelCellId}  component="th" scope="row">
                      {document}
                      </TableCell>
                    </TableRow>
                    )
                }
                else if(cell.id === "orders_customer"){
                  return(
                    <TableRow key={labelCellId} >
                      <TableCell component="th" scope="row">
                        {cell.label}
                      </TableCell>
                      <TableCell key={labelCellId}  component="th" scope="row">
                        <Typography style={{ wordWrap: "break-word" }} variant="body2">{props.selectedItem[cell.id] ? props.selectedItem[cell.id][0].last_name + " " + props.selectedItem[cell.id][0].first_name : "Walk In"}</Typography>
                      </TableCell>
                    </TableRow>
                    )
                }
                else if(cell.id === "cashier"){
                  return(
                    <TableRow key={labelCellId} >
                      <TableCell component="th" scope="row">
                        {cell.label}
                      </TableCell>
                      <TableCell key={labelCellId}  component="th" scope="row">
                        <Typography style={{ wordWrap: "break-word" }} variant="body2">{props.selectedItem[cell.id] ? props.selectedItem[cell.id].id: ""}</Typography>
                      </TableCell>
                    </TableRow>
                    )
                }
                else if(cell.id === "package_categories" || cell.id === "foc_categories"){
                  return(
                    <TableRow key={labelCellId} >
                      <TableCell component="th" scope="row">
                        {cell.label}
                      </TableCell>
                      <TableCell key={labelCellId}  component="th" scope="row">
                      {props.selectedItem[cell.id] ? props.selectedItem[cell.id].map(val =>{
                                        return <Typography variant="body2">{val.name}</Typography>
                                      }) : <Typography variant="body2">-</Typography>}
                      </TableCell>
                    </TableRow>
                    )
                }
                else if(cell.id === "image1" || cell.id === "image2" || cell.id === "image3" || cell.id === "image4"){

                  var title = "";
                  if(cell.id === "image1"){
                    title = "Store Picture"
                  }

                  else if(cell.id === "image2"){
                    title = "Lightbox Picture"
                  }

                  else if(cell.id === "image3"){
                    title = "Sticker Picture"
                  }

                  else if(cell.id === "image4"){
                    title = "SSM/Licensing"
                  }

                  return(
                    <TableRow key={labelCellId} >
                      <TableCell component="th" scope="row">
                        {title}
                      </TableCell>
                      <TableCell key={labelCellId}  component="th" scope="row">
                        <Typography style={{ wordWrap: "break-word" }} variant="body2">
                          <Link target="_blank" href={props.selectedItem[cell.id] ? props.selectedItem[cell.id].url : ""} >
                      {props.selectedItem[cell.id] ? props.selectedItem[cell.id].name : ""}
                    </Link></Typography>
                      </TableCell>
                    </TableRow>
                    )
                }


                return(
                  <TableRow key={labelCellId} >
                    <TableCell component="th" scope="row">
                      {cell.label}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Grid style={{overflow: "hidden", maxWidth: 200}} >
                        <Typography style={{ wordWrap: "break-word" }} variant="body2">{props.selectedItem[cell.id]}</Typography>
                      </Grid>
                    </TableCell>
                  </TableRow>
                  
                )
              })}
            </TableBody>
        </Table> : null}

        {props.option === "openFolder" ? renderPage() : null}

        {props.option === "preview" && props.selectedItem.type === "video/mp4" ? 
            <Grid style={{ backgroundColor: "black", padding: 10, display: props.dialogLoading ? "none":"flex", justifyContent: "center", alignItems: "center" }}>
              <ReactPlayer onReady={props.onMediaReady} controls url={props.selectedItem.url}/>
            </Grid>:null}

        {props.option === "preview" && props.selectedItem.type === "image/jpeg" ? 
        <Grid key={props.selectedItem.id}  style={{ height: "100%", width: props.matches ? 450 : 300, display: props.dialogLoading ? "none":"flex", justifyContent: "center", alignItems: "center" }}>
            <img onLoad={props.onMediaReady} style={{ width: "100%", height: "100%", objectFit: 'contain'}} alt={props.selectedItem.name} src={props.selectedItem.url}/>
        </Grid>:null}

        {props.option === "preview_proof_of_payment" ? 
        <Grid key={props.selectedItem.id}  style={{ height: "100%", display: props.dialogLoading ? "none":"flex", justifyContent: "center", alignItems: "center", padding: 10 }}>
            {props.selectedItem.proof_of_payment ? <Carousel style={{ width: "100%" }}>
                  {Object.keys(props.selectedItem.proof_of_payment).map((val, index) => {
                  return <img onLoad={props.onMediaReady} style={{ width: "100%", height: "100%", objectFit: 'contain'}} 
                  alt={props.selectedItem.proof_of_payment[val].name} src={props.selectedItem.proof_of_payment[val].url}/>} )}
                
            </Carousel> : null}
        </Grid>:null}
        
        {props.option === "edit" && !props.customizeDialog ? 
          <Grid container style={{  paddingBottom: 10, display: "flex", flexDirection: "row" }}>
            {props.editCells.map((cell, index)=>{ 
              const labelCellId = `edit-cell-${index}`;
              var sizeMd = 6;

              if(index === props.editCells.length - 1){
                if((index+1) % 2 !== 0){
                  sizeMd = 12;
                } 
              }

              return(
                <Grid key={labelCellId} xs={12} md={sizeMd} style={{  padding: 10, display: "flex", justifyContent: "center", alignItems: "center"}} item >
                  <TextField
                    key={"textfield-input-"+index}
                    label={cell.label}
                    style={{ width: "100%"}}
                    value={props.selectedItem[cell.id]}
                    onChange={(e)=>props.onChangeHandle(e, cell.id)}
                    variant="outlined"/></Grid>
                  )}) }
          </Grid>
        : null}

        {props.option === "addItem" && !props.customizeDialog ? 
          <Grid container style={{ paddingBottom: 10, display: "flex", flexDirection: "row" }}>
            {props.addCells ? props.addCells.map((cell, index)=>{ 
              const labelCellId = `add-cell-${index}`;
              var sizeMd = 6;

              if(index === props.addCells.length - 1){
                if((index+1) % 2 !== 0){
                  sizeMd = 12;
                } 
              }

              if(cell.id === "description"){
                return (<Grid key={labelCellId} xs={12} md={sizeMd} style={{  padding: 10, display: "flex", justifyContent: "center", alignItems: "center"}} item >
                          <TextField
                            key={"textfield-input-"+index}
                            label={cell.label}
                            style={{ width: "100%"}}
                            multiline
                            rows={4}
                            value={props.addItem[cell.id]}
                            onChange={(e)=>props.onChangeAddItemHandle(e, cell.id)}
                            variant="outlined"/></Grid>
                    )
              }
              
              return(   
                <Grid key={labelCellId} xs={12} md={sizeMd} style={{  padding: 10, display: "flex", justifyContent: "center", alignItems: "center"}} item >
                  <TextField
                    key={"textfield-input-"+index}
                    label={cell.label}
                    style={{ width: "100%"}}
                    value={props.addItem[cell.id]}
                    onChange={(e)=>props.onChangeAddItemHandle(e, cell.id)}
                    variant="outlined"/></Grid>
                  )}):null }
                </Grid>
        : null}

        {props.option === "addItem" && props.customizeDialog ? <Grid style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><props.customizeDialog {...props}/></Grid> : null }
        {props.option === "edit" && props.customizeDialog ? <Grid style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><props.customizeDialog {...props}/></Grid> : null }
        {props.dialogLoading ? 
        <Grid style={{ padding: 40, display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress 
              disableShrink
              color="primary" />
        </Grid> : null}

        {props.option === "orderReceipt" ? <Grid style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: 20  }}>
          <Receipt ordersDetails={props.selectedItem}/></Grid> : null }

        {props.option === "orders" ? <Grid style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Orders_store user={props.selectedItem.id}/></Grid> : null }

          {props.option === "sales_report" ? <Grid style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <DailyCheckOut user={props.selectedItem.id}/></Grid> : null }

          {props.option === "products" ? <Grid style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Products_store user={props.selectedItem.id}/></Grid> : null }

        {!props.dialogLoading && props.option === "move"? props.folderData ? 
        <Grid>
        <ListItem button style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)', borderTop: '1px solid rgba(0, 0, 0, 0.1)', width: "100%" }} key={"ListItem-null"} onClick={()=>props.selectFolder(null)} >
          <Typography color="primary" style={{ fontWeight: 'bold' }}>Default</Typography>
        </ListItem>
        {props.folderData.sort((a, b) => (a.name < b.name ? -1 : 1)).map((cell, index)=>{
          return(
            <ListItem button style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)',  width: "100%"}} key={"ListItem-"+index} onClick={()=>props.selectFolder(cell)} >
              <ListItemText primary={cell.name} />
            </ListItem>
          )
        })}
        </Grid>
        :null : null }

        {!props.dialogLoading && props.option === "moveAll"? props.folderData ? 
        <Grid>
        <ListItem button style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)', borderTop: '1px solid rgba(0, 0, 0, 0.1)', width: "100%"}} key={"ListItem-null"} onClick={()=>props.selectFolderMoveAll(null)} >
          <Typography color="primary" style={{ fontWeight: 'bold' }}>Default</Typography>
        </ListItem>
        {props.folderData.sort((a, b) => (a.name < b.name ? -1 : 1)).map((cell, index)=>{
          return(
            <ListItem button style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)', width: "100%"}} key={"ListItem-"+index} onClick={()=>props.selectFolderMoveAll(cell)} >
              <ListItemText primary={cell.name} />
            </ListItem>
          )
        })}
        </Grid>
        :null : null }

        {props.component ? props.component : null}

        {props.customizeDialog && (props.option === "addItem" || props.option === "edit") ? null : props.agreeButton ? <DialogActions>
          <Button variant="outlined" style={{ fontSize: 14, fontWeight: 'bold'}} onClick={props.handleClose} color="primary">
          {props.disagreeTxt}
          </Button>
          <Button disableElevation variant="contained" style={{ fontSize: 14, fontWeight: 'bold'}} onClick={props.handleAgree} color="primary" autoFocus>
          {props.agreeTxt}
          </Button>
          
        </DialogActions> : null} 

      </Dialog>
    </div>
  );
}