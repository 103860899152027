import React, { useEffect } from 'react';
import Table from './Table'
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import firebase from "firebase";

export default function Index(props) {

    const [headCells, setHeadCells] = React.useState([]);
    const [headMobileCells, setHeadMobileCells] = React.useState([]);
    const [searchValue, setSearchValue] = React.useState("");
    const [searchCell, setSearchCell] = React.useState([]);
    const [displayInfo, setDisplayInfo] = React.useState([]);
    const [editCells, setEditCells] = React.useState([]);
    const [addCells, setAddCells] = React.useState([]);
    const [addCellsName, setAddCellsName] = React.useState([]);
    const matches = useMediaQuery(theme => theme.breakpoints.up('sm'));

    useEffect(() => {

        setHeadCells([
            { id: 'name', label: 'Name' },
            { id: 'image',  label: 'Feature Image' },
            { id: 'in_stock', label: 'Stock' },
            { id: 'cost_price',  label: 'Cost Price' },
            { id: 'retail_price', label: 'Retail Price' },
            { id: 'discount_price',  label: 'Sale Price' },
        ])

        setHeadMobileCells([
            { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
        ])
    
        setSearchCell([
            { id: 'id', label: 'ID' },
            { id: 'name', label: 'Name' },
        ])

        setDisplayInfo([
            { id: 'id', label: 'ID' },
            { id: 'name', label: 'Name' },
            { id: 'created', label: 'Created' },
            { id: 'updated', label: 'Updated' },
            { id: 'type', label: 'Type' },
            { id: 'retail_price', label: 'Retail Price' },
            { id: 'discount_price',  label: 'Sale Price' },
            { id: 'cost_price',  label: 'Cost Price' },
            { id: 'in_stock', label: 'In Stock' },
            { id: 'status', label: 'Status' },
            { id: 'description', label: 'Description' }
        ])

        setEditCells([
            { id: 'name', label: 'Name' },
            { id: 'retail_price', label: 'Retail Price' },
            { id: 'discount_price',  label: 'Sale Price' },
            { id: 'cost_price',  label: 'Cost Price' },
        ])

        setAddCells([
            { id: 'image',  label: 'Image' },
            { id: 'name', label: 'Name' },
            { id: 'retail_price', label: 'Retail Price' },
            { id: 'discount_price',  label: 'Sale Price' },
            { id: 'cost_price',  label: 'Cost Price' },
            { id: 'type', label: 'Type' },
            { id: 'status', label: 'Status' },
            { id: 'categories', label: 'Catogories' },
            { id: 'description', label: 'Description' }
        ])

        setAddCellsName({ 
            name: '', 
            created: firebase.database.ServerValue.TIMESTAMP,
            updated: firebase.database.ServerValue.TIMESTAMP,
            price: '',
            sale_price: '',
            sale_price_2: '',
            quantity:'',
            in_stock:'0',
            status: 'published', 
            categories: [],
            unit: '', 
            description: '',
            image: [],
            gallery: [],
            type: ''
         })
    
        setSearchValue("name")
        
    }, []);

    return (
    <Grid>
        <Table 
            {...props}
            //customizeDialog={AddEditProducts}
            control={true}
            orderBy="created" 
            hideMove={true}
            hideAllMove={true}
            hideRemove={true}
            data={"pos/" + props.user + "/products"}
            addItemButton={false}
            editButton={true}
            pullProductButton={true}
            addItemText="Add product"
            addItemIcon={<AddCircleIcon/>}
            addCells={addCells}
            addCellsName={addCellsName}
            folder_data={"pos/" + props.user + "/products_zone"}
            displayInfo={displayInfo}
            editCells={editCells}
            matches={matches}
            headCells={matches ? headCells : headMobileCells} 
            searchValue={searchValue} 
            searchCell={searchCell}
            />
    </Grid>
    )

}
  