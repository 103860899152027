import React, { useEffect } from 'react';
import Table from '../../../modules/components/Table'
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddEditConceptStore from './AddEditConceptStore';
import firebase from "firebase";

export default function Index(props) {

    const [headCells, setHeadCells] = React.useState([]);
    const [headMobileCells, setHeadMobileCells] = React.useState([]);
    const [searchValue, setSearchValue] = React.useState("");
    const [searchCell, setSearchCell] = React.useState([]);
    const [displayInfo, setDisplayInfo] = React.useState([]);
    const [editCells, setEditCells] = React.useState([]);
    const [addCells, setAddCells] = React.useState([]);
    const [addCellsName, setAddCellsName] = React.useState([]);
    const matches = useMediaQuery(theme => theme.breakpoints.up('sm'));

    useEffect(() => {

        setHeadCells([
           
            { id: 'id', label: 'Store Id' },
            { id: 'store_name', label: 'Store Name' },
            { id: 'company_name', label: 'Company Name' },
            { id: 'role', label: 'Role' },
            { id: 'phone', label: 'Phone' },
            { id: 'status_users', label: 'Status' },
        ])

        setHeadMobileCells([
            { id: 'id', label: 'Store Id' },
            { id: 'first_name', label: 'First Name' },
            { id: 'last_name', label: 'Last Name' },
        ])
    
        setSearchCell([
            { id: 'id', label: 'Store Id' },
            { id: 'phone', label: 'Phone' },
            { id: 'email', label: 'Email' },
        ])

        setDisplayInfo([
            { id: 'id', label: 'Store Id' },
            { id: 'store_name', label: 'Store Name' },
            { id: 'company_name', label: 'Company Name' },
            { id: 'store_address', label: 'Store Address' },
            { id: 'ssm_id', label: 'SSM ID' },
            { id: 'role', label: 'Role' },
            { id: 'created', label: 'Registered' },
            { id: 'phone', label: 'Phone' },
            { id: 'status_users', label: 'Status' },
        ])

        setEditCells([
            { id: 'id', label: 'Store Id' },
            { id: 'store_name', label: 'Store Name' },
            { id: 'company_name', label: 'Company Name' },
            { id: 'store_address', label: 'Store Address' },
            { id: 'company_phone', label: 'Company Phone' },
            { id: 'ssm_id', label: 'SSM ID' },
            { id: 'phone', label: 'Phone' },
            { id: 'address', label: 'Address' },
            { id: 'status_users', label: 'Status' },
        ])

        setAddCells([
            { id: 'area', label: 'Area' },
            { id: 'store_name', label: 'Store Name' },
            { id: 'company_name', label: 'Company Name' },
            { id: 'store_address', label: 'Store Address' },
            { id: 'company_phone', label: 'Company Phone' },
            { id: 'ssm_id', label: 'SSM ID' },
            { id: 'phone', label: 'Phone' },
            { id: 'address', label: 'Address' },
            { id: 'status_users', label: 'Status' },
        ])

        setAddCellsName({ 
            id: '',
            store_address: '',
            first_name: '',
            last_name: '',
            point_users: '',
            created: firebase.database.ServerValue.TIMESTAMP,
            updated: firebase.database.ServerValue.TIMESTAMP,
            email: '',
            phone: '',
            status_users: '',
            role: '',
            user_redeem_time: '',
            company_phone: ''
         })
    
        setSearchValue("id")
        
    }, []);

    return (
    <Grid>
        <Table 
            {...props}
            customizeDialog={AddEditConceptStore}
            control={true}
            orderBy="created" 
            data="concept_store" 
            addItemButton={true}
            addItemText="Add Store"
            addItemIcon={<AddCircleIcon/>}
            addCells={addCells}
            addCellsName={addCellsName}
            folder_data="concept_store_zone"
            displayInfo={displayInfo}
            editCells={editCells}
            matches={matches}
            headCells={matches ? headCells : headMobileCells} 
            searchValue={searchValue} 
            searchCell={searchCell}/>
    </Grid>
    )

}
  