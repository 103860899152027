import React, { useEffect }  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import firebase from "firebase";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  root: {
    display:"flex",
    flexGrow: 1,
    flexDirection: "column",
    alignItems: "center",
  },
}));

function Index (props){

  const classes = useStyles();
  const [ordersDetails, setOrdersDetails] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {

    if(props.data){
      let ordersRef = firebase.database().ref("gift_redeem/"+ props.data);

      ordersRef.on('value', snapshot => {
        
        if(snapshot.exists()){
          setOrdersDetails(snapshot.val())
        }
        else{
          setOrdersDetails([])
        }
  
        setLoading(false)
      })
    }
    
    
  }, [props]);

  if(loading){
    return (
    <Grid className={classes.root}>
      <Grid style={{ marginTop: 70 }}>
        <CircularProgress 
          disableShrink
          color="primary" />
      </Grid>
    </Grid>);
  }


  return (
    <Grid className={classes.root}>
      <Grid container style={{ maxWidth: 1000, flexDirection: "row" }}>
        
        <Grid item xs={12} style={{ display: "flex", padding: 15, flexDirection: "row", alignItems: "center", justifyContent: "space-between" }} >
          
          <Grid container spacing={2}>
            <Grid item xs={12} md={7}>
              <Typography style={{ fontSize: 18 }} variant="body2">
                {"Status"}
              </Typography>
              <Typography color="primary" style={{ fontSize: 18 }} variant="body2">
                {ordersDetails.status_orders}
              </Typography>
              
            </Grid>
            
          </Grid>

        </Grid>
        
        <Grid item xs={12} style={{ padding: 15 }}>
          <Grid container spacing={2} style={{ flexDirection: "row" }}>
              <Grid item xs={12} md={3}>
                <Grid style={{ border:"1px solid #7e7e7e", borderRadius: 5, width: "100%", padding: 15 }}>
                  <Typography variant="caption">
                    {"Order Number"}
                  </Typography>
                  <Typography variant="body2" style={{ opacity: .6 }}>
                    {ordersDetails.uuid}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} md={3}>
                <Grid style={{ border:"1px solid #7e7e7e", borderRadius: 5, width: "100%", padding: 15 }}>
                  <Typography variant="caption">
                    {"Date"}
                  </Typography>
                  <Typography variant="body2" style={{ opacity: .6 }}>
                    {new Intl.DateTimeFormat('en-GB', {year: 'numeric',day: '2-digit',  month: 'long' }).format(ordersDetails.created)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} md={3}>
                <Grid style={{ border:"1px solid #7e7e7e", borderRadius: 5, width: "100%", padding: 15 }}>
                  <Typography variant="caption">
                    {"Total"}
                  </Typography>
                  <Typography variant="body2" style={{ opacity: .6 }}>
                    {"RM " + ordersDetails.amount}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} md={3}>
                <Grid style={{ border:"1px solid #7e7e7e", borderRadius: 5, width: "100%", padding: 15 }}>
                  <Typography variant="caption">
                    {"Payment Method"}
                  </Typography>
                  <Typography variant="body2" style={{ opacity: .6 }}>
                    {"Bank Transfer"}
                  </Typography>
                </Grid>
              </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} >
          <Grid container style={{ flexDirection: "row" }}>
            <Grid item xs={12} md={6}>
              <Grid style={{width: "100%", padding: 15 }}>
                <Grid>
                  <Typography style={{ fontSize: 24 }} variant="body2">
                    {"Total Amount"}
                  </Typography>
                </Grid>
                <Grid style={{ marginTop: 10 }}>
                  <Typography style={{ fontSize: 14 }} variant="body2">
                    {"Shipping Charge"}
                  </Typography>
                  <Typography style={{ fontSize: 14, opacity: .6 }} variant="body2">
                    {ordersDetails.shipping_fee? "RM: " + ordersDetails.shipping_fee : "RM 0"}
                  </Typography>
                </Grid>
                <Grid style={{ marginTop: 10 }}>
                  <Typography style={{ fontSize: 14 }} variant="body2">
                    {"Tax"}
                  </Typography>
                  <Typography style={{ fontSize: 14, opacity: .6 }} variant="body2">
                    {ordersDetails.tax ? "RM: " + ordersDetails.tax : "RM 0"}
                  </Typography>
                </Grid>
                <Grid style={{ marginTop: 10 }}>
                  <Typography style={{ fontSize: 14 }} variant="body2">
                    {"Total"}
                  </Typography>
                  <Typography style={{ fontSize: 14, opacity: .6 }} variant="body2">
                    {"RM " + ordersDetails.amount}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid style={{width: "100%", padding: 15 }}>
                <Grid>
                  <Typography style={{ fontSize: 24 }} variant="body2">
                    {"Order Details"}
                  </Typography>
                </Grid>
                <Grid style={{ marginTop: 10 }}>
                  <Typography style={{ fontSize: 14 }} variant="body2">
                    {"Total Items"}
                  </Typography>
                  <Typography style={{ fontSize: 14, opacity: .6 }} variant="body2">
                    {ordersDetails.products ? ordersDetails.products.length : "0"}
                  </Typography>
                </Grid>
                <Grid style={{ marginTop: 10 }}>
                  <Typography style={{ fontSize: 14 }} variant="body2">
                    {"Shipping Address"}
                  </Typography>
                  <Typography style={{ fontSize: 12, opacity: .4 }} variant="body2">
                    {ordersDetails.shipping_address ? ordersDetails.shipping_address.title : ""}
                  </Typography>
                  <Typography style={{ fontSize: 14, opacity: .6 }} variant="body2">
                  {ordersDetails.shipping_address ? ordersDetails.shipping_address.street_address + ", " + 
                  ordersDetails.shipping_address.city + ", " + 
                  ordersDetails.shipping_address.zip + " " + 
                  ordersDetails.shipping_address.state + ", " + 
                  ordersDetails.shipping_address.country : ""}
                  </Typography>
                </Grid>
                
                <Grid style={{ marginTop: 10 }}>
                  <Typography style={{ fontSize: 14 }} variant="body2">
                    {"Billing Address"}
                  </Typography>
                  <Typography style={{ fontSize: 12, opacity: .4 }} variant="body2">
                    {ordersDetails.billing_address ? ordersDetails.billing_address.title : ""}
                  </Typography>
                  <Typography style={{ fontSize: 14, opacity: .6 }} variant="body2">
                  {ordersDetails.billing_address ? ordersDetails.billing_address.street_address + ", " + 
                  ordersDetails.billing_address.city + ", " + 
                  ordersDetails.billing_address.zip + " " + 
                  ordersDetails.billing_address.state + ", " + 
                  ordersDetails.billing_address.country : ""}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ padding: 15 }}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Item</TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="center">Quantity</TableCell>
                  <TableCell align="left">Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ordersDetails.products.map((row, index) => (
                  <TableRow key={"item-"+index}>
                    <TableCell component="th" scope="row">
                      {row.image ? <img
                                    src={row.image[0].url}
                                    style={{  display: 'block', width: 'auto', maxHeight: 50, maxWidth: 50 }}
                                    alt={row.name}
                                    /> : null}
                    </TableCell>
                    <TableCell align="left">
                      <Typography style={{ fontSize: 14, opacity: .6 }} variant="body2">
                        {"RM " + row.sale_price}
                      </Typography>
                      <Typography style={{ fontSize: 12 }} variant="body2">
                        {row.name}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">{row.quantity}</TableCell>
                    <TableCell align="left">{"RM " + row.sale_price * row.quantity}</TableCell>
                  </TableRow>
                ))}

                {ordersDetails.coupon && ordersDetails.coupon.coupon_type === "free_item" ? ordersDetails.coupon.free_products.map((row, index) => (
                  <TableRow key={"item-"+index}>
                    <TableCell component="th" scope="row">
                      {row.image ? <img
                                    src={row.image[0].url}
                                    style={{  display: 'block', width: 'auto', maxHeight: 50, maxWidth: 50 }}
                                    alt={row.name}
                                    /> : null}
                    </TableCell>
                    <TableCell align="left">
                      <Typography style={{ fontSize: 14, opacity: .6 }} variant="body2">
                        {"RM " + row.sale_price}
                      </Typography>
                      <Typography style={{ fontSize: 12 }} variant="body2">
                        {row.name}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">{row.quantity}</TableCell>
                    <TableCell align="left">{"FREE"}</TableCell>
                  </TableRow>
                 )) : null} 

              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
    
  );
  
}

export default Index;