import React, { useEffect }  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import firebase from "firebase";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';

const useStyles = makeStyles((theme) => ({
  root: {
    display:"flex",
    flexGrow: 1,
    flexDirection: "column",
    alignItems: "center",
  },
}));

function Index (props){

  const classes = useStyles();
  const [ordersDetails, setOrdersDetails] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {

    if(props.ordersDetails){
        setLoading(false)
        setOrdersDetails(props.ordersDetails)
    }
    
    
  }, [props]);

  const renderPaymentMethod = () => {

    let paymentMethod = "Cash";

    if(ordersDetails.payment_method === "debit_card"){
      paymentMethod = "Debit Card"
    }
    else if(ordersDetails.payment_method === "credit_card"){
      paymentMethod = "Credit Card"
    }
    else if(ordersDetails.payment_method === "ewallet"){
      paymentMethod = "E-Wallet"
    }
    else if(ordersDetails.payment_method === "check"){
      paymentMethod = "Cheque"
    }
    else if(ordersDetails.payment_method === "gift"){
      paymentMethod = "Gift Card"
    }
    else if(ordersDetails.payment_method === "online_transfer"){
      paymentMethod = "Online Transfer"
    }

    return paymentMethod
  }

  if(loading){
    return (
    <Grid className={classes.root}>
      <Grid style={{ marginTop: 70 }}>
        <CircularProgress 
          disableShrink
          color="primary" />
      </Grid>
    </Grid>);
  }


  return (
    <ThemeProvider
    theme={theme}
    >
    <Paper elevation={0} style={{ marginBottom: 50 }}>
      <Grid style={{ width: 320, flexDirection: "row", paddingBottom: 20 }}>
        <Grid style={{ padding: 15, display: "flex", flexDirection: "column" }}>
          <Grid>
          
          
          <Grid style={{ display: "flex", flexDirection: "column", marginTop: 10 }}>
          <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
          <img height={120} src="/assets/logo/logo-dark.png" alt="Sp2s Logo"/>
          </Grid>
            
          </Grid>
         
          
          </Grid>

          <Grid style={{ paddingTop: 10, display: "flex", flexDirection: "column", justifyContent: "space-between" }} >
            <Grid>
              <Typography align='center' variant="body2" style={{ fontWeight: "normal", color: "black" }}>
                    {ordersDetails.store_name ? ordersDetails.store_name : ""}
                  </Typography>
            </Grid>
                  
           </Grid>

          <Grid style={{ paddingTop: 10, display: "flex", flexDirection: "column", justifyContent: "space-between" }} >
            <Grid>
              <Typography align='center' variant="body2" style={{ fontWeight: "normal", color: "black" }}>
                    {ordersDetails.ssm_id ? ordersDetails.ssm_id : ""}
                  </Typography>
            </Grid>
                  
           </Grid>

          <Grid style={{ paddingTop: 10, display: "flex", flexDirection: "column", justifyContent: "space-between" }} >
            <Grid>
              <Typography align='center' variant="body2" style={{ fontWeight: "normal", color: "black" }}>
                    {ordersDetails.store_address ? ordersDetails.store_address : ""}
                  </Typography>
            </Grid>
            <Typography variant="h6" align='center' style={{ fontWeight: "bold", color: "black", marginTop: 10 }}>
                    {"RECEIPT"}
          </Typography>
           </Grid>

           <Grid style={{ paddingTop: 10, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <Grid style={{ display: "flex", flexDirection: "column" }} >
              <Grid>
                <Typography variant="caption"  style={{ fontWeight: "normal", color: "black" }}>
                  {"Date"}
                </Typography>
              </Grid>
              <Grid>
                <Typography variant="body2" style={{ fontWeight: "normal", color: "black" }}>
                      {new Intl.DateTimeFormat('en-GB', {year: 'numeric',day: '2-digit',  month: 'long', hour: '2-digit', minute: '2-digit', second: 'numeric' }).format(ordersDetails.created)}
                    </Typography>
              </Grid>
                    
            </Grid>

            <Grid>
              <Typography variant="caption" style={{ fontWeight: "normal", color: "black" }}>
                {"Receipt Number"}
              </Typography>
              <Typography variant="body2" align={"right"} style={{ fontWeight: "normal", color: "black" }}>
                {ordersDetails.uuid}
              </Typography>
            </Grid>

           </Grid>
           
        </Grid>

        <Divider light style={{ width: "100%"}} />

        <Grid style={{ padding: 15 }}>
          <Grid style={{ display: "flex", flexDirection: "column" }}>
              <Grid>
                <Grid>
                  <Typography variant="caption" style={{ fontWeight: "normal", color: "black" }}>
                    {"Cashier"}
                  </Typography>
                  <Typography variant="body2"  style={{ fontWeight: "normal", color: "black" }}>
                    {ordersDetails.cashier ? (ordersDetails.cashier.last_name + " " + ordersDetails.cashier.first_name)  : "N/A"}
                  </Typography>
                </Grid>
               
              </Grid>
              
              <Grid style={{ paddingTop: 10, display: "flex", justifyContent: "flex-start", flexDirection: "column", alignItems: "flex-start" }} >
                
                <Grid>
                    <Typography variant="caption" style={{ fontWeight: "normal", color: "black" }} >
                        {"Customer"}
                    </Typography>
                    </Grid>
                    <Grid>
                    <Typography variant="body2" align={"right"} style={{ fontWeight: "normal", color: "black" }}>
                            {ordersDetails.orders_customer ? ordersDetails.orders_customer[0].last_name + " " + ordersDetails.orders_customer[0].first_name : "Walk In" }
                        </Typography>
                    </Grid>
              </Grid>
             
          </Grid>
        </Grid>

        <Grid>
          <TableContainer>
            <Table size="small" >
              <TableBody >
                <TableRow>
                  <TableCell style={{ fontSize: 12, fontWeight: "normal", color: "black" }} >Item</TableCell>
                  <TableCell style={{ fontSize: 12, fontWeight: "normal", color: "black" }} align="center">Qty</TableCell>
                  <TableCell style={{ fontSize: 12, fontWeight: "normal", color: "black" }} align="left">Price</TableCell>
                </TableRow >
                {ordersDetails && ordersDetails.products.length > 0 ? ordersDetails.products.map((row, index) => (
                  <TableRow key={"item-"+index} >
                    <TableCell align="left">
  
                      <Typography style={{ fontSize: 10, fontWeight: "normal", color: "black" }} variant="body2">
                        {row.name}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ fontSize: 10, fontWeight: "normal", color: "black" }} align="center">{row.quantity}</TableCell>
                    <TableCell  style={{ fontSize: 10, fontWeight: "normal", color: "black" }} align="left">
                    <Typography noWrap style={{ fontSize: 10, fontWeight: "normal", color: "black" }} variant="body2">
                    {row.orders_customer ? "RM " + (row.discount_price * row.quantity).toFixed(2) : "RM " + (row.retail_price * row.quantity).toFixed(2)}
                      </Typography>
                     
                      </TableCell>
                  </TableRow>
                )): null}

              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid style={{ padding: 15, display: "flex", flexDirection: "column" }}>
          
          <Grid style={{ display: "flex", justifyContent: "flex-end", flexDirection: "row", alignItems: "flex-end" }} >
            <Grid container>
              <Grid item xs={6} style={{ paddingRight: 50, width: 200 }}>
                <Typography variant="body2" style={{ paddingTop: 10, fontWeight: "normal", color: "black" }}>
                  {"Subtotal"}
                </Typography>
                <Typography variant="body2"  style={{ paddingTop: 10, fontWeight: "normal", color: "black" }}>
                  {"Tax"}
                </Typography>
                <Typography variant="body2"  style={{ paddingTop: 10, fontWeight: "normal", color: "black" }}>
                  {"Discount/Waiver"}
                </Typography>
                <Typography variant="h6"  style={{ paddingTop: 10, fontWeight: "bold", color: "black" }}>
                  {"Total"}
                </Typography>
                <Typography variant="body2"  style={{ paddingTop: 10, fontWeight: "normal", color: "black" }}>
                  {renderPaymentMethod()}
                </Typography>
                <Typography variant="h6"  style={{ paddingTop: 10, fontWeight: "bold", color: "black" }}>
                  {"Changes"}
                </Typography>
              </Grid> 
              <Grid item xs={6} style={{  display: "flex", alignItems: "flex-end", flexDirection: "column" }}>
                <Typography noWrap variant="body2"   style={{ paddingTop: 10, fontWeight: "normal", color: "black" }}>
                  {"RM " + ordersDetails.subtotal.toFixed(2)}
                </Typography>
                <Typography noWrap variant="body2"  style={{ paddingTop: 10, fontWeight: "normal", color: "black" }}>
                  {"RM 0.00"}
                </Typography>
                <Typography noWrap variant="body2"  style={{ paddingTop: 10, fontWeight: "normal", color: "black"}}>
                {"RM " + Number(ordersDetails.waiver).toFixed(2)}
                </Typography>
                <Typography noWrap variant="h6"  style={{ paddingTop: 10, fontWeight: "normal", color: "black" }}>
                  {"RM " + ordersDetails.amount.toFixed(2)}
                </Typography>
                <Typography noWrap variant="body2"  style={{ paddingTop: 10, fontWeight: "normal", color: "black" }}>
                {"RM " + (ordersDetails.amound_tendered ? ordersDetails.amound_tendered.toFixed(2) : "0.00")}
                </Typography>
                <Typography noWrap variant="h6"  style={{ paddingTop: 10, fontWeight: "normal", color: "black" }}>
                  {"RM " + ((ordersDetails.amound_tendered ? ordersDetails.amound_tendered : 0) -  ordersDetails.amount).toFixed(2)}
                </Typography>
              </Grid>

            </Grid>

            

          </Grid>
          
        </Grid>
        <Divider light style={{ width: "100%"}} />
          <Grid>
              <Typography variant="h6" style={{ paddingTop: 20, fontSize: 14, fontWeight: "bold", color: "black", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    {"THANK YOU FOR YOUR BUSINESS!"}
                  </Typography>
            </Grid>
            
            <Grid>
            <Typography variant="caption" style={{ paddingTop: 10, fontSize: 14, fontWeight: "bold", color: "black", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    {"- sp2smalaysia.com -"}
            </Typography>
            </Grid>
            <Grid>
              <Typography variant="h6" style={{ paddingTop: 10, fontSize: 10, fontWeight: "normal", color: "black", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    {"** Goods sold are not exchangeable **"}
                  </Typography>
            </Grid>
           
      </Grid>
    </Paper>
    </ThemeProvider>
  );
  
}

export default Index;