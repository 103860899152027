import firebase from "firebase";

//SP2s
const app = firebase.initializeApp({
    apiKey: "AIzaSyBnXV-CTYzntdhfL6TMDu_kj40HSGm-SkI",
    authDomain: "sp2smalaysia-41a0d.firebaseapp.com",
    databaseURL: "https://sp2smalaysia-41a0d-default-rtdb.firebaseio.com",
    projectId: "sp2smalaysia-41a0d",
    storageBucket: "sp2smalaysia-41a0d.appspot.com",
    messagingSenderId: "487018956238",
    appId: "1:487018956238:web:30555b8ecb5ca2756fd79f"
});

/*const app = firebase.initializeApp({
    apiKey: "AIzaSyCgQaybvmVzNURJbPiHvCsTpadxWE5lzNg",
    authDomain: "serply-53416.firebaseapp.com",
    databaseURL: "https://serply-53416-default-rtdb.firebaseio.com",
    projectId: "serply-53416",
    storageBucket: "serply-53416.appspot.com",
    messagingSenderId: "616578044505",
    appId: "1:616578044505:web:a969bd511451cbf68e1748"
});*/

//dummy
/*const app = firebase.initializeApp({
    apiKey: "AIzaSyAGLwOLeg3rBKzoA_VRdcT0UWvTHXjdP9M",
  authDomain: "ordering-dummy.firebaseapp.com",
  databaseURL: "https://ordering-dummy-default-rtdb.firebaseio.com",
  projectId: "ordering-dummy",
  storageBucket: "ordering-dummy.appspot.com",
  messagingSenderId: "643540930529",
  appId: "1:643540930529:web:d8f65fa6a1436602608ca0"
});*/

export default app;