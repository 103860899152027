import React, { useEffect } from 'react';
import Table from '../../../modules/components/Table'
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddEditUserLevel from './AddEditUserLevel';
import firebase from "firebase";

export default function Index(props) {

    const [headCells, setHeadCells] = React.useState([]);
    const [headMobileCells, setHeadMobileCells] = React.useState([]);
    const [searchValue, setSearchValue] = React.useState("");
    const [searchCell, setSearchCell] = React.useState([]);
    const [displayInfo, setDisplayInfo] = React.useState([]);
    const [editCells, setEditCells] = React.useState([]);
    const [addCells, setAddCells] = React.useState([]);
    const [addCellsName, setAddCellsName] = React.useState([]);
    const matches = useMediaQuery(theme => theme.breakpoints.up('sm'));

    useEffect(() => {

        setHeadCells([
            { id: 'name', label: 'Name' },
            { id: 'highest_level', label: 'highest Level' },
            { id: 'min_point', label: 'Min Point' },
            { id: 'max_point', label: 'Max Point' }
        ])

        setHeadMobileCells([
            { id: 'name', label: 'Name' },
        ])
    
        setSearchCell([
            { id: 'id', label: 'ID' },
            { id: 'name', label: 'Name' },
        ])

        setDisplayInfo([
            { id: 'id', label: 'ID' },
            { id: 'name', label: 'Name' },
            { id: 'highest_level', label: 'highest Level' },
            { id: 'min_point', label: 'Min Point' },
            { id: 'max_point', label: 'Max Point' },
            { id: 'created', label: 'Created' }
        ])

        setEditCells([
            { id: 'name', label: 'Name' },
            { id: 'highest_level', label: 'highest Level' },
            { id: 'min_point', label: 'Min Point' },
            { id: 'max_point', label: 'Max Point' },
           
        ])

        setAddCells([
            { id: 'name', label: 'Name' },
            { id: 'highest_level', label: 'highest Level' },
            { id: 'min_point', label: 'Min Point' },
            { id: 'max_point', label: 'Max Point' },
           
        ])

        setAddCellsName({ 
            name: '', 
            created: firebase.database.ServerValue.TIMESTAMP,
            updated: firebase.database.ServerValue.TIMESTAMP,
            min_point: null,
            max_point: null,
            highest_level: null
         })
    
        setSearchValue("name")
        
    }, []);

    return (
    <Grid>
        <Table 
             {...props}
            order={'asc'}
            control={true}
            disabledMove={true}
            customizeDialog={AddEditUserLevel}
            orderBy="level" 
            data="user_level" 
            addItemButton={true}
            addItemText="Add User Level"
            addItemIcon={<AddCircleIcon/>}
            addCells={addCells}
            addCellsName={addCellsName}
            folder_data="user_level_zone"
            displayInfo={displayInfo}
            editCells={editCells}
            matches={matches}
            headCells={matches ? headCells : headMobileCells} 
            searchValue={searchValue} 
            searchCell={searchCell}/>
    </Grid>
    )

}
  