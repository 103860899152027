import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import firebase from "firebase";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export default function Index(props) {

    //Dialog
    const [values, setValues] = React.useState({});

    useEffect(() => {
        setValues(props.addCellsName ? props.addCellsName : {})

        if(props.option === "edit"){
            setValues(props.selectedItem ? props.selectedItem : {})
        }


    }, [props]);

    const handleAgree = () => {

        var updates = {};
        if(props.option === "edit"){
            values.id = props.selectedItem.id;

            if(values.highest_level === true){
                values.min_point = null;
                values.max_point = null;
            }

            updates[props.data + '/' + props.selectedItem.id] = values;
    
            firebase.database().ref().update(updates);
        }
        else{
            var newPostKey = firebase.database().ref().child(props.data).push().key;

            values.id = newPostKey;
            values.user = firebase.auth().currentUser.email;

            if(values.highest_level === true){
                values.min_point = null;
                values.max_point = null;
            }
    
            updates[props.data + '/' + newPostKey] = values;
    
            firebase.database().ref().update(updates);
        }
        

        props.handleClose()
    }

    const handleChange = (prop) => (event) => {
        if(prop === "highest_level"){
            setValues({ ...values, [prop]: event.target.checked });
        }
        else
        {
            setValues({ ...values, [prop]: event.target.value });
        }
        
    };

    return (
        <Grid style={{ maxWidth: 625  }}>
            <Grid container style={{ paddingBottom: 10, display: "flex", flexDirection: "row" }}>
                {props[props.option === "edit" ? "editCells" : "addCells"] ? props[props.option === "edit" ? "editCells" : "addCells"].map((cell, index)=>{ 
                const labelCellId = `add-cell-${index}`;
                var sizeMd = 6;

                if(index === props.addCells.length - 1){
                    if((index+1) % 2 !== 0){
                    sizeMd = 12;
                    } 
                }

                if(cell.id === "highest_level"){
                    return (<Grid key={labelCellId} xs={12} md={sizeMd} style={{  padding: 10, display: "flex", justifyContent: "center", alignItems: "center"}} item >
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                        <Checkbox
                                            checked={values[cell.id] || false}
                                            onChange={handleChange(cell.id)}
                                            name="Highest Level"
                                            color="primary"
                                        />
                                        }
                                        label="Highest Level"
                                    />
                                </FormGroup>
                            </Grid>)
                }

                if(cell.id === "min_point" || cell.id === "max_point"){
                    return (<Grid key={labelCellId} xs={12} md={sizeMd} style={{  padding: 10, display: "flex", justifyContent: "center", alignItems: "center"}} item >
                            <TextField
                                disabled={values["highest_level"]}
                                key={"textfield-input-"+index}
                                label={cell.label}
                                style={{ width: "100%"}}
                                type="number"
                                value={values[cell.id] || ''}
                                onChange={handleChange(cell.id)}
                                variant="outlined"/>
                            </Grid>)
                }

                return(   
                    <Grid key={labelCellId} xs={12} md={sizeMd} style={{  padding: 10, display: "flex", justifyContent: "center", alignItems: "center"}} item >
                    <TextField
                        key={"textfield-input-"+index}
                        label={cell.label}
                        style={{ width: "100%"}}
                        value={values[cell.id] || ''}
                        onChange={handleChange(cell.id)}
                        variant="outlined"/></Grid>
                    )}):null }
            </Grid>
            
            <Grid style={{ padding: 10, marginBottom: 30, display: 'flex', justifyContent: "flex-end" }}>
                <Button variant="outlined" style={{ fontSize: 14, fontWeight: 'bold'}} onClick={props.handleClose} color="primary">
                {"No"}
                </Button>
                <Button disableElevation variant="contained" style={{ marginLeft: 10, fontSize: 14, fontWeight: 'bold'}} onClick={handleAgree} color="primary" autoFocus>
                {"Yes"}
                </Button>
            </Grid>
        </Grid>
    )

}
  