import React, { useEffect } from 'react';

import Dialog1 from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import {
  TablePagination
} from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from './Dialog'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import EnhancedTableHead from './EnhancedTableHead';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ControlButton from './ControlButton';
import firebase from "firebase";

import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import Description from '@material-ui/icons/Description';

import moment from "moment";

import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import ReactExport from 'react-data-export';
import Button from '@material-ui/core/Button';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;



//bytesToSize
const bytesToSize = (bytes) => {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
};

// Table Sort
function descendingComparator(a, b, orderBy) {

    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
  
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

//Table
const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    table: {
      minWidth: "100%",
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    datepicker:{
      color: "black",
      background: "white"
    }
}));

export default function CustomPaginationActionsTable(props) {

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [addItem, setAddItem] = React.useState('');
  const [order, setOrder] = React.useState(props.order ? props.order : 'desc');
  const [orderBy, setOrderBy] = React.useState(props.orderBy);
  const [rowsOriginal, setRowsOriginal] = React.useState([]);
  const [headCells, setHeadCells] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState("");
  const [searchtxt, setSearchtxt] = React.useState("");
  const [searchCell, setSearchCell] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [dialogLoading, setDialogLoading] = React.useState(false);
  const [fullScreenDialog, setFullScreenDialog] = React.useState(false);
  const [dialogTitle, setDialogTitle] = React.useState("");
  const [dialogDes, setDialogDes] = React.useState("");
  const [agreeButton, setAgreeButton] = React.useState(false);
  const [collapse, setCollapse] = React.useState(null);
  const [option, setOptions] = React.useState("");
  const [folderData, setFolderData] = React.useState(null);

  const [allUser, setAllUser] = React.useState([]);
  const matches = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const [dateRange, setDateRange] = React.useState([null,null]);
  
  const [totalCheckOutAmount, setTotalCheckOutAmount] = React.useState(0);

  //download excel
  const [multiDataSet, setMultiDataSet] = React.useState([]);
  const excelInput = React.useRef(null);

  const [open, setOpen] = React.useState(false);

  useEffect(() => {


    if(props.data === "checkoutdaily"){

      let itemRef = firebase.database().ref("pos/" + props.user + "/orders");

      itemRef.on('value', snapshot => {
        if(snapshot.exists()){

          let items = [];
          
          snapshot.forEach(child => {
            var itemsVal = child.val();

            var itemDate = new Intl.DateTimeFormat('en-GB', {year: 'numeric',day: '2-digit',  month: 'long' }).format(itemsVal.created)
            itemsVal.created = itemDate;

            items.push(itemsVal)

          })

          let creditNoteRef = firebase.database().ref("pos/" + props.user + "/credit_note");

          creditNoteRef.on('value', snapshot => {

            let items_cn = [];

            snapshot.forEach(child => {
              var itemsVal = child.val();
              var itemDate = new Intl.DateTimeFormat('en-GB', {year: 'numeric',day: '2-digit',  month: 'long' }).format(itemsVal.created)
              itemsVal.created = itemDate;
              items_cn.push(itemsVal)
            })

            // Create a map for the dates
          let dataMap = {};

          let _totalCheckOutAmount = 0;
          
          items.forEach((el, index) => {

            _totalCheckOutAmount += el.amount;
            
            let tempDate = el.created;
            if (tempDate in dataMap) {
              
              dataMap[tempDate].checkout_amount.push(el.amount);
              dataMap[tempDate].checkout_sub_total.push(el.subtotal);
              dataMap[tempDate].payment_method.push(el.payment_method);
              dataMap[tempDate].cash.push(el.payment_method === "cash" ? el.amount : 0);
              dataMap[tempDate].debit_card.push(el.payment_method === "debit_card" ? el.amount : 0);
              dataMap[tempDate].credit_card.push(el.payment_method === "credit_card" ? el.amount : 0);
              dataMap[tempDate].ewallet.push(el.payment_method === "ewallet" ? el.amount : 0);
              dataMap[tempDate].online_transfer.push(el.payment_method === "online_transfer" ? el.amount : 0);
              dataMap[tempDate].gift.push(el.payment_method === "gift" ? el.amount : 0);

              dataMap[tempDate].total_invoice =  dataMap[tempDate].total_invoice ? dataMap[tempDate].total_invoice+1 : 1;
              dataMap[tempDate].checkout_discount.push(el.waiver ? Number(el.waiver) : 0);
              dataMap[tempDate].total_cost_of_goods_sold.push(el.cost_amount ? Number(el.cost_amount) : 0);
              dataMap[tempDate].redeemed_point.push(el.product_use_point? Number(el.product_use_point) : 0)

              if(el.products){
                el.products.forEach((item)=>{
                  dataMap[tempDate].all_products.push(item)
                })
              }

              
            } else {
              dataMap[tempDate] = {
                checkout_created_timestamp: moment(tempDate).unix(),
                checkout_created: moment(tempDate).unix(),
                checkout_amount: [el.amount],
                checkout_sub_total: [el.subtotal],
                total_invoice: 1,
                checkout_discount: [Number(el.waiver)],
                total_credit_note: [],
                net_turnover: 0,
                total_cost_of_goods_sold: [el.cost_amount ? Number(el.cost_amount) : 0],
                total_credit_note_cost: [],
                gross_profit: 0,
                all_products: [],
                redeemed_point: [el.product_use_point ? Number(el.product_use_point) : 0],
                store_name: el.store_name ? el.store_name : "",
                company_name: el.company_name ? el.company_name : "",
                ssm_id: el.ssm_id ? el.ssm_id : "",
                store_address: el.store_address ? el.store_address : "",
                payment_method: [el.payment_method],
                cash: [el.payment_method === "cash" ? el.amount : 0],
                debit_card: [el.payment_method === "debit_card" ? el.amount : 0],
                credit_card: [el.payment_method === "credit_card" ? el.amount : 0],
                ewallet: [el.payment_method === "ewallet" ? el.amount : 0],
                online_transfer: [el.payment_method === "online_transfer" ? el.amount : 0],
                gift: [el.payment_method === "gift" ? el.amount : 0],
              };

              if(el.products){
                el.products.forEach((item)=>{
                  dataMap[tempDate].all_products.push(item)
                })
              }
              
            }
            
            var total_amount = 0;

              dataMap[tempDate].checkout_sub_total.forEach(val => {
                total_amount += Number(val)
              })

              var total_discount = 0;

              dataMap[tempDate].checkout_discount.forEach(val => {
                total_discount += Number(val)
              })

              var total_cost = 0;

              dataMap[tempDate].total_cost_of_goods_sold.forEach(val => {
                total_cost += Number(val)
              })

              var _total_point = 0;

              dataMap[tempDate].redeemed_point.forEach(val => {
                _total_point += Number(val)
              })

              dataMap[tempDate].net_turnover = total_amount - total_discount;

              
              dataMap[tempDate].gross_profit = dataMap[tempDate].net_turnover - total_cost;
              dataMap[tempDate].total_point = _total_point;
            
          });

          items_cn.forEach((el, index) => {
            let tempDate = el.created;
            if (tempDate in dataMap) {
              dataMap[tempDate].total_credit_note.push(el.credit_note_amount);
              dataMap[tempDate].total_credit_note_cost.push(el.credit_note_amount_cost);
            }
            else
            {
              dataMap[tempDate] = {
                checkout_created_timestamp: moment(tempDate).unix(),
                checkout_created: moment(tempDate).unix(),
                checkout_amount: el.checkout_amount ?  el.checkout_amount : [],
                total_invoice: el.total_invoice ?  el.total_invoice : 0,
                checkout_discount: el.checkout_discount ?  el.checkout_discount : [],
                total_credit_note: [el.credit_note_amount ? Number(el.credit_note_amount) : 0],
                net_turnover:  el.net_turnover ?  el.net_turnover : 0,
                total_cost_of_goods_sold: el.total_cost_of_goods_sold ?  el.total_cost_of_goods_sold : [],
                total_credit_note_cost: [el.credit_note_amount_cost ? Number(el.credit_note_amount_cost) : 0],
                gross_profit: el.gross_profit ?  el.gross_profit : 0
              };
            }
          })

          let data = [];

          // Fill the data
          Object.keys(dataMap).forEach((el) => data.push(dataMap[el]));
          setTotalCheckOutAmount(_totalCheckOutAmount)

          data.forEach((el, index) => {

           
            var total_credit_note_cost = 0;
            el.total_credit_note_cost.forEach(val => {
              total_credit_note_cost += Number(val)
            })
            el.gross_profit = el.gross_profit - total_credit_note_cost
            
            var total_credit_note = 0;
            el.total_credit_note.forEach(val => {
              total_credit_note += Number(val)
            })

            el.net_turnover = el.net_turnover - total_credit_note;

            var total_cash = 0;
            var total_debit_card = 0;
            var total_credit_card = 0;
            var total_ewallet = 0;
            var total_online_transfer = 0;
            var total_gift = 0;

            el.cash.forEach(val => {
              total_cash += Number(val)
            })
            el.total_cash = total_cash;

            el.debit_card.forEach(val => {
              total_debit_card += Number(val)
            })
            el.total_debit_card = total_debit_card;

            el.credit_card.forEach(val => {
              total_credit_card += Number(val)
            })
            el.total_credit_card = total_credit_card;

            el.ewallet.forEach(val => {
              total_ewallet += Number(val)
            })
            el.total_ewallet = total_ewallet;

            el.online_transfer.forEach(val => {
              total_online_transfer += Number(val)
            })
            el.total_online_transfer = total_online_transfer;

            el.gift.forEach(val => {
              total_gift += Number(val)
            })
            el.total_gift = total_gift;

          })


          setRowsOriginal(data)
          setLoading(false)

          })
          
          
          
        }
        else
        {
          setRowsOriginal([])
          setLoading(false)
        }

        setSelected([])
      })

      setHeadCells(props.headCells ? props.headCells : [])
      setSearchCell(props.searchCell ? props.searchCell : [])
      setSearchValue(props.searchValue ? props.searchValue : "")
      setAddItem(props.addCellsName ? props.addCellsName : [])

      return () => {
        itemRef.off()
      };
    }
    else
    {
      let itemRef = firebase.database().ref(props.data);

      itemRef.on('value', snapshot => {
       
        if(snapshot.exists()){
  
          let items = [];
  
          if(props.folder){
            snapshot.forEach(child => {
              
              var itemsVal = child.val();
  
              props.headCells.forEach(headChild =>{
                itemsVal[headChild.id] = itemsVal[headChild.id] ? itemsVal[headChild.id] : ''
              })
  
              if(itemsVal.user && (props.data === "orders" || props.data === "gift_redeem" || props.data === "pos/" + props.user + "/orders")){
                itemsVal.first_name_orders = itemsVal.user["first_name"]
              }
  
              if(itemsVal.user && (props.data === "orders" || props.data === "gift_redeem" || props.data === "pos/" + props.user + "/orders")){
                itemsVal.last_name_orders = itemsVal.user["last_name"]
              }
  
              if(props.data === "users"){
                itemsVal.level = itemsVal.level ? parseInt(itemsVal.point_users) :  0;
                itemsVal.point_users = itemsVal.point_users ? parseInt(itemsVal.point_users) : 0;
                itemsVal.user_redeem_time = itemsVal.user_redeem_time ? itemsVal.user_redeem_time : 0;
              }
              
              if(itemsVal.folder && 
                itemsVal.folder === props.folder){
  
                if(itemsVal.serial){
                  itemsVal.serial = parseInt(itemsVal.serial);
                }
  
                if(props.data === "users"){
  
                  if(props.role === "salesman"){
                    if(itemsVal.role === "salesman"){
                      items.push(itemsVal);
                    }
                  }
                  else{
                    if(itemsVal.role !== "salesman"){
                      items.push(itemsVal);
                    }
                  }
                  
                  
                }
                else
                {
                  items.push(itemsVal);
                }
                
              }
              
            })
          }
          else
          {
           
            snapshot.forEach(child => {
              var itemsVal = child.val();
  
              props.headCells.forEach(headChild =>{
                itemsVal[headChild.id] = itemsVal[headChild.id] ? itemsVal[headChild.id] : ''
              })
              
              
              if(itemsVal.user && (props.data === "orders" || props.data === "gift_redeem" || props.data === "pos/" + props.user + "/orders")){
                itemsVal.first_name_orders = itemsVal.user["first_name"]
              }
  
              if(itemsVal.user && (props.data === "orders" || props.data === "gift_redeem" || props.data === "pos/" + props.user + "/orders")){
                itemsVal.last_name_orders = itemsVal.user["last_name"]
              }

              if(props.data === "customer"){
                itemsVal.customer_point = (itemsVal.customer_point ? parseInt(itemsVal.customer_point) : 0) - (itemsVal.customer_use_point ? itemsVal.customer_use_point : 0);
              }
  
              if(props.data === "users"){
                itemsVal.level = itemsVal.level ? parseInt(itemsVal.point_users) :  0;
                itemsVal.point_users = itemsVal.point_users ? parseInt(itemsVal.point_users) : 0;
                itemsVal.user_redeem_time = itemsVal.user_redeem_time ? itemsVal.user_redeem_time : 0;
              }
  
              if(itemsVal.serial){
                itemsVal.serial = parseInt(itemsVal.serial);
              }
              
              if(!itemsVal.folder){
                if(props.data === "users"){
  
                  if(props.role === "salesman"){
                    if(itemsVal.role === "salesman"){
                      items.push(itemsVal);
                    }
                  }
                  else{
                    if(itemsVal.role !== "salesman"){
                      items.push(itemsVal);
                    }
                  }
                  
                  
                }
                else
                {
                  items.push(itemsVal);
                }
              }
              
            })
          }
  
          if(props.data === "request"){
  
            let userRef = firebase.database().ref("users");
            userRef.once('value', snapshot => {
  
              let _users = [];
  
              if(snapshot.exists()){
                snapshot.forEach(child => {
                  var users = child.val();
                  _users.push(users)
                })
              }
  
              setAllUser(_users)
            });
  
          }

          if(props.data === "pos/" + props.user + "/products"){

            let orderRef = firebase.database().ref("pos/" + props.user + "/orders");

            orderRef.once('value', snapshot => {

              let _orders = []
                  
              if(snapshot.exists()){

                snapshot.forEach(val => {
                  _orders.push(val.val())
                })

              }

              let warehouseRef = firebase.database().ref("pos/" + props.user + "/warehouse");

              warehouseRef.once('value', snapshot => {
                
                let _warehouse = []
                  
                if(snapshot.exists()){
    
                  snapshot.forEach(val => {
                    _warehouse.push(val.val())
                  })
    
                }

                let cnRef = firebase.database().ref("pos/" + props.user + "/credit_note");

                cnRef.once('value', snapshot => {
                
                  let _credit_note = []
                    
                  if(snapshot.exists()){
      
                    snapshot.forEach(val => {
                      _credit_note.push(val.val())
                    })
      
                  }

                  var allproducts = []
                  _orders.forEach(val => {
                    if(val.products)
                    {
                      val.products.forEach(value => {
                        value.quantity = Number(value.quantity)
                        allproducts.push(value)
                      })
                    }
                    
                  })

                  const productOrderedList = allproducts.reduce((items, item) => {
                    
                      const {id, quantity} = item;
                      
                    const itemIndex = items.findIndex(item => item.id === id)
                    
                    if(itemIndex === -1){
                      items.push(item);
                    } else {
                      items[itemIndex].quantity += quantity;
                    }
                    
                    return items;
                  }, []);


                  var warehouseproducts_import = []
                  var warehouseproducts_export = []

                  _warehouse.forEach(val => {
                    if(val.warehouse_action === "receive"){
                      if(val.products)
                      {
                        val.products.forEach(value => {
                          value.warehouse_quantity = Number(value.warehouse_quantity)
                          warehouseproducts_import.push(value)
                        })
                      }
                      
                    }
                    else{
                      if(val.products)
                      {
                        val.products.forEach(value => {
                          value.warehouse_quantity = Number(value.warehouse_quantity)
                          warehouseproducts_export.push(value)
                        })
                      }
                      
                    }
                  })
                  

                  const warehouseImportList = warehouseproducts_import.reduce((items, item) => {
                    
                    const {id, warehouse_quantity} = item;
                    
                    const itemIndex = items.findIndex(item => item.id === id)
                    
                    if(itemIndex === -1){
                      items.push(item);
                    } else {
                      items[itemIndex].warehouse_quantity += warehouse_quantity;
                    }
                    
                    return items;
                  }, []);


                  const warehouseExportList = warehouseproducts_export.reduce((items, item) => {
                    
                    const {id, warehouse_quantity} = item;
                    
                    const itemIndex = items.findIndex(item => item.id === id)
                    
                    if(itemIndex === -1){
                      items.push(item);
                    } else {
                      items[itemIndex].warehouse_quantity += warehouse_quantity;
                    }
                    
                    return items;
                  }, []);


                  var allCNproducts = []
                  _credit_note.forEach(val => {
                    if(val.products)
                    {
                      val.products.forEach(value => {
                        value.products_quantity = Number(value.products_quantity)
                        allCNproducts.push(value)
                      })
                    }
                    
                  })

                  const cnProductList = allCNproducts.reduce((items, item) => {
                    
                    const {id, products_quantity} = item;
                    
                    const itemIndex = items.findIndex(item => item.id === id)
                    
                    if(itemIndex === -1){
                      items.push(item);
                    } else {
                      items[itemIndex].products_quantity += products_quantity;
                    }
                    
                    return items;
                  }, []);

                  let _products = []
                  items.forEach(_product =>{
                    
                      _product.in_stock = 0;

                      let itemIndex = productOrderedList.map(value => value.id).indexOf(_product.id);

                      if(itemIndex != -1){
                        _product.in_stock -= productOrderedList[itemIndex].quantity;
                      }

                      let itemIndex2 = warehouseImportList.map(value => value.id).indexOf(_product.id);

                      if(itemIndex2 != -1){
                        _product.in_stock += warehouseImportList[itemIndex2].warehouse_quantity;
                      }

                      let itemIndex3 = warehouseExportList.map(value => value.id).indexOf(_product.id);

                      if(itemIndex3 != -1){
                        _product.in_stock -= warehouseExportList[itemIndex3].warehouse_quantity;
                      }

                      let itemIndex4 = cnProductList.map(value => value.id).indexOf(_product.id);

                      if(itemIndex4 != -1){
                        _product.in_stock += cnProductList[itemIndex4].products_quantity;
                      }

                      _products.push(_product)
                  })
                  setRowsOriginal(_products)
                  setLoading(false)

                })

              })
            })

          }
  
          else if(props.data === "users"){
            let userLevelRef = firebase.database().ref("user_level");
  
            userLevelRef.once('value', snapshot => {
              let _userLevel = [];
              
              snapshot.forEach(child => {
                var userLevelVal = child.val();
                _userLevel.push(userLevelVal)
              })
  
              let giftRedeemRef = firebase.database().ref("gift_redeem");
  
              giftRedeemRef.once('value', snapshotRedeem => {
  
                items.forEach(row => {
  
                  var redeem_times = 0;
                  snapshotRedeem.forEach(child => {
                    var giftRedeemVal = child.val();
                    if(giftRedeemVal.redeem_user){
                                      
                      var currentUserIndex = giftRedeemVal.redeem_user.map(values => values.id).indexOf(row.id);
  
                      if(currentUserIndex !== -1){
                        redeem_times++;
                      }
                    }
                  })
  
                  let level = "Bronze";
                  let highest_point = 0;
                  
                  _userLevel.forEach(val => {
                    if(val.max_point >= highest_point){
                      highest_point = val.max_point;
                    }
                  })
  
                  _userLevel.forEach(val => {
                    if(parseInt(row["point_users"]) >= val.min_point && parseInt(row["point_users"]) < val.max_point){
                      level = val.name;
                    }
                    if(parseInt(row["point_users"]) >= highest_point && val.highest_level){
                      level = val.name;
                    }
                    
                  })
                  
  
                  row.gift_redeem = redeem_times;
                  row.user_level = level;
                })
  
                setRowsOriginal(items)
                setLoading(false)
              })
  
              
            })
            
          }
  
          else
          {
            setRowsOriginal(items)
            setLoading(false)
          }
  
        }
        else
        {
          setRowsOriginal([])
          setLoading(false)
        }
  
        setSelected([])
      });
  
      setHeadCells(props.headCells ? props.headCells : [])
      setSearchCell(props.searchCell ? props.searchCell : [])
      setSearchValue(props.searchValue ? props.searchValue : "")
      setAddItem(props.addCellsName ? props.addCellsName : [])
      
      return () => {
        itemRef.off()
      };
    }
    

  }, [props]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {

    if(props.mediaOption && (props.mediaOption === "image" || props.mediaOption === "customer" || props.mediaOption === "invoice")){
      
    }
    else
    {
      if (event.target.checked) {
        const newSelecteds = readRows().map((n) => n);
        setSelected(newSelecteds);
  
        if(props.handleSelectedSation){
          props.handleSelectedSation(newSelecteds)
        }
        
        return;
      }else
      {
        if(props.handleSelectedSation){
          props.handleSelectedSation([])
        }
      }
    }
    
    setSelected([]);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleCheckBoxClick = (event, name) => {

    if(props.mediaOption && (props.mediaOption === "image" || props.mediaOption === "customer" || props.mediaOption === "invoice")){

      const selectedIndex = selected.indexOf(name);

      if (selectedIndex === -1) {
        setSelected([name]);
      }
      else{
        setSelected([])
      }
      
    }
    else 
    {
      const selectedIndex = selected.indexOf(name);
      let newSelected = [];
  
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }
      
      if(props.handleSelectedSation){
        props.handleSelectedSation(newSelected)
      }
      
      setSelected(newSelected);
    }
    
  };

  //Search
  const onChangeSearchSelect = event => {
    setSearchtxt("")
    setSearchValue(event.target.value)
  }

  const search = event => {
    setSearchtxt(event.target.value)
    setPage(0)
  }

  //Dialog
  const onClickDeleteAll = () => {
    setDialogLoading(false)
    setAgreeButton(true)
    setDialogTitle("Delete all selected items")
    setOptions("deleteAll")
    setDialogDes("Are you sure you want to delete these items?")
    setFullScreenDialog(false)
    setOpenDialog(true)
  }

  const onClickDownloadExcel = () => {
    setDialogLoading(false)
    setAgreeButton(true)
    setDialogTitle("Download Excel")
    setOptions("downloadExcel")
    setDialogDes("")
    setFullScreenDialog(false)
    setOpenDialog(true)
  }

  const onClickReceipt = (row) => {
    setDialogLoading(false)
    setAgreeButton(false)
    setDialogTitle("Order Receipt")
    setOptions("orderReceipt")
    setSelectedItem(row)
    setDialogDes("")
    setFullScreenDialog(false)
    setOpenDialog(true)
  }

  const onClickMoveAll = () => {
    setDialogLoading(true)
    let itemRef = firebase.database().ref(props.folder_data);
    
    itemRef.once('value', snapshot => {
        
      if(snapshot.exists()){
        let items = [];
        snapshot.forEach(child => {
            
          var itemsVal = child.val();
          items.push(itemsVal);
          
        })
        
        setFolderData(items)
        setDialogLoading(false)
      }
      else
      {
        setFolderData([])
        setDialogLoading(false)
      }

    })

    setAgreeButton(false)
    setDialogTitle("Move all selected items")
    setOptions("moveAll")
    setDialogDes("Are you sure you want to move these items?")
    setFullScreenDialog(false)
    setOpenDialog(true)
  }

  const onClickDelete = (row) => {
    setDialogLoading(false)
    setSelectedItem(row)
    setAgreeButton(true)
    setDialogTitle("Delete")
    setOptions("delete")
    setDialogDes("Are you sure you want to delete this item?")
    setFullScreenDialog(false)
    setOpenDialog(true)
  }

  const onClickInfo = (row) => {
    setDialogLoading(false)
    setSelectedItem(row)
    setAgreeButton(false)
    setDialogTitle("Info")
    setOptions("info")
    setDialogDes("")
    setFullScreenDialog(false)
    setOpenDialog(true)
  }

  const onClickPreview = (row) => {

    if(row.type === "application/pdf")
    {
      window.open(row.url, "_blank")
    }
    else
    {
      setDialogLoading(true)
      setSelectedItem(row)
      setAgreeButton(false)
      setDialogTitle("Preview")
      setOptions("preview")
      setDialogDes("")
      setFullScreenDialog(false)
      setOpenDialog(true)
    }

  }

  const onClickOrders = (row) => {
      setDialogLoading(false)
      setSelectedItem(row)
      setAgreeButton(false)
      setDialogTitle("Orders")
      setOptions("orders")
      setDialogDes("")
      setFullScreenDialog(true)
      setOpenDialog(true)
  }

  const onClickSaleReport = (row) => {
    setDialogLoading(false)
    setSelectedItem(row)
    setAgreeButton(false)
    setDialogTitle("Sales Report")
    setOptions("sales_report")
    setDialogDes("")
    setFullScreenDialog(true)
    setOpenDialog(true)
  }

  const onClickDownloadSaleReport = (row) => {

    setOpen(true)
    setSelectedItem(row)

  }

  const handleDownloadReport = () => {

    let itemRef = firebase.database().ref("pos/" + selectedItem.id + "/orders");

      itemRef.on('value', snapshot => {
        if(snapshot.exists()){

          let items = [];
          
          snapshot.forEach(child => {
            var itemsVal = child.val();

            var itemDate = new Intl.DateTimeFormat('en-GB', {year: 'numeric',day: '2-digit',  month: 'long' }).format(itemsVal.created)
            itemsVal.created = itemDate;

            items.push(itemsVal)

          })

          let creditNoteRef = firebase.database().ref("pos/" + selectedItem.id + "/credit_note");

          creditNoteRef.on('value', snapshot => {

            let items_cn = [];

            snapshot.forEach(child => {
              var itemsVal = child.val();
              var itemDate = new Intl.DateTimeFormat('en-GB', {year: 'numeric',day: '2-digit',  month: 'long' }).format(itemsVal.created)
              itemsVal.created = itemDate;
              items_cn.push(itemsVal)
            })

            // Create a map for the dates
          let dataMap = {};

          
          
          items.forEach((el, index) => {
            
            
            let tempDate = el.created;
            if (tempDate in dataMap) {
              
              dataMap[tempDate].checkout_amount.push(el.amount);
              dataMap[tempDate].checkout_sub_total.push(el.subtotal);
              dataMap[tempDate].payment_method.push(el.payment_method);
              dataMap[tempDate].cash.push(el.payment_method === "cash" ? el.amount : 0);
              dataMap[tempDate].debit_card.push(el.payment_method === "debit_card" ? el.amount : 0);
              dataMap[tempDate].credit_card.push(el.payment_method === "credit_card" ? el.amount : 0);
              dataMap[tempDate].ewallet.push(el.payment_method === "ewallet" ? el.amount : 0);
              dataMap[tempDate].online_transfer.push(el.payment_method === "online_transfer" ? el.amount : 0);
              dataMap[tempDate].gift.push(el.payment_method === "gift" ? el.amount : 0);

              dataMap[tempDate].total_invoice =  dataMap[tempDate].total_invoice ? dataMap[tempDate].total_invoice+1 : 1;
              dataMap[tempDate].checkout_discount.push(el.waiver ? Number(el.waiver) : 0);
              dataMap[tempDate].total_cost_of_goods_sold.push(el.cost_amount ? Number(el.cost_amount) : 0);
              dataMap[tempDate].redeemed_point.push(el.product_use_point? Number(el.product_use_point) : 0)

              if(el.products){
                el.products.forEach((item)=>{
                  dataMap[tempDate].all_products.push(item)
                })
              }
              
            } else {

              dataMap[tempDate] = {
                checkout_created_timestamp: moment(tempDate).unix(),
                checkout_created: moment(tempDate).unix(),
                checkout_amount: [el.amount],
                checkout_sub_total: [el.subtotal],
                total_invoice: 1,
                checkout_discount: [Number(el.waiver)],
                total_credit_note: [],
                net_turnover: 0,
                total_cost_of_goods_sold: [el.cost_amount ? Number(el.cost_amount) : 0],
                total_credit_note_cost: [],
                gross_profit: 0,
                all_products: [],
                redeemed_point: [el.product_use_point ? Number(el.product_use_point) : 0],
                store_name: el.store_name ? el.store_name : "",
                company_name: el.company_name ? el.company_name : "",
                ssm_id: el.ssm_id ? el.ssm_id : "",
                store_address: el.store_address ? el.store_address : "",
                payment_method: [el.payment_method],
                cash: [el.payment_method === "cash" ? el.amount : 0],
                debit_card: [el.payment_method === "debit_card" ? el.amount : 0],
                credit_card: [el.payment_method === "credit_card" ? el.amount : 0],
                ewallet: [el.payment_method === "ewallet" ? el.amount : 0],
                online_transfer: [el.payment_method === "online_transfer" ? el.amount : 0],
                gift: [el.payment_method === "gift" ? el.amount : 0],
              };

              if(el.products){
                el.products.forEach((item)=>{
                  dataMap[tempDate].all_products.push(item)
                })
              }

            }
            
            var total_amount = 0;

              dataMap[tempDate].checkout_sub_total.forEach(val => {
                total_amount += Number(val)
              })

              var total_discount = 0;

              dataMap[tempDate].checkout_discount.forEach(val => {
                total_discount += Number(val)
              })

              var total_cost = 0;

              dataMap[tempDate].total_cost_of_goods_sold.forEach(val => {
                total_cost += Number(val)
              })

              var _total_point = 0;

              dataMap[tempDate].redeemed_point.forEach(val => {
                _total_point += Number(val)
              })

              dataMap[tempDate].net_turnover = total_amount - total_discount;

              
              dataMap[tempDate].gross_profit = dataMap[tempDate].net_turnover - total_cost;
              dataMap[tempDate].total_point = _total_point;
              
          });

          items_cn.forEach((el, index) => {
            
            let tempDate = el.created;
            if (tempDate in dataMap) {
              dataMap[tempDate].total_credit_note.push(el.credit_note_amount);
              dataMap[tempDate].total_credit_note_cost.push(el.credit_note_amount_cost);
            }
            else
            {
              dataMap[tempDate] = {
                checkout_created_timestamp: moment(tempDate).unix(),
                checkout_created: moment(tempDate).unix(),
                checkout_amount: el.checkout_amount ?  el.checkout_amount : [],
                total_invoice: el.total_invoice ?  el.total_invoice : 0,
                checkout_discount: el.checkout_discount ?  el.checkout_discount : [],
                total_credit_note: [el.credit_note_amount ? Number(el.credit_note_amount) : 0],
                net_turnover:  el.net_turnover ?  el.net_turnover : 0,
                total_cost_of_goods_sold: el.total_cost_of_goods_sold ?  el.total_cost_of_goods_sold : [],
                total_credit_note_cost: [el.credit_note_amount_cost ? Number(el.credit_note_amount_cost) : 0],
                gross_profit: el.gross_profit ?  el.gross_profit : 0
              };
            }

            
           
          })

          let data = [];

          // Fill the data
          Object.keys(dataMap).forEach((el) => data.push(dataMap[el]));

          data.forEach((el, index) => {

           
            var total_credit_note_cost = 0;
            el.total_credit_note_cost.forEach(val => {
              total_credit_note_cost += Number(val)
            })
            el.gross_profit = el.gross_profit - total_credit_note_cost
            
            var total_credit_note = 0;
            el.total_credit_note.forEach(val => {
              total_credit_note += Number(val)
            })

            el.net_turnover = el.net_turnover - total_credit_note;
            

            var total_cash = 0;
            var total_debit_card = 0;
            var total_credit_card = 0;
            var total_ewallet = 0;
            var total_online_transfer = 0;
            var total_gift = 0;

            if(el.cash)
            {
              el.cash.forEach(val => {
                total_cash += Number(val)
              })
              el.total_cash = total_cash;
            }
            
            if(el.debit_card)
            {
              el.debit_card.forEach(val => {
                total_debit_card += Number(val)
              })
              el.total_debit_card = total_debit_card;
            }

            if(el.credit_card)
            {
              el.credit_card.forEach(val => {
                total_credit_card += Number(val)
              })
              el.total_credit_card = total_credit_card;
            }
            
            if(el.ewallet)
            {
              el.ewallet.forEach(val => {
                total_ewallet += Number(val)
              })
              el.total_ewallet = total_ewallet;
            }
            
            if(el.online_transfer)
            {
              el.online_transfer.forEach(val => {
                total_online_transfer += Number(val)
              })
              el.total_online_transfer = total_online_transfer;
            }
            

            if(el.gift)
            {
              el.gift.forEach(val => {
                total_gift += Number(val)
              })
              el.total_gift = total_gift;
            }

            

          })
          onClickExcelSaleReport(data)

          })
          
          
          
        }
        else
        {
          onClickExcelSaleReport([])
        }
      })

  }

  const onClickExcelSaleReport = (rowsOriginal) => {

    if(dateRange[0] && dateRange[1]){
      rowsOriginal.forEach(val => {

        if(val.checkout_created_timestamp){
          if(val.checkout_created_timestamp >= moment(dateRange[0]).unix() && val.checkout_created_timestamp <= moment(dateRange[1]).unix()){
            val.hide = false
          }
          else{
            val.hide = true
          }
        }
        
      })
      
    }
    else{
      rowsOriginal.forEach(val => {

        val.hide = false
        
      })
    }

    var indexNum = 1;
      var headCell = ["No.", "ID", "Product Name", "QTY SOLD", "Total Retail Price", "Total Cost Price", "Gross Profit"]

      var listItems = {
        columns: [],
        data: [[]]
      }
  
      headCell.forEach(val => {

        if(val === "ID"){
          listItems.columns.push({ title: "", width: { wpx: 200 } })
        }
        else if(val === "Product Name"){
          listItems.columns.push({ title: "", width: { wpx: 300 } })
        }
        else{
          listItems.columns.push({ title: "", width: { wpx: val.length * 12 } })
        }
        
  
        listItems.data[0].push({
          value: val,
          style: {
            alignment: { horizontal: "center" },
            font: { sz: "11", bold: true },
            border: {
              top: { style: "thin", color: { rgb: "FF000000" } },
              bottom: { style: "thin", color: { rgb: "FF000000" } },
              left: { style: "thin", color: { rgb: "FF000000" } },
              right: { style: "thin", color: { rgb: "FF000000" } }
            },
            fill: { patternType: "solid", fgColor: { rgb: "FFffd200" } }
          }
        })
      })

      let group = {};

      rowsOriginal.forEach(val=>{
       
        if(!val.hide){
          if(val.all_products)
          {
            val.all_products.forEach(item => {

              if(item.id === "-NDStaV_NQlXjC7f24t2"){
                console.log(item)
                
              }
              
              if(group[item.id] && group[item.id].id === item.id){
                  group[item.id].quantity += Number(item.quantity);
                  group[item.id].total_cost_price += (Number(item.cost_price)*Number(item.quantity));
                  group[item.id].total_retail_price += (Number(item.retail_price)*Number(item.quantity));
                  group[item.id].product_gross_profit = group[item.id].total_retail_price - group[item.id].total_cost_price;
              }
              else
              {
                  group[item.id] = {...item};
                  group[item.id].quantity = Number(item.quantity);
                  group[item.id].total_cost_price = (Number(item.cost_price)*Number(item.quantity));
                  group[item.id].total_retail_price =   (Number(item.retail_price)*Number(item.quantity));
                  group[item.id].product_gross_profit = group[item.id].total_retail_price - group[item.id].total_cost_price;
              }
      
              })
          }
          
        }

      })
     
      const _rowsOriginal = Object.values(group);
      
      _rowsOriginal.forEach((val, index) => {

        var items_cell = []
  
        headCell.forEach((val2, index2) => {
  
          if(val2 === "No."){
            items_cell.push(
              {
                value: indexNum++,
                style: {
                  alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                  font: { sz: "11" },
                  border: {
                    top: { style: "thin", color: { rgb: "FF000000" } },
                    bottom: { style: "thin", color: { rgb: "FF000000" } },
                    left: { style: "thin", color: { rgb: "FF000000" } },
                    right: { style: "thin", color: { rgb: "FF000000" } }
                  }
                }
              }
            )
          }
          else if(val2 === "ID")
          {
            items_cell.push(
              {
                value: val.id,
                style: {
                  alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                  font: { sz: "11" },
                  border: {
                    top: { style: "thin", color: { rgb: "FF000000" } },
                    bottom: { style: "thin", color: { rgb: "FF000000" } },
                    left: { style: "thin", color: { rgb: "FF000000" } },
                    right: { style: "thin", color: { rgb: "FF000000" } }
                  }
                }
              }
            )
          }

          else if(val2 === "Product Name")
          {
            items_cell.push(
              {
                value: val.name,
                style: {
                  alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                  font: { sz: "11" },
                  border: {
                    top: { style: "thin", color: { rgb: "FF000000" } },
                    bottom: { style: "thin", color: { rgb: "FF000000" } },
                    left: { style: "thin", color: { rgb: "FF000000" } },
                    right: { style: "thin", color: { rgb: "FF000000" } }
                  }
                }
              }
            )
          }
          else if(val2 === "QTY SOLD")
          {
            items_cell.push(
              {
                value: val.quantity,
                style: {
                  alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                  font: { sz: "11" },
                  border: {
                    top: { style: "thin", color: { rgb: "FF000000" } },
                    bottom: { style: "thin", color: { rgb: "FF000000" } },
                    left: { style: "thin", color: { rgb: "FF000000" } },
                    right: { style: "thin", color: { rgb: "FF000000" } }
                  }
                }
              }
            )
          }
          else if(val2 === "Total Retail Price")
          {
            items_cell.push(
              {
                value: val.total_retail_price,
                style: {
                  alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                  font: { sz: "11" },
                  border: {
                    top: { style: "thin", color: { rgb: "FF000000" } },
                    bottom: { style: "thin", color: { rgb: "FF000000" } },
                    left: { style: "thin", color: { rgb: "FF000000" } },
                    right: { style: "thin", color: { rgb: "FF000000" } }
                  }
                }
              }
            )
          }
          else if(val2 === "Total Cost Price")
          {
            items_cell.push(
              {
                value: val.total_cost_price,
                style: {
                  alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                  font: { sz: "11" },
                  border: {
                    top: { style: "thin", color: { rgb: "FF000000" } },
                    bottom: { style: "thin", color: { rgb: "FF000000" } },
                    left: { style: "thin", color: { rgb: "FF000000" } },
                    right: { style: "thin", color: { rgb: "FF000000" } }
                  }
                }
              }
            )
          }
          else if(val2 === "Gross Profit")
          {
            items_cell.push(
              {
                value: val.product_gross_profit,
                style: {
                  alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                  font: { sz: "11" },
                  border: {
                    top: { style: "thin", color: { rgb: "FF000000" } },
                    bottom: { style: "thin", color: { rgb: "FF000000" } },
                    left: { style: "thin", color: { rgb: "FF000000" } },
                    right: { style: "thin", color: { rgb: "FF000000" } }
                  }
                }
              }
            )
          }
          
        })
        
  
        listItems.data.push(items_cell)

        })

        setMultiDataSet([listItems])

        setTimeout(
          () => {

            excelInput.current.click()
            setOpen(false)
            setDateRange([null,null])
          },
          1000
        );
  }

  const onClickProducts = (row) => {
    setDialogLoading(false)
    setSelectedItem(row)
    setAgreeButton(false)
    setDialogTitle("Products")
    setOptions("products")
    setDialogDes("")
    setFullScreenDialog(true)
    setOpenDialog(true)
  }

  const onClickProofOfPayment = (row) => {

    setDialogLoading(false)
    setSelectedItem(row)
    setAgreeButton(false)
    setDialogTitle("Proof of Payment")
    setOptions("preview_proof_of_payment")
    setDialogDes("")
    setFullScreenDialog(false)
    setOpenDialog(true)
  }

  const onMediaReady = (e) => {
    setDialogLoading(false)
  }

  const onClickMove = (row) => {

    setDialogLoading(true)
    let itemRef = firebase.database().ref(props.folder_data);
    
    itemRef.once('value', snapshot => {
        
      if(snapshot.exists()){
        let items = [];
        snapshot.forEach(child => {
            
          var itemsVal = child.val();
          items.push(itemsVal);
          
        })
        
        setFolderData(items)
        setDialogLoading(false)
      }
      else
      {
        setFolderData([])
        setDialogLoading(false)
      }

    })

    setAgreeButton(false)
    setSelectedItem(row)
    setDialogTitle("Move")
    setOptions("move")
    setDialogDes("Are you sure you want to move this item?")
    setFullScreenDialog(false)
    setOpenDialog(true)
  }

  const onClickEdit = (row) => {
    setDialogLoading(false)
    setAgreeButton(true)
    setSelectedItem(row)
    setDialogTitle("Edit")
    setOptions("edit")
    setDialogDes("")
    setFullScreenDialog(true)
    setOpenDialog(true)
  }

  const onClickAddItem = () => {
    setDialogLoading(false)
    setSelectedItem(null)
    setAgreeButton(true)
    setDialogTitle(props.addItemText)
    setOptions("addItem")
    setDialogDes("")
    setFullScreenDialog(true)
    setOpenDialog(true)
  }

  const onClickOpenFolder = (row) => {
    setDialogLoading(false)
    setSelectedItem(row)
    setAgreeButton(false)
    setDialogTitle(row.name)
    setOptions("openFolder")
    setDialogDes("")
    setFullScreenDialog(true)
    setOpenDialog(true)
  }

  const handleClose = () => {
    setOpenDialog(false)
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setDateRange([null,null])
  };

  const selectFolder = (cell) => {
    firebase.database().ref(props.data + '/' + selectedItem.id).update({ folder: cell ? cell.id : null })
    if(selectedItem.folder){
      firebase.database().ref(props.folder_data + "/" + selectedItem.folder).update({ updated: firebase.database.ServerValue.TIMESTAMP})
    }
    
    setSelected([])
    setOpenDialog(false)
  }

  const selectFolderMoveAll = (cell) => {

    selected.forEach((child, index) => {
      firebase.database().ref(props.data + '/' + child.id).update({ folder: cell ? cell.id : null })
      if(child.folder){
        firebase.database().ref(props.folder_data + "/" + child.folder).update({ updated: firebase.database.ServerValue.TIMESTAMP})
      }
    });

    setSelected([])
    setOpenDialog(false)
  }

  const handleAgree = () => {

    if(option === "deleteAll"){
        selected.forEach((child, index) => {
          firebase.database().ref(props.data + '/' + child.id).remove().then(() => {
            if(props.storage){
              firebase.storage().ref().child(child.full_path).delete().catch((error)=>{

              })
            }
          }).then(()=>{ 
            if(child.notification_id){
                firebase.database().ref("notification/" + child.notification_id).update({ read: true });
            }
          })

          if(props.type === "folder"){
            firebase.database().ref(props.child).once('value', snapshot => {
              if(snapshot.exists()){
                snapshot.forEach(childval => {
              
                  var itemsVal = childval.val();
                  if(itemsVal.folder && 
                    itemsVal.folder === child.id){
                      firebase.database().ref(props.child + '/' + itemsVal.id).remove().then(() => {
  
                        if(props.storage){
                          firebase.storage().ref().child(itemsVal.full_path).delete().catch((error)=>{
             
                          })
                        }
                        
                      });
                      
                  }
                  
                })
              }
            })
            
          }

        });


    }

    else if (option === "downloadExcel") {

      if (props.excelList) {

        var listItems = {
          columns: [],
          data: [[]]
        }

        props.excelList.forEach(cell => {

          if (cell.id === "id" || cell.id === "name" || cell.id === "type"
            || cell.id === "zone" || cell.id === "address" || cell.id === "created" || cell.id === "refreshed"
            || cell.id === "block" || cell.id === "note") {
            listItems.columns.push({ title: "", width: { wpx: 170 } })
          }
          else if(cell.id === "image1" || cell.id === "image2" ||cell.id === "image3" ||cell.id === "image4")
          {
            listItems.columns.push({ title: "", width: { wpx: 670 } })
          }
          else {
            listItems.columns.push({ title: "", width: { wpx: cell.label.length * 12 } })
          }

          listItems.data[0].push({
            value: cell.label,
            style: {
              alignment: { horizontal: "center" },
              font: { sz: "11", bold: true },
              border: {
                top: { style: "thin", color: { rgb: "FF000000" } },
                bottom: { style: "thin", color: { rgb: "FF000000" } },
                left: { style: "thin", color: { rgb: "FF000000" } },
                right: { style: "thin", color: { rgb: "FF000000" } }
              },
              fill: { patternType: "solid", fgColor: { rgb: "FFffd200" } }
            }
          })

        })

        readRows().forEach((cell) => {
          var items_cell = []
          
          props.excelList.forEach(cellid => {

           
           if (cellid.id === "created" || cellid.id === "updated" || cellid.id === "signed_in" || cellid.id === "signed_out") {

              var created_date = "";

              if (cell[cellid.id]) {
                created_date = new Intl.DateTimeFormat('en-US', { year: 'numeric', day: '2-digit', month: 'long', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true }).format(cell[cellid.id]).toString()
              }


              items_cell.push(
                {
                  value: created_date,
                  style: {
                    alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                    font: { sz: "11" },
                    border: {
                      top: { style: "thin", color: { rgb: "FF000000" } },
                      bottom: { style: "thin", color: { rgb: "FF000000" } },
                      left: { style: "thin", color: { rgb: "FF000000" } },
                      right: { style: "thin", color: { rgb: "FF000000" } }
                    }
                  }
                }
              )
            }
            else if (cellid.id === "image1" || cellid.id === "image2" || cellid.id === "image3" || cellid.id === "image4") {
              items_cell.push(
                {
                  value: cell[cellid.id] ? cell[cellid.id].url : "",
                  style: {
                    alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                    font: { sz: "11" },
                    border: {
                      top: { style: "thin", color: { rgb: "FF000000" } },
                      bottom: { style: "thin", color: { rgb: "FF000000" } },
                      left: { style: "thin", color: { rgb: "FF000000" } },
                      right: { style: "thin", color: { rgb: "FF000000" } }
                    }
                  }
                }
              )
            }
            else {
              items_cell.push(
                {
                  value: cell[cellid.id] ? cell[cellid.id] : "",
                  style: {
                    alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                    font: { sz: "11" },
                    border: {
                      top: { style: "thin", color: { rgb: "FF000000" } },
                      bottom: { style: "thin", color: { rgb: "FF000000" } },
                      left: { style: "thin", color: { rgb: "FF000000" } },
                      right: { style: "thin", color: { rgb: "FF000000" } }
                    }
                  }
                }
              )
            }

          })


          
          listItems.data.push(items_cell)
        })

       
        setMultiDataSet([listItems])

        setTimeout(
          () => excelInput.current.click(),
          1000
        );

      }
    }

    else if(option === "delete"){

      firebase.database().ref(props.data + '/' + selectedItem.id).remove().then(() => {
        if(props.storage){
          firebase.storage().ref().child(selectedItem.full_path).delete()
          .catch((error)=>{
           
          })
        }
      
        
        if(props.type === "folder"){
          firebase.database().ref(props.child).once('value', snapshot => {
            if(snapshot.exists()){
              snapshot.forEach(child => {
            
                var itemsVal = child.val();
                if(itemsVal.folder && 
                  itemsVal.folder === selectedItem.id){
                    firebase.database().ref(props.child + '/' + itemsVal.id).remove().then(() => {

                      if(props.storage){
                        firebase.storage().ref().child(itemsVal.full_path).delete().catch((error)=>{
           
                        })
                      }
                      
                    });
                    
                }
                
              })
            }
          })
          
        }

      }).then(()=>{ 
        if(selectedItem.notification_id){
            firebase.database().ref("notification/" + selectedItem.notification_id).update({ read: true });
        }
      })
    }    

    else if(option === "edit"){
      firebase.database().ref(props.data + '/' + selectedItem.id).update(selectedItem)
    }    

    else if(option === "addItem"){

        var newPostKey = firebase.database().ref().child(props.data).push().key;

        addItem['created'] = firebase.database.ServerValue.TIMESTAMP;
        addItem['id'] = newPostKey;
        addItem['user'] = firebase.auth().currentUser.email;

        if(props.folder){
          addItem['folder'] = props.folder;
          firebase.database().ref(props.folder_data + "/" + props.folder).update({ updated: firebase.database.ServerValue.TIMESTAMP})
        }

        var updates = {};
        updates[props.data + '/' + newPostKey] = addItem;

        firebase.database().ref().update(updates);
        setAddItem(props.addCellsName ? props.addCellsName : [])
        
    }


    setSelected([])
    setOpenDialog(false)
  }

  const onChangeHandle = (e, id) => {
    setSelectedItem({ ...selectedItem, [id]: e.target.value });
  }

  const onChangeAddItemHandle = (e, id) => {
    setAddItem({ ...addItem, [id]: e.target.value });
  }

  const readRows = () => {
    var filterData = rowsOriginal.filter(item => { 
      
      if(item.hide)
      {
        return undefined
      }
      else{
        if(item[searchValue] !== undefined){

          return item[searchValue].toString().toLowerCase().includes(searchtxt.toLowerCase())
   
        }
        else
        {
          return undefined
        }
      }
      
    });

    if(searchtxt !== ""){
        return filterData
    }
    else{

      var _filterData = rowsOriginal.filter(item => { 
        if(item.hide)
        {
          return undefined
        }
        else{
          return item
        }
      });

        return _filterData
    }
    
    
  }

  const onChangeRangePicker = (e) => {
    if(e){
      rowsOriginal.forEach(val => {

        if(val.checkout_created_timestamp){
          if(val.checkout_created_timestamp >= moment(e[0]).unix() && val.checkout_created_timestamp <= moment(e[1]).unix()){
            val.hide = false
          }
          else{
            val.hide = true
          }
        }
        
      })
      
    }
    else{
      rowsOriginal.forEach(val => {

        val.hide = false
        
      })
    }
    setDateRange(e)
  }

  const renderTotalInvoice = () => {
    var totalInvoice = 0;

    readRows().forEach(val =>{
      totalInvoice += val.total_invoice;
    })
    return totalInvoice
  }

  const renderTotalSale = (value) => {
    var totalSale = 0;

    readRows().forEach(val =>{
      var total_amount = 0;

      val[value].forEach(val1 => {
        total_amount += Number(val1)
      })
      totalSale += total_amount;
    })
    return totalSale
  }

  const renderTotalProfit = () => {

    var totalprofit = renderTotalSale("checkout_amount").toFixed(2) - renderTotalSale("total_cost_of_goods_sold").toFixed(2) -  renderTotalSale("total_credit_note_cost").toFixed(2)

    return totalprofit;
  }


  const renderTotalNets = () => {
    var totalSale = 0;
    var totalDiscount = 0;

    readRows().forEach(val =>{
      var total_amount = 0;
      var total_discount = 0;

      val["checkout_amount"].forEach(val1 => {
        total_amount += Number(val1)
      })

      val["checkout_discount"].forEach(val2 => {
        total_discount += Number(val2)
      })

      totalSale += total_amount;
      totalDiscount += total_discount;

    })
    return totalSale - totalDiscount;
  }

  if(loading){
    return (
    <Grid style={{ display: 'flex',  justifyContent:'center', alignItems:'center',padding: 70 }}>
      <CircularProgress 
        disableShrink
        color="primary" />
    </Grid>);
  }
  
  var d = new Date();
  var datestring = d.getDate() + "-" + (d.getMonth() + 1) + "-" + d.getFullYear() + "-" +
    d.getHours() + "-" + d.getMinutes() + "-" + d.getSeconds();

  return (
    <Grid>
      <ExcelFile element={<Button style={{ display: "none" }} ref={excelInput} >download</Button>} filename={props.data + "_" + datestring}>
        <ExcelSheet dataSet={multiDataSet} name={props.data} />
      </ExcelFile>
        <Dialog 
          agreeTxt={"Yes"}
          disagreeTxt={"No"}
          description={dialogDes}
          title={dialogTitle}
          open={openDialog} 
          agreeButton={agreeButton}
          selectedItem={selectedItem}
          addItem={addItem}
          onChangeHandle={(e, id) => onChangeHandle(e, id)}
          onChangeAddItemHandle={(e, id) => onChangeAddItemHandle(e, id)}
          handleAgree={handleAgree}
          fullScreenDialog={fullScreenDialog}
          option={option}
          dialogLoading={dialogLoading}
          folderData={folderData}
          selectFolder={selectFolder}
          selectFolderMoveAll={selectFolderMoveAll}
          handleClose={handleClose}
          onMediaReady={onMediaReady}
          {...props}/>

        <Dialog1
          open={open}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Download Report"}</DialogTitle>
          <DialogContent style={{ height: 300 }}>
            <Grid>
            <DialogContentText id="alert-dialog-description" style={{ marginTop: 10 }}>
              Select Date
            </DialogContentText>
            <DateRangePicker className={classes.datepicker} onChange={onChangeRangePicker} value={dateRange} />
            </Grid>
            <DialogContentText id="alert-dialog-description" style={{ marginTop: 10 }}>
              Are you sure you want to download this report?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              No
            </Button>
            <Button onClick={handleDownloadReport} color="primary" autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog1>
          
        <EnhancedTableToolbar 
          onClickMoveAll={onClickMoveAll}
          numSelected={selected.length} 
          selected={selected}
          onClickAddItem={onClickAddItem}
          onClickDeleteAll={onClickDeleteAll}
          onClickDownloadExcel={onClickDownloadExcel}
          {...props}/>
           {props.data === "checkoutdaily" ? <Grid style={{ display: "flex", justifyContent: "space-between", paddingRight: 25, marginBottom: 10 }}>
           <Typography variant="h6" style={{ marginRight: 20}} >{"Total All Sales: RM " + totalCheckOutAmount.toFixed(2) }</Typography>
        <DateRangePicker className={classes.datepicker} onChange={onChangeRangePicker} value={dateRange} />
       
        </Grid>: null}
        
        <TableContainer>
        <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
            >
            <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={readRows().length}
                headCells={headCells}
                {...props}
            />
            
            <TableBody>
              {props.data === "checkoutdaily" ? <TableRow>
                  <TableCell padding="checkbox" >
                      <Checkbox
                          style={{ display: "none"}} 
                        />
                  </TableCell>
                  <TableCell style={{ padding: 3 }}>
                    <Typography  style={{ wordWrap: "break-word" }} variant="body2" >All</Typography>
                  </TableCell>
                  <TableCell style={{ padding: 3 }}>
                    <Typography  style={{ wordWrap: "break-word" }} variant="body2" >{renderTotalInvoice()}</Typography>
                  </TableCell>
                  <TableCell style={{ padding: 3 }}>
                    <Typography  style={{ wordWrap: "break-word" }} variant="body2" >{"RM " + renderTotalSale("checkout_amount").toFixed(2)}</Typography>
                  </TableCell>
                  <TableCell style={{ padding: 3 }}>
                    <Typography  style={{ wordWrap: "break-word" }} variant="body2" >{"RM " + renderTotalSale("checkout_discount").toFixed(2)}</Typography>
                  </TableCell>
                  <TableCell style={{ padding: 3 }}>
                    <Typography  style={{ wordWrap: "break-word" }} variant="body2" >{"RM " + renderTotalSale("total_credit_note").toFixed(2)}</Typography>
                  </TableCell>
                  <TableCell style={{ padding: 3 }}>
                    <Typography  style={{ wordWrap: "break-word" }} variant="body2" >{"RM " + renderTotalNets().toFixed(2)}</Typography>
                  </TableCell>
                  <TableCell style={{ padding: 3 }}>
                    <Typography  style={{ wordWrap: "break-word" }} variant="body2" >{"RM " + renderTotalSale("total_cost_of_goods_sold").toFixed(2)}</Typography>
                  </TableCell>
                  <TableCell style={{ padding: 3 }}>
                    <Typography  style={{ wordWrap: "break-word" }} variant="body2" >{"RM " + renderTotalSale("total_credit_note_cost").toFixed(2)}</Typography>
                  </TableCell>
                  <TableCell style={{ padding: 3 }}>
                    <Typography  style={{ wordWrap: "break-word" }} variant="body2" >{"RM " + renderTotalProfit().toFixed(2)}</Typography>
                  </TableCell>
                </TableRow> :null}
                <TableRow>
                    <TableCell padding={'none'} colSpan={props.headCells.length+1+(props.control ? 1 : 0)}>
                      <Grid container>
                          <Grid item md={7} xs={12}>
                          <form noValidate autoComplete="off">
                          <FormControl style={{ marginTop:10 }} fullWidth >
                              <InputLabel style={{ marginLeft:20 }}>Search</InputLabel>
                              <Input disabled={rowsOriginal.length > 0 ? false : true} onChange={(e) => search(e)}
                                  value={searchtxt}
                                  id="input-with-icon-adornment"
                                  startAdornment={
                                      <InputAdornment style={{ marginLeft:20 }} position="start">
                                      <SearchIcon />
                                      </InputAdornment>
                                  }
                              />
                          </FormControl>
                          </form>
                          </Grid>
                          <Grid item md={5} xs={12} style={{ marginTop:10 }}>
                          <FormControl style={{ width: "100%"}} >
                              <InputLabel style={{ paddingLeft: 25 }} id="simple-select-outlined-label">Search by</InputLabel>
                              <Select
                                  style={{ paddingLeft: 18 }} 
                                  disabled={rowsOriginal.length > 0 ? false : true}
                                  labelId="simple-select-outlined-label"
                                  id="simple-select-outlined"
                                  value={searchValue}
                                  label="Search by"
                                  onChange={(e) => onChangeSearchSelect(e)}
                              >
                                  {searchCell.map((cell, index)=>{
                                      const labelId = `enhanced-search-${index}`;
                                      return(
                                          <MenuItem key={labelId} value={cell.id}>{cell.label}</MenuItem>
                                      )
                                  })}
                              </Select>
                          </FormControl>
                          </Grid>
                      </Grid>
                    </TableCell>
                </TableRow>
                
                {stableSort(readRows(), getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                    const isItemSelected = isSelected(row);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    const rolllabelId = `enhanced-table-roll-${index}`;

                    return (
                      <React.Fragment key={rolllabelId}>
                        <TableRow
                          hover
                          style={{ opacity: row.read? .4: 1, height: 50 }}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          selected={isItemSelected} 
                          >
                          <TableCell padding="checkbox">
                              <Checkbox
                                style={{ display: props.listOnly ? "none" : "normal"}} 
                                onClick={(event) => handleCheckBoxClick(event, row)}
                                checked={isItemSelected}
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                          </TableCell>
                          
                          {headCells.map((cell, cellIndex) => {
                              const labelCellId = `enhanced-table-cell-${cellIndex}` + row.id;
                              
                              if(cell.id === "created" || cell.id === "updated" || cell.id === "claimed_date" ){
                                return(
                                  row[cell.id] ? <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                  {new Intl.DateTimeFormat('en-GB', {year: 'numeric',day: '2-digit',  month: 'long' ,hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true }).format(row[cell.id])}
                                  </TableCell> : <TableCell key={labelCellId} style={{ padding: 3 }} align="left"></TableCell>)
                              }
                              else if(cell.id === "expired_date"){
                                return(
                                  <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                    <Grid style={{overflow: "hidden", textOverflow: "ellipsis", maxWidth: 200}} >
                                      <Typography  style={{ wordWrap: "break-word" }} variant="body2" >{
                                      moment(row["created"]).add(365, "days").format("DD-MM-YYYY")
                                      }</Typography>
                                    </Grid>
                                  </TableCell>)
                              }
                              else if(cell.id === "claimed"){

                                return(
                                  <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                    <Grid style={{overflow: "hidden", textOverflow: "ellipsis", maxWidth: 200}} >
                                      <Typography variant="body2">{row[cell.id] ? "Yes" : "No"}</Typography>
                                    </Grid>
                                  </TableCell>)
                              }
                              else if(cell.id === "size"){
                                return(
                                  <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                  {bytesToSize(row[cell.id])}
                                  </TableCell>)
                              }
                              else if(cell.id === "url"){

                                if(row["type"] === "video/mp4"){
                                  return(
                                    <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                      <VideoLibraryIcon color="primary" style={{ fontSize: 36}}/>
                                    </TableCell>)
                                }

                                if(row["type"] === "application/pdf"){
                                  return(
                                    <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                      <Description color="primary" style={{ fontSize: 36}}/>
                                    </TableCell>)
                                }

                                return(
                                  <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                    {row[cell.id] ? <img
                                    src={row[cell.id]}
                                    style={{  display: 'block', width: 'auto', maxHeight: 35, maxWidth: 35 }}
                                    alt={row["name"]}
                                    /> : null }
                                  </TableCell>)
                              }
                              else if(cell.id === "image"){
                                return(
                                  <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                    {row[cell.id] && row[cell.id][0] ? <img
                                    src={row[cell.id] ? row[cell.id][0].url : null}
                                    style={{  display: 'block', width: 'auto', maxHeight: 35, maxWidth: 35 }}
                                    alt={row["name"]}
                                    />: null}
                                  </TableCell>)
                              }
                              else if(cell.id === "gallery"){
                                return(
                                  <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                    {row[cell.id] && row[cell.id][0] ? <img
                                    src={row[cell.id][0] ? row[cell.id][0].url : null}
                                    style={{  display: 'block', width: 'auto', maxHeight: 50, maxWidth: 50 }}
                                    alt={row[cell.id][0] ? row[cell.id][0].name : ''}
                                    />: <Typography  style={{ wordWrap: "break-word" }} variant="body2" >-</Typography>}
                                  </TableCell>)
                              }
                              else if(cell.id === "status"){
                                return(
                                  <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                    <Grid style={{overflow: "hidden", textOverflow: "ellipsis", maxWidth: 200}} >
                                      <Typography  style={{ wordWrap: "break-word", color: row[cell.id] === "draft" ? "#919191" : "#99d700" }} variant="body2" >{row[cell.id].toUpperCase()}</Typography>
                                    </Grid>
                                  </TableCell>)
                              }
                              else if(cell.id === "status_users"){
                                return(
                                  <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                    <Grid style={{overflow: "hidden", textOverflow: "ellipsis", maxWidth: 200}} >
                                      <Typography  style={{ wordWrap: "break-word", color: row[cell.id] === "inactive" ? "#919191" : "#99d700" }} variant="body2" >{row[cell.id].toUpperCase()}</Typography>
                                    </Grid>
                                  </TableCell>)
                              }
                              else if(cell.id === "redeem_user"){
                                return(
                                  <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                    <Grid style={{overflow: "hidden", textOverflow: "ellipsis", maxWidth: 200}} >
                                    <Typography variant="body2">{row[cell.id] ? row[cell.id].map((val, index) => 
                                      { 
                                        var symbol = "\n | \n";
                                        if(index === row[cell.id].length-1){
                                          symbol = ""
                                        }
                                        
                                        return (val.last_name + "\n" + val.first_name + symbol) 
                                      } ) : ""}</Typography>
                                    </Grid>
                                  </TableCell>)
                              }

                              else if(cell.id === "status_orders"){
                                return(
                                  <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                    <Grid style={{overflow: "hidden", textOverflow: "ellipsis", maxWidth: 200}} >
                                      <Typography  style={{ wordWrap: "break-word", color: "#99d700" }} variant="body2" >{row[cell.id]}</Typography>
                                    </Grid>
                                  </TableCell>)
                              }

                              else if(cell.id === "expired"){
                                return(
                                  <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                    <Grid style={{overflow: "hidden", textOverflow: "ellipsis", maxWidth: 200}} >
                                      <Typography  style={{ wordWrap: "break-word" }} variant="body2" >{moment.unix(row[cell.id]).format("DD-MM-YYYY")}</Typography>
                                    </Grid>
                                  </TableCell>)
                              }

                              else if(cell.id === "amount" || cell.id === "paid_total"){
                                return(
                                  <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                    <Grid style={{overflow: "hidden", textOverflow: "ellipsis", maxWidth: 200}} >
                                      <Typography variant="body2">{row[cell.id] ? "RM " + row[cell.id] : ""}</Typography>
                                    </Grid>
                                  </TableCell>)
                              }
                              else if(cell.id === "highest_level"){
                                return(
                                  <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                    <Grid style={{overflow: "hidden", textOverflow: "ellipsis", maxWidth: 200}} >
                                      <Typography variant="body2">{row[cell.id] ? "Yes" : "-"}</Typography>
                                    </Grid>
                                  </TableCell>)
                              }

                              else if(cell.id === "uuid"){
                                return(
                                  <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                    <Grid style={{overflow: "hidden", textOverflow: "ellipsis", maxWidth: 200}} >
                                      <Typography variant="body2">{row[cell.id]}</Typography>
                                    </Grid>
                                  </TableCell>)
                              }
                              else if(cell.id === "proof_of_payment"){
                                return(
                                  <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                    <Grid style={{overflow: "hidden", textOverflow: "ellipsis", maxWidth: 200}} >
                                      <Typography variant="body2">{row[cell.id] ? "Received" : 'Not received'}</Typography>
                                    </Grid>
                                  </TableCell>)
                              }
                              else if(cell.id === "redeem"){
                                return(
                                  <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                    <Grid style={{overflow: "hidden", textOverflow: "ellipsis", maxWidth: 200}} >
                                      <Typography variant="body2">{row[cell.id] ? "Yes" : 'No'}</Typography>
                                    </Grid>
                                  </TableCell>)
                              }
                              else if(cell.id === "user_uid"){

                                var currentIndexUser = allUser.map(val => val.id).indexOf(row[cell.id]);

                                return(
                                  <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                    <Grid style={{overflow: "hidden", textOverflow: "ellipsis", maxWidth: 200}} >
                                      <Typography variant="body2">{currentIndexUser !== -1 ? allUser[currentIndexUser].last_name + " " + allUser[currentIndexUser].first_name: "-"}</Typography>
                                    </Grid>
                                  </TableCell>)
                              }
                              else if(cell.id === "orders_customer"){
                                return(
                                  <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                    <Grid style={{overflow: "hidden", textOverflow: "ellipsis", maxWidth: 200}} >
                                      <Typography variant="body2">{row[cell.id] ? row[cell.id][0].last_name + " " + row[cell.id][0].first_name : "Walk In"}</Typography>
                                    </Grid>
                                  </TableCell>)
                              }
                              else if(cell.id === "in_stock" ){
                                return(
                                  <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                    <Grid style={{overflow: "hidden", textOverflow: "ellipsis", maxWidth: 200}} >
                                      <Typography variant="body2">{row[cell.id] ? row[cell.id] : "0"}</Typography>
                                    </Grid>
                                  </TableCell>)
                              }
                              else if(cell.id === "checkout_amount" || 
                              cell.id === "checkout_sub_total" ||
                              cell.id === "checkout_discount" || 
                              cell.id === "total_cost_of_goods_sold" ||  cell.id === "total_credit_note"
                              ||  cell.id === "total_credit_note_cost"){

                                var total_amount = 0;

                                row[cell.id].forEach(val => {
                                  total_amount += Number(val)
                                })

                                return(
                                  <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                    <Grid style={{overflow: "hidden", textOverflow: "ellipsis", maxWidth: 200}} >
                                      <Typography variant="body2">{"RM " + total_amount.toFixed(2)}</Typography>
                                    </Grid>
                                  </TableCell>)
                              }
                              else if(cell.id === "net_turnover" || cell.id === "gross_profit"){

                                return(
                                  <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                    <Grid style={{overflow: "hidden", textOverflow: "ellipsis", maxWidth: 200}} >
                                      <Typography variant="body2">{row[cell.id] ? "RM " + row[cell.id].toFixed(2) : "RM 0"}</Typography>
                                    </Grid>
                                  </TableCell>)
                              }
                              else if(cell.id === "checkout_created"){

                                return(
                                  <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                    <Grid style={{overflow: "hidden", textOverflow: "ellipsis", maxWidth: 200}} >
                                      <Typography variant="body2">{moment.unix(row[cell.id]).format("DD/MM/YYYY")}</Typography>
                                    </Grid>
                                  </TableCell>)
                              }
                              else if(cell.id === "package_categories" || cell.id === "foc_categories"){

                                return(
                                  <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                    <Grid style={{overflow: "hidden", textOverflow: "ellipsis", maxWidth: 200}} >
                                      {row[cell.id] ? row[cell.id].map(val =>{
                                        return <Typography variant="body2">{val.name}</Typography>
                                      }) : <Typography variant="body2">-</Typography>}
                                      
                                    </Grid>
                                  </TableCell>)
                              }
                              else if(cell.id === "customer_point"){

                                return(
                                  <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                    <Grid style={{overflow: "hidden", textOverflow: "ellipsis", maxWidth: 200}} >
                                      <Typography  style={{ wordWrap: "break-word" }} variant="body2" >{row[cell.id]}</Typography>
                                    </Grid>
                                  </TableCell>)
                              }
                              
                              
                              
                              
                              return(
                              <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                <Grid style={{overflow: "hidden", textOverflow: "ellipsis", maxWidth: 200}} >
                                  <Typography  style={{ wordWrap: "break-word" }} variant="body2" >{row[cell.id]}</Typography>
                                </Grid>
                              </TableCell>)
                          })}
                          {props.control && matches? 
                            <TableCell key={"control"} style={{ padding: 3 }} align="right">
                                <ControlButton 
                                  row={row}
                                  onClickSaleReport={(row)=> onClickSaleReport(row)} 
                                  onClickDownloadSaleReport={(row)=> onClickDownloadSaleReport(row)} 
                                  onClickPreview={(row)=> onClickPreview(row)} 
                                  onClickOrders={(row)=> onClickOrders(row)} 
                                  onClickReceipt={(row)=> onClickReceipt(row)}
                                  onClickProducts={(row)=> onClickProducts(row)} 
                                  onClickProofOfPayment={(row)=> onClickProofOfPayment(row)} 
                                  onClickMove={(row)=> onClickMove(row)} 
                                  onClickInfo={(row)=> onClickInfo(row)}
                                  onClickEdit={(row)=> onClickEdit(row)}
                                  onClickDelete={(row)=> onClickDelete(row)}
                                  onClickOpenFolder={(row)=> onClickOpenFolder(row)}
                                  {...props}
                                />
                          </TableCell> : null}

                            {matches ? null : 
                            <TableCell align="right">
                              <IconButton onClick={() => { 
                                  if(collapse === row.id){
                                    setCollapse(null)
                                  }
                                  else
                                  {
                                    setCollapse(row.id)
                                  }
                              }}>
                                {collapse === row.id? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                              </IconButton>
                            </TableCell>}
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={headCells.length+1+(props.control ? 1 : 0)}>
                              <Collapse in={collapse === row.id ? true : false } timeout="auto" unmountOnExit>
                                  <Box margin={1}>
                                      <ControlButton 
                                        row={row}
                                        onClickSaleReport={(row)=> onClickSaleReport(row)} 
                                        onClickDownloadSaleReport={(row)=> onClickDownloadSaleReport(row)} 
                                        onClickPreview={(row)=> onClickPreview(row)} 
                                        onClickOrders={(row)=> onClickOrders(row)} 
                                        onClickReceipt={(row)=> onClickReceipt(row)}
                                        onClickProducts={(row)=> onClickProducts(row)} 
                                        onClickProofOfPayment={(row)=> onClickProofOfPayment(row)} 
                                        onClickMove={(row)=> onClickMove(row)} 
                                        onClickInfo={(row)=> onClickInfo(row)}
                                        onClickEdit={(row)=> onClickEdit(row)}
                                        onClickDelete={(row)=> onClickDelete(row)}
                                        onClickOpenFolder={(row)=> onClickOpenFolder(row)}
                                        {...props}
                                      />
                                  </Box>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TablePagination
                    labelRowsPerPage={"Pages"}
                    rowsPerPageOptions={[10, 25, 50]}
                    colSpan={props.headCells.length+1+(props.control ? 1 : 0)}
                    count={readRows().length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableRow>

            </TableFooter>
        </Table>
        </TableContainer>
          
    </Grid>
  );
}